import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgQuestionMarkCircle = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.22 7.154A3.939 3.939 0 0 1 12 6a3.937 3.937 0 0 1 3.933 3.934c0 1.596-1.176 2.549-1.955 3.18l-.003.002-.041.033c-.181.146-.355.286-.494.425-.408.407-.44.777-.441.793V15h-2v-.667c0-.118.03-1.177 1.028-2.174.22-.211.45-.41.69-.599.736-.594 1.217-1.028 1.217-1.626A1.936 1.936 0 0 0 12 8a1.936 1.936 0 0 0-1.934 1.934h-2a3.939 3.939 0 0 1 1.154-2.78ZM13 18v-2h-2v2h2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.486 6.486 2 12 2s10 4.486 10 10-4.486 10-10 10S2 17.514 2 12Zm2 0c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgQuestionMarkCircle);
export default ForwardRef;
