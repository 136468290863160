import React, { ReactNode } from 'react';
import { Menu as CMenu, MenuProps as CMenuProps, PlacementWithLogical } from '@chakra-ui/react';
import { useMenuContext } from '../../context/menu.context';
import { MenuItemProps } from '../MenuItem/menuItem.component';

export interface KMenuContainerProps {
  autoSelect?: boolean;
  children?: ReactNode;
  closeOnBlur?: boolean;
  closeOnSelect?: boolean;
  defaultIsOpen?: boolean;
  flip?: boolean;
  gutter?: number;
  isLazy?: boolean;
  isOpen?: boolean;
  matchWidth?: boolean;
  offset?: [number, number];
  onClose?: (value: (MenuItemProps | undefined)[]) => void;
  onOpen?: () => void;
  placement?: PlacementWithLogical;
}

export type MenuContainerProps = KMenuContainerProps & Omit<CMenuProps, 'onClose' | 'children'>;

export const MenuContainer = ({ children, onClose, ...rest }: MenuContainerProps): JSX.Element => {
  const { handleOnClose } = useMenuContext();

  return (
    <CMenu onClose={handleOnClose} {...rest}>
      {children}
    </CMenu>
  );
};
