import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgEmailSend = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4h14c1.103 0 2 .897 2 2v11c0 1.103-.897 2-2 2h-8v-2h8V8l-6.4 4.8a1.001 1.001 0 0 1-1.2 0L6 8v3H4V6c0-1.103.897-2 2-2Zm.666 2L13 10.75 19.334 6H6.666Z"
      fill={color}
    />
    <path d="M9 12H2v2h7v-2ZM10 15H4v2h6v-2ZM11 20v-2H7v2h4Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgEmailSend);
export default ForwardRef;
