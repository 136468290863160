import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgNotification = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill={color} />
    <path
      d="M5 19h13v-8c.689 0 1.37-.144 2-.422V19c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2h8.422A4.962 4.962 0 0 0 13 6H5v13Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNotification);
export default ForwardRef;
