import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgEnvelopeCancel = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.414 4 3.563 2.149 2.149 3.563l18.353 18.353 1.415-1.414-.827-.826A2 2 0 0 0 22 18V6c0-1.103-.897-2-2-2H5.414Zm2 2 5.794 5.794L20 6.511V6H7.414Zm7.219 7.219L19.414 18h.588L20 9.044l-5.367 4.175Z"
      fill={color}
    />
    <path d="M2 6.23V18c0 1.103.897 2 2 2h11.77l-2-2H4V8.23l-2-2Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgEnvelopeCancel);
export default ForwardRef;
