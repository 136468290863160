import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCreditCardLogoDiners = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="#CFD8DC" d="M45 35c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V13c0-2.2 1.8-4 4-4h34c2.2 0 4 1.8 4 4v22z" />
    <path fill="#1565C0" d="M29 16H19c-4.4 0-8 3.6-8 8s3.6 8 8 8h10c4.4 0 8-3.6 8-8s-3.6-8-8-8z" />
    <path
      fill="#FFF"
      d="M19 18c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm-4 6c0-1.9 1.3-3.4 3-3.9v7.7c-1.7-.4-3-1.9-3-3.8zm5 3.9v-7.7c1.7.4 3 2 3 3.9 0 1.8-1.3 3.3-3 3.8z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCreditCardLogoDiners);
export default ForwardRef;
