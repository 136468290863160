import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { CardSize, CardPaddingMap } from './card.types';

export interface BaseCardProps {
  isFullWidth?: boolean;
  size?: CardSize;
  children?: ReactNode;
}

export interface DefaultCardProps extends BaseCardProps {
  isActive?: never;
  isInteractive?: false;
}

export interface InteractiveCardProps extends BaseCardProps {
  isActive?: boolean;
  isInteractive?: true;
}

export type KCardProps = InteractiveCardProps | DefaultCardProps;

export type CardProps = KCardProps & BoxProps;

export const Card = ({
  children,
  isActive = false,
  isFullWidth = true,
  isInteractive = false,
  size = 'md',
  ...rest
}: CardProps): JSX.Element => {
  return (
    <Box
      bg="background.default"
      p={CardPaddingMap[size]}
      border="1px"
      borderColor="border.default"
      borderRadius="lg"
      {...(isInteractive && {
        borderColor: `${isActive ? 'transparent' : 'border.default'}`,
        boxShadow: `0 0 0 3px ${isActive ? 'var(--chakra-colors-border-focus)' : 'transparent'}`,
        cursor: 'pointer',
        transition: '.1s all ease-in-out',
        _hover: {
          boxShadow: !isActive && `0 0 0 3px var(--chakra-colors-border-default)`,
          borderColor: 'transparent',
        },
      })}
      {...(!isFullWidth && { maxW: 'max-content' })}
      {...rest}
    >
      {children}
    </Box>
  );
};
