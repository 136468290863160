import { Button } from '../../Button/button.component';
import React from 'react';
import { Box } from '@chakra-ui/react';

export interface AlertCloseButtonProps {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  isToast: boolean;
}

export const AlertCloseButton = (props: AlertCloseButtonProps): JSX.Element => {
  const { onClose, isToast } = props;
  return (
    <Box className="Alert__CloseButton">
      <Button variant="ghostNeutral" iconName="close" size="xs" onClick={onClose} isOnContrast={isToast} />
    </Box>
  );
};
