import React from 'react';
import { Text, TextProps } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';

export interface TimestampProps {
  date: Date;
  timeFormat?: string;
  dateFormat?: string;
  shouldTodayDisplayTimeOnly?: boolean;
  tooltipLabel?: string;
  textProps?: Omit<TextProps, 'children'>;
}

export const Timestamp = ({
  date,
  timeFormat = 'h:mma',
  dateFormat = 'M/d/yy',
  shouldTodayDisplayTimeOnly = true,
  tooltipLabel,
  textProps,
}: TimestampProps): JSX.Element => {
  const stamp = format(date, isToday(date) && shouldTodayDisplayTimeOnly ? timeFormat : dateFormat);

  return (
    <Text className="Timestamp" variant="subtle" size="xs" textTransform="lowercase" {...textProps}>
      <Tooltip isDisabled={!tooltipLabel} titleCaseLabel={false} label={tooltipLabel || ''}>
        {stamp}
      </Tooltip>
    </Text>
  );
};
