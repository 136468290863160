import React, { useMemo } from 'react';
import { AvatarBadge as CAvatarBadge, PositionProps } from '@chakra-ui/react';
import { Icon } from '../../Icon/icon.component';
import { AvatarSize } from '../avatar.types';
import { SemanticBackgroundColorToken, SemanticBorderColorToken } from 'theme/tokens/colors';
import { IconName } from '../../../icons';
import { AvatarBadgePosition } from '../avatar.types';
import { TypographyVariant } from 'shared/types/typography';

export interface AvatarBadgeProps {
  iconName?: IconName;
  bg?: SemanticBackgroundColorToken;
  borderColor?: SemanticBorderColorToken;
  iconVariant?: TypographyVariant;
  position?: AvatarBadgePosition;
}

interface AvatarBadgeComponentProps extends AvatarBadgeProps {
  avatarSize: AvatarSize;
}

export const AvatarBadge = ({
  avatarSize,
  iconName,
  bg = 'background.info',
  borderColor = 'border.strongContrast',
  iconVariant = 'info',
  position = 'bottomRight',
}: AvatarBadgeComponentProps): JSX.Element => {
  const useSmallBadge = useMemo(() => avatarSize === 'xs' || !iconName, [iconName, avatarSize]);

  const getBadgePosition = (position: AvatarBadgePosition): PositionProps => {
    switch (position) {
      case 'topLeft':
        return {
          top: '0',
          left: '0',
        };
      case 'topRight':
        return {
          top: '0',
          right: '0',
        };
      case 'bottomLeft':
        return {
          bottom: '0',
          left: '0',
        };
      default:
        return {
          bottom: '0',
          right: '0',
        };
    }
  };

  const getBadgeTransform = (position: AvatarBadgePosition): string => {
    const extraSmallPosition = 45;
    const extraLargePosition = 25;
    const defaultPosition = 40;

    switch (position) {
      case 'topLeft':
        if (avatarSize === 'xl') {
          return `translate(-${extraLargePosition}%, -${extraLargePosition}%)`;
        }
        return useSmallBadge && avatarSize !== 'xs'
          ? `translate(-${defaultPosition}%, -${defaultPosition}%)`
          : `translate(-${extraSmallPosition}%, -${extraSmallPosition}%)`;
      case 'topRight':
        if (avatarSize === 'xl') {
          return `translate(${extraLargePosition}%, -${extraLargePosition}%)`;
        }
        return useSmallBadge && avatarSize !== 'xs'
          ? `translate(${defaultPosition}%, -${defaultPosition}%)`
          : `translate(${extraSmallPosition}%, -${extraSmallPosition}%)`;
      case 'bottomLeft':
        if (avatarSize === 'xl') {
          return `translate(-${extraLargePosition}%, ${extraLargePosition}%)`;
        }
        return useSmallBadge && avatarSize !== 'xs'
          ? `translate(-${defaultPosition}%, ${defaultPosition}%)`
          : `translate(-${extraSmallPosition}%, ${extraSmallPosition}%)`;
      default:
        if (avatarSize === 'xl') {
          return `translate(${extraLargePosition}%, ${extraLargePosition}%)`;
        }
        return useSmallBadge && avatarSize !== 'xs'
          ? `translate(${defaultPosition}%, ${defaultPosition}%)`
          : `translate(${extraSmallPosition}%, ${extraSmallPosition}%)`;
    }
  };

  const getBoxSize = (avatarSize: AvatarSize): string => {
    switch (avatarSize) {
      case 'xl':
        return '24px';
      case 'xs':
        return '10px';
      default:
        return '18px';
    }
  };

  return (
    <CAvatarBadge
      borderColor={borderColor}
      borderWidth="thin"
      bg={bg}
      boxSize={getBoxSize(avatarSize)}
      transform={getBadgeTransform(position)}
      position="absolute"
      {...getBadgePosition(position)}
    >
      {!useSmallBadge && iconName && (
        <Icon name={iconName} variant={iconVariant} size={avatarSize === 'xl' ? 'sm' : 'xs'} />
      )}
    </CAvatarBadge>
  );
};
