import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMagic = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10.625 7.958a6.851 6.851 0 0 0 4.333-4.333.571.571 0 0 1 1.084 0 6.852 6.852 0 0 0 4.333 4.333.57.57 0 0 1 0 1.084 6.852 6.852 0 0 0-4.333 4.333.571.571 0 0 1-1.084 0 6.851 6.851 0 0 0-4.333-4.333.571.571 0 0 1 0-1.084ZM3.25 16.583a5.27 5.27 0 0 0 3.333-3.333c.134-.4.7-.4.834 0a5.27 5.27 0 0 0 3.333 3.333c.4.134.4.7 0 .834a5.27 5.27 0 0 0-3.333 3.333c-.134.4-.7.4-.834 0a5.27 5.27 0 0 0-3.333-3.333.44.44 0 0 1 0-.834ZM2.75 4.75a3.162 3.162 0 0 0 2-2c.08-.24.42-.24.5 0a3.162 3.162 0 0 0 2 2c.24.08.24.42 0 .5a3.162 3.162 0 0 0-2 2c-.08.24-.42.24-.5 0a3.162 3.162 0 0 0-2-2 .264.264 0 0 1 0-.5ZM16.75 18.75a3.162 3.162 0 0 0 2-2c.08-.24.42-.24.5 0a3.162 3.162 0 0 0 2 2c.24.08.24.42 0 .5a3.162 3.162 0 0 0-2 2c-.08.24-.42.24-.5 0a3.162 3.162 0 0 0-2-2 .264.264 0 0 1 0-.5Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMagic);
export default ForwardRef;
