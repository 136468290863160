import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgAnimalSnake = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.238 3.279c-.867 4.334 6.227 1.448 6.39 2.057.095.348-6.108 1.402-6.388 3.727-.644 5.351 12.545.027 12.647.347.215.695-8.54 1.813-5.852 6.16 1.551 2.508 4.485 1.25 6.41 1.305 1.028 1.656 2.598 2.363 3.75 1.875 1.082-.457.578-1.781.059-2.988l-.555.613.301-1.121c-1.574-2.469-7.129-.242-7.355-.777-.399-.942 8.445-1.622 7.039-6.2-1.133-3.68-12.996.829-13.004.239-.008-.817 7.347-1.97 6.539-3.707-1.34-2.884-8.212 1.593-8.787-1.312-.205-1.034 3.048-1.833 2.736-2.12-1.012-.934-3.687.69-3.93 1.902Zm13.91 11.987 1.403 1.312-1.7-.484.297-.828Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAnimalSnake);
export default ForwardRef;
