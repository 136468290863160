import React, { ReactNode } from 'react';
import { HStack } from '@chakra-ui/react';

export interface AlertHeaderProps {
  children: ReactNode;
}

export const AlertHeader = (props: AlertHeaderProps): JSX.Element => {
  const { children } = props;
  return (
    <HStack className="Alert__Header" w="100%" align="flex-start" justify="flex-start">
      {children}
    </HStack>
  );
};
