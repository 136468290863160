export function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function isRGB(color: string): boolean {
  return !!color.match(/^rgb/);
}

export function isDark(color: string): boolean {
  if (!color) return false;
  if (color === 'transparent') return false;

  let r, g, b, hsp;

  if (isRGB(color)) {
    let m = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    if (m) {
      r = m[1];
      g = m[2];
      b = m[3];
    }
  } else {
    let rgb = hexToRgb(color);

    if (rgb) {
      r = rgb.r;
      g = rgb.g;
      b = rgb.b;
    }
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  // @ts-ignore
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp < 174;
}

/**
 * Darken or lighten a color hex value.
 * @param colorHex
 * @param amount Provide positive integer to lighten, negative integer to darken
 */
export const colorLightenDarken = (colorHex: string, amount: number): string => {
  let usePound = false;
  if (colorHex[0] === '#') {
    colorHex = colorHex.slice(1);
    usePound = true;
  }

  const num = parseInt(colorHex, 16);

  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};
