import { ComponentStyleConfig } from '@chakra-ui/react';

export const TagConfig: ComponentStyleConfig = {
  baseStyle: {
    container: {
      borderRadius: 'md',
    },
    label: {
      fontWeight: 'semibold',
    },
    closeButton: {
      height: '20px',
      marginInline: 0,
      marginLeft: 1,
      opacity: 0.5,
      width: '20px',
      _hover: {
        opacity: 0.8,
      },
      _active: {
        opacity: 1,
      },
      _focus: {
        backgroundColor: 'transparent',
        boxShadow: 0,
      },
    },
  },
  sizes: {
    sm: {
      container: {
        height: '20px',
        px: '8px',
      },
      label: {
        fontSize: 'xs',
      },
      closeButton: {
        fontSize: 'sm',
      },
    },
    md: {
      container: {
        height: '24px',
        px: '8px',
      },
      label: {
        fontSize: 'sm',
      },
      closeButton: {
        fontSize: 'md',
      },
    },
    lg: {
      container: {
        height: '32px',
        px: '12px',
      },
      label: {
        fontSize: 'md',
      },
      closeButton: {
        fontSize: 'lg',
      },
    },
  },
  variants: {
    info: {
      container: {
        bg: 'background.info',
      },
      label: {
        color: 'text.info',
      },
    },
    success: {
      container: {
        bg: 'background.success',
      },
      label: {
        color: 'text.success',
      },
    },
    warning: {
      container: {
        bg: 'background.warning',
      },
      label: {
        color: 'text.warning',
      },
    },
    error: {
      container: {
        bg: 'background.danger',
      },
      label: {
        color: 'text.danger',
      },
    },
    default: {
      container: {
        bg: 'background.lessSubtle',
      },
      label: {
        color: 'text.default',
      },
    },
  },
};
