import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgAlertCircle = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.001 7h-2v7h2V7ZM13.001 17v-2h-2v2h2Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.001 12c0-5.514 4.465-10 9.953-10 5.54 0 10.047 4.486 10.047 10s-4.486 10-10 10-10-4.486-10-10Zm2 0c0 4.411 3.589 8 8 8s8-3.589 8-8-3.609-8-8.047-8c-4.386 0-7.953 3.589-7.953 8Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlertCircle);
export default ForwardRef;
