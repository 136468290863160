import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgPackage = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M22 8c.01-.07.01-.14 0-.21v-.08a.772.772 0 0 0-.07-.16.35.35 0 0 0-.05-.08l-.1-.13-.08-.06-.12-.09-9-5a1 1 0 0 0-1 0l-9 5-.09.07-.11.08a.41.41 0 0 0-.07.11.39.39 0 0 0-.08.1.59.59 0 0 0-.06.14.3.3 0 0 0 0 .1A.76.76 0 0 0 2 8v8a1 1 0 0 0 .52.87l9 5a.75.75 0 0 0 .13.06h.1c.164.04.336.04.5 0h.1l.14-.06 9-5A1 1 0 0 0 22 16V8Zm-10 3.87L5.06 8l2.76-1.52 6.83 3.9L12 11.87Zm0-7.72L18.94 8 16.7 9.25 9.87 5.34 12 4.15ZM4 9.7l7 3.92v5.68l-7-3.89V9.7Zm9 9.6v-5.68l3-1.68V15l2-1v-3.18l2-1.11v5.7l-7 3.89Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPackage);
export default ForwardRef;
