import React from 'react';
import { NotificationCounterSize, NotificationCounterVariant } from './notificationCounter.types';
import { Box, Flex, FlexProps } from '@chakra-ui/react';
import {
  MAX_COUNT,
  maxNotificationCounterSizes,
  notificationCounterFontSizes,
  notificationCounterSizes,
} from './notificationCounter.records';

export interface NotificationCounterProps extends FlexProps {
  count?: number;
  size?: NotificationCounterSize;
  showCount?: boolean;
  variant?: NotificationCounterVariant;
}

export const NotificationCounter = ({
  count = 0,
  size = 'md',
  showCount = true,
  variant = NotificationCounterVariant.Red,
  ...rest
}: NotificationCounterProps): JSX.Element => {
  const _showCount = size === 'xs' ? false : showCount; // By default the xs size doesn't show a label

  const notificationVariantStyles = {
    [NotificationCounterVariant.Red]: {
      backgroundColor: 'background.dangerContrast',
      color: 'text.onContrast',
    },
    [NotificationCounterVariant.Neutral]: {
      backgroundColor: 'background.lessSubtle',
      color: 'text.default',
    },
  };

  const counterContent =
    count > MAX_COUNT ? (
      <Box
        backgroundColor={notificationVariantStyles[variant].color}
        borderRadius="full"
        height={maxNotificationCounterSizes[size]}
        width={maxNotificationCounterSizes[size]}
      />
    ) : (
      count > 0 && count
    );

  return (
    <Flex
      {...rest}
      className="NotifcationCounter"
      alignItems="center"
      border="1px"
      borderColor="background.default"
      borderRadius="full"
      fontSize={notificationCounterFontSizes[size]}
      fontWeight="semibold"
      height={notificationCounterSizes[size]}
      justifyContent="center"
      width={notificationCounterSizes[size]}
      {...notificationVariantStyles[variant]}
    >
      {_showCount && counterContent}
    </Flex>
  );
};
