import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Popover, useDisclosure } from '@chakra-ui/react';
import { DatePickerProps } from '../datepicker.component';
import { useDatepicker } from '@datepicker-react/hooks';
import {
  DatePickerContext,
  defaultDateFormat,
  defaultFocusedDate,
  defaultMaxYear,
  defaultMinYear,
  FocusedInput,
  today,
} from './datepicker.context';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';

type DatePickerProviderProps = DatePickerProps & {
  children: React.ReactNode;
  calendarRef: RefObject<HTMLDivElement>;
};

interface DatePickerInternalState {
  startDate: Date | null;
  endDate: Date | null;
  focusedInput: FocusedInput | null;
}

export const DatePickerProvider = ({
  children,
  dateFormat = defaultDateFormat,
  endDate,
  inputProps,
  isDateRange,
  maxDate,
  maxYear,
  maxDaysInRange,
  minDate,
  minYear,
  onDateChange,
  placement,
  startDate,
}: DatePickerProviderProps): JSX.Element => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [state, setState] = useState<DatePickerInternalState>({
    startDate,
    endDate: endDate || startDate,
    focusedInput: null,
  });

  /**
   * Calculates the max booking date based on the following criteria:
   * _maxDaysInRange_
   * * should only ever be used by date ranges
   * * should take precedence over the maxDate value
   * * should use it's value plus the start date of the range to calculate the max date
   * _maxDate_
   * * can be passed to either a single date input or a date range
   * * if provided it will be ignored if maxDaysInRange is passed
   * If neither of these values is specified return undefined
   */
  const getMaxBookingDate = useCallback(
    (startDate) => {
      if (maxDaysInRange && startDate) {
        return add(startDate, { days: maxDaysInRange });
      } else if (maxDate) {
        return maxDate;
      }

      return;
    },
    [maxDate, maxDaysInRange],
  );

  const onDatesChange = (data: DatePickerInternalState): void => {
    if (!data) {
      return;
    }

    // Default the end date to the start date if no value is provided
    if (!data.endDate) {
      data.endDate = data.startDate;
    }

    // If end date exceeds new max booking date, reset the end date value
    const _maxBookingDate = getMaxBookingDate(data.startDate);
    if (data.endDate && _maxBookingDate && isAfter(data.endDate, _maxBookingDate)) {
      data.endDate = null;
    }

    // Set the start date to start of day and end date to end of day to create a range
    data.startDate = data.startDate ? startOfDay(data.startDate) : data.startDate;
    data.endDate = data.endDate ? endOfDay(data.endDate) : data.endDate;

    setState({ ...data, focusedInput: data.focusedInput || FocusedInput.StartDate });
    if (!isDateRange && onDateChange) {
      onDateChange(data);
    }
  };

  const clearDates = useCallback(() => {
    setState({ startDate: null, endDate: null, focusedInput: isOpen ? FocusedInput.StartDate : null });
  }, [isOpen]);

  const {
    activeMonths,
    firstDayOfWeek,
    focusedDate,
    goToDate,
    goToNextMonths,
    goToPreviousMonths,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  } = useDatepicker({
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    initialVisibleMonth: state.startDate || today,
    // Only show max disabled dates for date range when focused on end date input
    maxBookingDate:
      isDateRange && state.focusedInput === FocusedInput.StartDate ? undefined : getMaxBookingDate(state.startDate),
    minBookingDate: minDate,
    minBookingDays: 1,
    numberOfMonths: 1,
    onDatesChange,
    startDate: state.startDate,
    ...(!isDateRange && { exactMinBookingDays: true }),
  });

  const navigateToFocusedInputDate = useCallback(
    (focusedInput: FocusedInput | null) => {
      if (focusedInput === FocusedInput.EndDate && state.endDate) {
        goToDate(state.endDate);
      } else if (state.startDate) {
        goToDate(state.startDate);
      }
    },
    [goToDate, state.endDate, state.startDate],
  );

  const setFocusedInput = useCallback(
    (focusedInput: FocusedInput | null): void => {
      setState((prevState) => ({ ...prevState, focusedInput }));
      navigateToFocusedInputDate(focusedInput);
    },
    [navigateToFocusedInputDate],
  );

  const handleOpen = (): void => {
    if (inputProps?.isDisabled) return;

    onOpen();

    // Default to start date input on open if no start date value has been set
    if (!state.startDate || !isDateRange) {
      setFocusedInput(FocusedInput.StartDate);
    }
  };

  const handleClose = useCallback((): void => {
    setFocusedInput(null);
    onClose();

    if (isDateRange && onDateChange) {
      setTimeout(() => {
        onDateChange({ startDate: state.startDate, endDate: state.endDate });
      }, 200);
    }
  }, [isDateRange, onClose, onDateChange, setFocusedInput, state.endDate, state.startDate]);

  const activeMonth = useMemo(() => activeMonths[0], [activeMonths]);

  // Listen for external changes to start and end dates and update internal state
  useEffect(() => {
    setState((prevState) => ({ ...prevState, startDate, endDate: endDate || startDate }));
  }, [startDate, endDate]);

  return (
    <DatePickerContext.Provider
      value={{
        activeMonth,
        activeMonths,
        dateFormat: dateFormat || defaultDateFormat,
        endDate: state.endDate || null,
        firstDayOfWeek,
        focusedDate: focusedDate || defaultFocusedDate,
        focusedInput: state.focusedInput,
        goToDate,
        goToNextMonths,
        goToPreviousMonths,
        setFocusedInput,
        isDateBlocked,
        isDateFocused,
        isDateHovered,
        isDateRange: isDateRange || false,
        isDateSelected,
        isFirstOrLastSelectedDate,
        maxDate,
        maxYear: maxYear || defaultMaxYear,
        minDate,
        minYear: minYear || defaultMinYear,
        onDateChange,
        onDateFocus,
        onDateHover,
        onDateSelect,
        startDate: state.startDate,
        isOpen,
        onOpen,
        onClose: handleClose,
        clearDates,
      }}
    >
      <Popover isOpen={isOpen} onClose={handleClose} onOpen={handleOpen} placement={placement}>
        {children}
      </Popover>
    </DatePickerContext.Provider>
  );
};
