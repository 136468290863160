import React from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { TimePicker, TimePickerProps } from '../TimePicker/timePicker.component';

export interface TimeRangeProps {
  startTimePickerProps: TimePickerProps;
  endTimePickerProps: TimePickerProps;
  dashContainerProps?: BoxProps;
}

export const TimeRange = ({
  startTimePickerProps,
  endTimePickerProps,
  dashContainerProps,
}: TimeRangeProps): JSX.Element => {
  return (
    <Flex>
      <TimePicker {...startTimePickerProps} />
      <Box alignSelf="center" mx={1} {...dashContainerProps}>
        -
      </Box>
      <TimePicker {...endTimePickerProps} />
    </Flex>
  );
};
