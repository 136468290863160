import React from 'react';
import { Tooltip as CTooltip, TooltipProps as CTooltipProps, PlacementWithLogical } from '@chakra-ui/react';

export interface TooltipProps extends CTooltipProps {
  label: string;
  placement?: PlacementWithLogical;
  titleCaseLabel?: boolean;
}

export const Tooltip = ({
  children,
  label,
  openDelay = 350,
  placement = 'top',
  titleCaseLabel = false,
  ...rest
}: TooltipProps): JSX.Element => {
  return (
    <CTooltip hasArrow arrowSize={10} label={label} openDelay={openDelay} placement={placement} {...rest}>
      {children}
    </CTooltip>
  );
};
