import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PlacementWithLogical, Box, Flex, useDisclosure } from '@chakra-ui/react';
import { Icon } from '../Icon/icon.component';
import { Popover } from '../Popover/popover.component';
import format from 'date-fns/format';
import { TextInput, TextInputProps } from '../TextInput/textInput.component';
import { ScrollContainer } from './components/scrollContainer.component';
import { Meridiem } from './timePicker.types';
import { useIconTextSizeMap } from 'shared/hooks/useIconTextSizeMap';

export interface TimePickerProps {
  initialTime?: Date;
  minuteInterval?: number;
  inputProps?: Omit<TextInputProps, 'onChange' | 'value' | 'isClearable' | 'onClear'>;
  placement?: PlacementWithLogical;
  onTimeChange?: (time: Date) => void;
  dataTestId?: string;
}

export const TimePicker = ({
  initialTime,
  minuteInterval = 5,
  inputProps,
  placement,
  dataTestId = 'time-picker',
  onTimeChange,
}: TimePickerProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // selectedTime should always be UTC
  const [selectedTime, setSelectedTime] = useState(initialTime);

  // convert to local time for display
  const [selectedHour, setSelectedHour] = useState<string | undefined>();
  const [selectedMinute, setSelectedMinute] = useState<string | undefined>();
  const [selectedMeridiem, setSelectedMeridiem] = useState<string | undefined>();

  const formattedTime = useMemo(() => {
    if (!selectedTime) return '';
    return format(selectedTime, 'h:mm aa');
  }, [selectedTime]);

  const formatNumber = (number: number | string): string => {
    const formattedValue = number.toString();
    if (formattedValue.length === 1) {
      return `0${formattedValue}`;
    }

    return formattedValue;
  };

  const hourOptions = useMemo(() => {
    return [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((hour) => hour.toString());
  }, []);

  const minuteOptions = useMemo(() => {
    const minutes = [];
    for (let i = 0; i < 60; i += minuteInterval) {
      minutes.push(i);
    }

    return minutes.map(formatNumber);
  }, [minuteInterval]);

  const meridiemOptions = [Meridiem.AM, Meridiem.PM];

  const handleTimeChange = useCallback(
    (hour?: string, minute?: string, meridiem?: string) => {
      const _hour = hour || '12';
      const _minute = minute || '00';
      const _meridiem = meridiem || 'AM';
      let meridiemAdjustment = 0;

      if (!hour) {
        setSelectedHour('12');
      }
      if (!minute) {
        setSelectedMinute('00');
      }
      if (!meridiem) {
        setSelectedMeridiem('AM');
      }

      // set 1-11pm to 13-23. Don't set 12pm to 24
      if (_meridiem === Meridiem.PM && Number(_hour) < 12) {
        meridiemAdjustment = 12;
      }

      // set 12am to 00
      if (_meridiem === Meridiem.AM && Number(_hour) === 12) {
        meridiemAdjustment = -12;
      }

      const hours = Number(_hour) + meridiemAdjustment;
      const time = new Date().setHours(hours, Number(_minute), 0, 0);
      const date = new Date(time);
      setSelectedTime(date);

      if (onTimeChange) {
        onTimeChange(date);
      }
    },
    [onTimeChange],
  );

  const handleHourClick = useCallback(
    (value: string) => {
      setSelectedHour(value);
      handleTimeChange(value, selectedMinute, selectedMeridiem);
    },
    [handleTimeChange, selectedMeridiem, selectedMinute],
  );

  const handleMinuteClick = useCallback(
    (value: string) => {
      setSelectedMinute(value);
      handleTimeChange(selectedHour, value, selectedMeridiem);
    },
    [handleTimeChange, selectedHour, selectedMeridiem],
  );

  const handleMeridiemClick = useCallback(
    (value: string) => {
      setSelectedMeridiem(value);
      handleTimeChange(selectedHour, selectedMinute, value);
    },
    [handleTimeChange, selectedHour, selectedMinute],
  );

  useEffect(() => {
    // Set default selections based on value passed in
    if (initialTime) {
      const localTime = new Date(initialTime);

      // calculate hours
      const hours = localTime.getHours();
      let adjustedHours = hours % 12 || 12;

      setSelectedTime(initialTime);
      setSelectedHour(adjustedHours.toString());
      setSelectedMinute(localTime.getMinutes().toString());
      setSelectedMeridiem(localTime.getHours() > 11 ? Meridiem.PM : Meridiem.AM);
    }
  }, [initialTime]);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={placement}
      contentProps={{ width: 'max-content' }}
      variant="default"
      size="sm"
      anchor={
        <Box w="100%">
          <TextInput
            placeholder="12:00 AM"
            rightElement={
              <Flex cursor={inputProps?.isDisabled ? 'not-allowed' : 'pointer'} h="100%" align="center">
                <Icon size={useIconTextSizeMap(inputProps?.size || 'md')} name="clock" />
              </Flex>
            }
            value={formattedTime}
            isReadOnly
            data-testid={dataTestId}
            cursor={'pointer'}
            {...inputProps}
          />
        </Box>
      }
    >
      <Flex height="186px" gap={1.5} overflow="hidden">
        <ScrollContainer
          options={hourOptions}
          optionType="hour"
          selectedValue={selectedHour}
          handleClick={handleHourClick}
          isOpen={isOpen}
        />
        <ScrollContainer
          options={minuteOptions}
          optionType="minute"
          selectedValue={selectedMinute ? formatNumber(selectedMinute) : undefined}
          handleClick={handleMinuteClick}
          isOpen={isOpen}
        />
        <ScrollContainer
          options={meridiemOptions}
          optionType="meridiem"
          selectedValue={selectedMeridiem}
          handleClick={handleMeridiemClick}
          isOpen={isOpen}
        />
      </Flex>
    </Popover>
  );
};
