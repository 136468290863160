import React from 'react';
import { Status } from 'shared/types/status';
import { Button, ButtonProps } from '../../Button';
import { Box } from '@chakra-ui/react';

export interface AlertButtonProps extends ButtonProps {
  buttonPosition?: 'topRight' | 'bottomLeft';
  buttonText: string;
  hasDescription?: boolean;
  status?: Status;
  isToast?: boolean;
}

export const AlertButton = (buttonProps: AlertButtonProps): JSX.Element => {
  const { buttonText, buttonPosition, hasDescription, status, isToast, ...rest } = buttonProps;

  return (
    <Box
      className="Alert__Button"
      pt={buttonPosition === 'bottomLeft' && !hasDescription ? 1 : 0}
      pb={buttonPosition === 'bottomLeft' ? 0.5 : 0}
    >
      <Button size="xs" variant={status === 'default' ? 'primary' : 'secondary'} isOnContrast={isToast} {...rest}>
        {buttonProps.buttonText}
      </Button>
    </Box>
  );
};
