import { ComponentStyleConfig } from '@chakra-ui/react';

export const RadioConfig: ComponentStyleConfig = {
  baseStyle: {
    label: {
      marginInlineStart: 2,
    },
    control: {
      border: '1px',
      position: 'relative',
      _checked: {
        _before: {
          position: 'absolute',
          top: '50%',
          right: '50%',
          w: '40%',
          h: '40%',
          transform: 'translate(50%, -50%)',
        },
      },
      _disabled: {
        opacity: 0.6,
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  variants: {
    default: {
      label: {
        color: 'text.default',
        _disabled: {
          opacity: 0.6,
        },
      },
      control: {
        background: 'background.default',
        borderColor: 'border.subtle',
        _hover: {
          borderColor: 'border.strong',
          _disabled: {
            borderColor: 'border.subtle',
          },
        },
        _checked: {
          background: 'background.primary',
          borderColor: 'border.focus',

          _hover: {
            background: 'background.primary.hover',
            borderColor: 'background.primary.hover',
          },
          _disabled: {
            background: 'background.primary',
            borderColor: 'border.focus',
            color: 'text.onContrast',
            _hover: {
              background: 'background.primary',
              borderColor: 'border.focus',
            },
          },
        },
        _disabled: {
          background: 'background.lessSubtle',
          borderColor: 'background.lessSubtle',
        },
        _invalid: {
          _hover: {
            borderColor: 'border.danger',
          },
          _checked: {
            borderColor: 'border.focus',
          },
          _disabled: {
            borderColor: 'border.subtle',
          },
        },
      },
    },
    onContrast: {
      label: {
        color: 'text.onContrast',
      },
      control: {
        background: 'background.contrast',
        borderColor: 'border.subtle',
        _hover: {
          borderColor: 'border.strongContrast',
          _disabled: {
            borderColor: 'background.lessSubtle',
          },
        },
        _checked: {
          background: 'background.primaryContrast',
          borderColor: 'border.focusContrast',
          _before: {
            bg: 'background.contrast',
          },
          _hover: {
            background: 'background.primaryContrast.hover',
            borderColor: 'background.primaryContrast.hover',
          },
          _disabled: {
            background: 'background.primaryContrast',
            borderColor: 'border.focusContrast',
            color: 'text.onContrast',
            _hover: {
              background: 'background.primaryContrast',
              borderColor: 'border.focusContrast',
            },
          },
        },
        _disabled: {
          background: 'background.moreSubtle',
          borderColor: 'background.moreSubtle',
        },
        _invalid: {
          borderColor: 'border.dangerContrast',
          _hover: {
            borderColor: 'border.dangerContrast',
          },
          _checked: {
            borderColor: 'border.focusContrast',
          },
          _disabled: {
            borderColor: 'border.moreSubtle',
          },
        },
      },
    },
  },
  sizes: {
    sm: {
      label: {
        fontSize: 'sm',
      },
      control: {
        height: 3,
        width: 3,
        mt: 1,
      },
    },
    md: {
      label: {
        fontSize: 'md',
      },
      control: {
        height: 4,
        width: 4,
        mt: 1,
      },
    },
    lg: {
      label: {
        fontSize: 'lg',
        marginLeft: 3,
      },
      control: {
        height: 5,
        width: 5,
        mt: '3px',
      },
    },
  },
};
