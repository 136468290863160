import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgPen = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.997 21c.08.01.16.01.24 0l4-1a1 1 0 0 0 .47-.26l12.29-12.33a2 2 0 0 0 0-2.82L19.417 3a2 2 0 0 0-2.83 0L4.297 15.29a1.06 1.06 0 0 0-.27.47l-1 4a1 1 0 0 0 .73 1.24c.08.01.16.01.24 0Zm14-16.59L19.587 6l-1.59 1.59L16.417 6l1.58-1.59Zm-12.09 12.1 9.09-9.1L16.587 9l-9.1 9.1-2.11.52.53-2.11Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPen);
export default ForwardRef;
