import React from 'react';
import { Global } from '@emotion/react';

const Fonts = (): JSX.Element => (
  <Global
    styles={`
      @import url("https://use.typekit.net/wtr4buu.css");
      @font-face {
        font-family: "aktiv-grotesk", sans-serif;
        font-style: normal;
        font-weight: 400;
      }
      @font-face {
        font-family: "aktiv-grotesk", sans-serif;
        font-style: italic;
        font-weight: 400;
      }
      @font-face {
        font-family: "aktiv-grotesk", sans-serif;
        font-style: normal;
        font-weight: 600;
      }
      @font-face {
        font-family: "aktiv-grotesk", sans-serif;
        font-style: italic;
        font-weight: 600;
      }
    `}
  />
);

export default Fonts;
