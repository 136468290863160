import React, { useEffect, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { Text } from '../Text/text.component';
import { RatingDot } from './components/RatingDot/ratingDot.component';

export interface RatingDotsProps {
  minValue: number;
  maxValue: number;
  minLabel?: string;
  maxLabel?: string;
  centeredLabel?: string;
  isInteractive?: boolean;
  isDisabled?: boolean;
  onClick?: (value: number) => void;
  selected?: number;
}

export const RatingDots = ({
  minValue,
  maxValue,
  minLabel,
  maxLabel,
  centeredLabel,
  isDisabled,
  isInteractive,
  onClick,
  selected,
}: RatingDotsProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<number>();

  const roundedMin = useMemo(() => Math.round(minValue), [minValue]);
  const roundedMax = useMemo(() => Math.round(maxValue), [maxValue]);

  const values = useMemo(() => {
    const arr = [];
    for (let i = roundedMin; i <= roundedMax; i++) {
      arr.push(i);
    }
    return arr;
  }, [roundedMax, roundedMin]);

  useEffect(() => {
    if (selected !== undefined) setSelectedValue(selected);
  }, [selected]);

  return (
    <>
      <Flex my={4} flexWrap="wrap" justifyContent={isInteractive ? 'center' : 'flex-start'}>
        {values.map((value) => (
          <RatingDot
            key={`composer-rating-dot-${value}`}
            number={value}
            isInteractive={isInteractive}
            onClick={onClick}
            isDisabled={isDisabled}
            selected={selectedValue === value}
          />
        ))}
      </Flex>
      <Flex justifyContent={isInteractive ? (centeredLabel ? 'center' : 'space-between') : 'flex-start'}>
        {centeredLabel ? (
          <Text variant="subtle" size="sm">
            {centeredLabel}
          </Text>
        ) : (
          <>
            {minLabel && <Text variant="subtle" size="sm">{`${roundedMin} - ${minLabel}`}</Text>}
            {!isInteractive && <Text variant="subtle" size="sm" pr={1}>{`,`}</Text>}
            {maxLabel && <Text variant="subtle" size="sm">{`${roundedMax} - ${maxLabel}`}</Text>}
          </>
        )}
      </Flex>
    </>
  );
};
