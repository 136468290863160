import { IconSize } from 'components/Icon/icon.types';
import { TextSize } from 'components/Text/text.types';

export const useIconTextSizeMap = (textSize: TextSize): IconSize => {
  switch (textSize) {
    case 'xs':
      return 'xs';
    case 'sm':
      return 'sm';
    case 'md':
      return 'md';
    case 'lg':
    case 'xl':
    case '2xl':
    case '3xl':
    case '4xl':
      return 'lg';
    case '5xl':
    case '6xl':
      return '2xl';
  }
};
