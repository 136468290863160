import React, { ReactNode } from 'react';
import { Tag as CTag, TagProps as CTagProps, TagCloseButton, TagLabel, Box } from '@chakra-ui/react';
import { CloseButtonDisplay, TagSize } from './tag.types';
import { paddingRightBySize } from './tag.records';
import { Status } from 'shared/types';
import { FontWeight, SemanticBackgroundColorToken, SemanticTypographyToken } from 'theme/tokens';

export interface KTagProps {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  label: string;
  closeButtonDisplay?: CloseButtonDisplay;
  size?: TagSize;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  fontWeight?: FontWeight;
  variant?: Status;
  color?: SemanticTypographyToken | string;
  bg?: SemanticBackgroundColorToken | string;
}

export type TagProps = KTagProps & CTagProps;

export const Tag = ({
  leftElement,
  rightElement,
  label,
  closeButtonDisplay = 'never',
  onClose,
  fontWeight = 'semibold',
  size = 'md',
  variant = 'default',
  color,
  bg,
  ...rest
}: TagProps): JSX.Element => {
  const labelStyle = {
    ...(!!leftElement && { ml: 1 }),
    ...(!!rightElement && { mr: 1 }),
  };

  const tagStyle = {
    ...(!!onClose && {
      ...(closeButtonDisplay === 'always' && { pr: paddingRightBySize[size] }),
      ...(closeButtonDisplay === 'hover' && {
        role: 'group',
        pos: 'relative' as const,
      }),
    }),
  };

  const showCloseButton = closeButtonDisplay !== 'never' && !!onClose;

  const closeButtonStyle = {
    ...(closeButtonDisplay === 'hover' && {
      display: 'none',
      px: paddingRightBySize[size],
      _groupHover: { display: 'flex' },
      pos: 'absolute' as const,
      right: 0,
      bgColor: 'inherit',
      color: 'inherit',
      opacity: 0.899999,
      borderRadius: 'md',
    }),
  };

  return (
    <CTag {...tagStyle} {...rest} size={size} variant={variant} bg={bg} color={color}>
      {leftElement}
      <TagLabel {...labelStyle} fontWeight={fontWeight}>
        {label}
      </TagLabel>
      {rightElement}
      {showCloseButton && (
        <Box {...closeButtonStyle} color={color ? color : `text.${variant}`}>
          <TagCloseButton onClick={onClose} />
        </Box>
      )}
    </CTag>
  );
};
