import React from 'react';
import { TypographyVariant } from 'shared/types';
import { Icon, IconProps } from '../../Icon/icon.component';

export interface LinkIconProps extends Omit<IconProps, 'position'> {
  position?: 'start' | 'end';
  variant?: TypographyVariant;
}

export const LinkIcon = (iconProps: LinkIconProps): JSX.Element => {
  return <Icon variant={iconProps.variant || 'interactive'} {...iconProps} />;
};
