import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgArrowDown = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11 2.793v14.586l-5.293-5.293L4.293 13.5 12 21.207l7.707-7.707-1.414-1.414L13 17.379V2.793h-2Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowDown);
export default ForwardRef;
