import { IconSize } from '../Icon/icon.types';
import { FormControlSize } from 'shared/types/formControl';

export const IconSizeMap: Record<FormControlSize, IconSize> = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
