import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgAnimalPig = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m16.525 6.25-1.892-1.892c.059-.35.15-.675.267-.958a1.248 1.248 0 0 0-1.15-1.733 4.163 4.163 0 0 0-3.333 1.666H6.25a4.58 4.58 0 0 0-4.583 4.584c0 2.533 2.083 9.583 2.083 9.583h4.583v-1.667H10V17.5h4.583l1.4-4.658 2.35-.784V6.25h-1.808Zm-3.192 2.917a.836.836 0 0 1-.833-.834c0-.458.375-.833.833-.833.459 0 .834.375.834.833a.836.836 0 0 1-.834.834Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAnimalPig);
export default ForwardRef;
