import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgArchive = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.001 11h-8v2h8v-2Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.001 3h16a2 2 0 0 1 2 2v2a2 2 0 0 1-1 1.72V19a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2V8.72a2 2 0 0 1-1-1.72V5a2 2 0 0 1 2-2Zm16 2h-16v2h16V5Zm-15 4v10h14V9h-14Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArchive);
export default ForwardRef;
