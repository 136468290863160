import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgPhone = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.703 12.293a.999.999 0 0 0-1.414 0l-1.594 1.594c-.74-.22-2.118-.72-2.992-1.594-.874-.874-1.374-2.253-1.594-2.992l1.594-1.594a.998.998 0 0 0 0-1.414l-4-4a.999.999 0 0 0-1.414 0L3.577 5.005c-.38.38-.594.902-.586 1.435.023 1.424.4 6.37 4.298 10.268 3.898 3.898 8.844 4.274 10.269 4.298h.028c.528 0 1.027-.208 1.405-.586l2.712-2.712a.998.998 0 0 0 0-1.414l-4-4.001Zm-.127 6.712c-1.248-.021-5.518-.356-8.873-3.712C5.337 11.927 5.01 7.642 4.99 6.419l2.005-2.005L9.582 7 8.289 8.293a1 1 0 0 0-.272.912c.024.115.61 2.842 2.27 4.502 1.66 1.66 4.388 2.247 4.503 2.271a.99.99 0 0 0 .912-.271l1.294-1.293L19.582 17l-2.006 2.005Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPhone);
export default ForwardRef;
