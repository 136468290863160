import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgStarCircle = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m8.484 17.063.664-3.873-2.814-2.744 3.89-.566 1.74-3.524 1.74 3.524 3.889.566-2.816 2.744.665 3.873-3.479-1.828-3.479 1.828Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.963 12c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10Zm2 0c0 4.411 3.59 8 8 8 4.411 0 8-3.589 8-8s-3.589-8-8-8c-4.41 0-8 3.589-8 8Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStarCircle);
export default ForwardRef;
