import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCreditCardLogoGeneric = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="#3F51B5" d="M45 35a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V13a4 4 0 0 1 4-4h34a4 4 0 0 1 4 4v22z" />
    <path
      fill="#FFC107"
      d="M16 21a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v4zm-1 5H9v2h6v-2zm8 5H9v2h14v-2zm0-5h-6v2h6v-2zm8 0h-6v2h6v-2zm8 0h-6v2h6v-2z"
    />
    <path fill="#9FA8DA" d="M20 16h19v4H20z" />
  </svg>
);
const ForwardRef = forwardRef(SvgCreditCardLogoGeneric);
export default ForwardRef;
