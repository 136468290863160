import { ComponentStyleConfig } from '@chakra-ui/react';

export const ButtonConfig: ComponentStyleConfig = {
  baseStyle: {
    border: '1px solid transparent',
    color: 'text.onContrast',
    svg: {
      color: 'text.onContrast',
    },
  },
  variants: {
    primary: {
      bg: 'background.primary',
      _hover: {
        bg: 'background.primary.hover',
        _disabled: {
          bg: 'background.primary',
        },
      },
      _active: {
        bg: 'background.primary.pressed',
      },
    },
    primaryOnContrast: {
      bg: 'background.primaryContrast',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.primaryContrast.hover',
        _disabled: {
          bg: 'background.primaryContrast',
        },
      },
      _active: {
        bg: 'background.primaryContrast.pressed',
      },
    },
    primaryDestructive: {
      bg: 'background.destructive',
      borderColor: 'transparent',
      _hover: {
        bg: 'background.destructive.hover',
        _disabled: {
          bg: 'background.destructive',
        },
      },
      _active: {
        bg: 'background.destructive.pressed',
      },
    },
    primaryDestructiveOnContrast: {
      bg: 'background.destructiveContrast',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.destructiveContrast.hover',
        _disabled: {
          bg: 'background.destructiveContrast',
        },
      },
      _active: {
        bg: 'background.destructiveContrast.pressed',
      },
    },
    secondary: {
      bg: 'transparent',
      borderColor: 'border.strong',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.alpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        color: 'text.interactive',
        svg: {
          color: 'text.interactive',
        },
        borderColor: 'border.focus',
        bg: 'background.secondaryAlpha.pressed',
      },
    },
    secondaryOnContrast: {
      bg: 'transparent',
      borderColor: 'border.strongContrast',
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.secondaryAlphaContrast.pressed',
        borderColor: 'border.focusContrast',
        color: 'text.interactiveOnContrast',
        svg: {
          color: 'text.interactiveOnContrast',
        },
      },
    },
    secondaryDestructive: {
      color: 'text.danger',
      bg: 'transparent',
      borderColor: 'border.danger',
      svg: {
        color: 'text.danger',
      },
      _hover: {
        bg: 'background.alpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alpha.pressed',
      },
    },
    secondaryDestructiveOnContrast: {
      bg: 'transparent',
      color: 'text.dangerOnContrast',
      borderColor: 'border.destructiveContrast',
      svg: {
        color: 'text.dangerOnContrast',
      },
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alphaContrast.pressed',
      },
    },
    secondarySubtle: {
      bg: 'background.default',
      borderColor: 'border.default',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.subtle',
        _disabled: {
          bg: 'background.default',
        },
      },
      _active: {
        color: 'text.interactive',
        svg: {
          color: 'text.interactive',
        },
        borderColor: 'border.focusContrast',
        bg: 'background.secondaryAlpha',
      },
    },
    secondarySubtleOnContrast: {
      bg: 'transparent',
      borderColor: 'border.contrast',
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        color: 'text.interactiveOnContrast',
        svg: {
          color: 'text.interactiveOnContrast',
        },
        borderColor: 'border.focusContrast',
        bg: 'background.secondaryAlphaContrast',
      },
    },
    secondarySubtleDestructive: {
      bg: 'background.default',
      color: 'text.danger',
      borderColor: 'border.default',
      svg: {
        color: 'text.danger',
      },
      _hover: {
        bg: 'background.subtle',
        _disabled: {
          bg: 'background.default',
        },
      },
      _active: {
        bg: 'background.lessSubtle',
      },
    },
    secondarySubtleDestructiveOnContrast: {
      bg: 'background.contrast',
      color: 'text.dangerOnContrast',
      borderColor: 'border.contrast',
      svg: {
        color: 'text.dangerOnContrast',
      },
      _hover: {
        bg: 'background.subtleContrast',
        _disabled: {
          bg: 'background.contrast',
        },
      },
      _active: {
        bg: 'background.moreSubtleContrast',
      },
    },
    tertiary: {
      color: 'text.default',
      bg: 'background.alpha',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.alpha.hover',
        _disabled: {
          bg: 'background.alpha',
        },
      },
      _active: {
        color: 'text.interactive',
        svg: {
          color: 'text.interactive',
        },
        bg: 'background.secondaryAlpha.pressed',
      },
    },
    tertiaryOnContrast: {
      bg: 'background.alphaContrast',
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'background.alphaContrast',
        },
      },
      _active: {
        bg: 'background.secondaryAlphaContrast.pressed',
        color: 'text.interactiveOnContrast',
        svg: {
          color: 'text.interactiveOnContrast',
        },
      },
    },
    tertiaryDestructive: {
      bg: 'background.destructiveAlpha',
      color: 'text.danger',
      svg: {
        color: 'text.danger',
      },
      _hover: {
        bg: 'background.destructiveAlpha.hover',
        _disabled: {
          bg: 'background.destructiveAlpha',
        },
      },
      _active: {
        bg: 'background.destructiveAlpha.pressed',
      },
    },
    tertiaryDestructiveOnContrast: {
      bg: 'background.destructiveAlphaContrast',
      color: 'text.dangerOnContrast',
      svg: {
        color: 'text.dangerOnContrast',
      },
      _hover: {
        bg: 'background.destructiveAlphaContrast.hover',
        _disabled: {
          bg: 'background.destructiveAlphaContrast',
        },
      },
      _active: {
        bg: 'background.destructiveAlphaContrast.pressed',
      },
    },
    ghost: {
      color: 'text.interactive',
      bg: 'transparent',
      svg: {
        color: 'text.interactive',
      },
      _hover: {
        bg: 'background.secondaryAlpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.secondaryAlpha.pressed',
      },
    },
    ghostOnContrast: {
      color: 'text.interactiveOnContrast',
      bg: 'transparent',
      svg: {
        color: 'text.interactiveOnContrast',
      },
      _hover: {
        bg: 'background.secondaryAlphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.secondaryAlphaContrast.pressed',
      },
    },
    ghostNeutral: {
      bg: 'transparent',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.alpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.secondaryAlpha.pressed',
        color: 'text.interactive',
        svg: {
          color: 'text.interactive',
        },
      },
    },
    ghostNeutralOnContrast: {
      bg: 'transparent',
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.secondaryAlphaContrast.pressed',
        color: 'text.interactiveOnContrast',
        svg: {
          color: 'text.interactiveOnContrast',
        },
      },
    },
    ghostDestructive: {
      bg: 'transparent',
      color: 'text.danger',
      svg: {
        color: 'text.danger',
      },
      _hover: {
        bg: 'background.destructiveAlpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.destructiveAlpha.pressed',
      },
    },
    ghostDestructiveOnContrast: {
      bg: 'transparent',
      color: 'text.dangerOnContrast',
      svg: {
        color: 'text.dangerOnContrast',
      },
      _hover: {
        bg: 'background.destructiveAlphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.destructiveAlphaContrast.pressed',
      },
    },
  },
  sizes: {
    lg: {
      p: '10px 20px',
    },
    md: {
      p: '8px 16px',
    },
    sm: {
      p: '6px 12px',
    },
    xs: {
      p: '3px 8px',
    },
  },
};
