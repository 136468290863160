import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgLocationMarker = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 10c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4Zm-2 0c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.897 2-2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22a.998.998 0 0 1-.58-.186C11.116 21.599 3.971 16.44 4 9.995 4 5.589 7.59 2 12 2c4.411 0 8 3.589 8 8 .03 6.44-7.116 11.599-7.42 11.814A.998.998 0 0 1 12 22Zm6-11.995A6.007 6.007 0 0 0 12 4c-3.309 0-6 2.691-6 6-.02 4.441 4.39 8.427 6 9.735 1.612-1.307 6.021-5.292 6-9.73Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLocationMarker);
export default ForwardRef;
