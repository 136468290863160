import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgSend = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m21.427 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.97 12l-1.94 7.758a.998.998 0 0 0 1.397 1.147l17-8a1 1 0 0 0 0-1.81ZM5.482 18.197l.839-3.357L12 12 6.32 9.16l-.84-3.357L18.652 12l-13.17 6.197Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSend);
export default ForwardRef;
