import { CoreColors, SemanticColorToken } from './color.types';

export const corePalette: CoreColors = {
  primary: {
    50: '#F6FCEB',
    100: '#E5F9C6',
    200: '#CDF197',
    300: '#BDE87D',
    400: '#9FCF5D',
    500: '#6AAE0D',
    600: '#4F830F',
    700: '#3E660C',
    800: '#284309',
    900: '#1A2D06',
  },
  secondary: {
    50: '#F6FAFF',
    100: '#E9F2FF',
    200: '#CBE2FF',
    300: '#ADCFFF',
    400: '#7FAFF2',
    500: '#497CC3',
    600: '#305992',
    700: '#2B5082',
    800: '#122136',
    900: '#080E17',
  },
  tertiary: {
    50: '#FAF8F2',
    100: '#F6F3EA',
    200: '#EAE4DD',
    300: '#DCD1C6',
    400: '#BCAC9E',
    500: '#9D7E6B',
    600: '#7D5B48',
    700: '#5E3B28',
    800: '#381F12',
    900: '#170D07',
  },
  neutral: {
    50: '#FFFFFF',
    100: '#FCFBFA',
    200: '#EDEBEB',
    300: '#DCDBDA',
    400: '#BEBCB9',
    500: '#96928F',
    600: '#706E6C',
    700: '#504D4C',
    800: '#373331',
    900: '#000000',
  },
  danger: {
    50: '#FFF4F7',
    100: '#FFDFE6',
    200: '#FFC9D4',
    300: '#F9A7A6',
    400: '#F78C7F',
    500: '#EE634A',
    600: '#CA4038',
    700: '#A6333F',
    800: '#82314D',
    900: '#48091F',
  },
  warning: {
    50: '#FFFDFA',
    100: '#FFF7EB',
    200: '#FEE8C3',
    300: '#F6BE5E',
    400: '#ECB14B',
    500: '#D69E2E',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
  },
  success: {
    50: '#F6FCEB',
    100: '#E5F9C6',
    200: '#CDF197',
    300: '#BDE87D',
    400: '#9FCF5D',
    500: '#6AAE0D',
    600: '#4F830F',
    700: '#3E660C',
    800: '#284309',
    900: '#1A2D06',
  },
  primaryAlpha: {
    50: '#4F830F0A',
    100: '#4F830F0F',
    200: '#4F830F14',
    300: '#4F830F29',
    400: '#4F830F3D',
    500: '#4F830F5C',
    600: '#4F830F7A',
    700: '#4F830F9E',
    800: '#4F830FCC',
    900: '#4F830FEB',
  },
  primaryAlphaInverse: {
    50: '#CDF1970A',
    100: '#CDF1970F',
    200: '#CDF19714',
    300: '#CDF19729',
    400: '#CDF1973D',
    500: '#CDF1975C',
    600: '#CDF1977A',
    700: '#CDF1979E',
    800: '#CDF197CC',
    900: '#CDF197EB',
  },
  secondaryAlpha: {
    50: '#497CC30A',
    100: '#497CC30F',
    200: '#497CC314',
    300: '#497CC329',
    400: '#497CC33D',
    500: '#497CC35C',
    600: '#497CC37A',
    700: '#497CC39E',
    800: '#497CC3CC',
    900: '#497CC3EB',
  },
  secondaryAlphaInverse: {
    50: '#CBE2FF0A',
    100: '#CBE2FF0F',
    200: '#CBE2FF14',
    300: '#CBE2FF29',
    400: '#CBE2FF3D',
    500: '#CBE2FF5C',
    600: '#CBE2FF7A',
    700: '#CBE2FF9E',
    800: '#CBE2FFCC',
    900: '#CBE2FFEB',
  },
  blackAlpha: {
    50: '#3635460A',
    100: '#3635460F',
    200: '#36354614',
    300: '#36354629',
    400: '#3635463D',
    500: '#3635465C',
    600: '#3635467A',
    700: '#3635469E',
    800: '#363546CC',
    900: '#363546EB',
  },
  whiteAlpha: {
    50: '#FFFFFF0A',
    100: '#FFFFFF0F',
    200: '#FFFFFF14',
    300: '#FFFFFF29',
    400: '#FFFFFF3D',
    500: '#FFFFFF5C',
    600: '#FFFFFF7A',
    700: '#FFFFFF9E',
    800: '#FFFFFFCC',
    900: '#FFFFFFEB',
  },
  dangerAlpha: {
    50: '#CA40380A',
    100: '#CA40380F',
    200: '#CA403814',
    300: '#CA403829',
    400: '#CA40383D',
    500: '#CA40385C',
    600: '#CA40387A',
    700: '#CA40389E',
    800: '#CA4038CC',
    900: '#CA4038EB',
  },
  dangerAlphaInverse: {
    50: '#F9A7A60A',
    100: '#F9A7A60F',
    200: '#F9A7A614',
    300: '#F9A7A629',
    400: '#F9A7A63D',
    500: '#F9A7A65C',
    600: '#F9A7A67A',
    700: '#F9A7A69E',
    800: '#F9A7A6CC',
    900: '#F9A7A6EB',
  },
};

const aliasMap = {
  primary: {
    default: {
      default: corePalette.primary['600'],
      _dark: corePalette.primary['200'],
    },
    hover: {
      default: corePalette.primary['700'],
      _dark: corePalette.primary['100'],
    },
    pressed: {
      default: corePalette.primary['800'],
      _dark: corePalette.primary['50'],
    },
    inverse: {
      default: corePalette.primary['200'],
      _dark: corePalette.primary['600'],
    },
    inverseHover: {
      default: corePalette.primary['100'],
      _dark: corePalette.primary['700'],
    },
    inversePressed: {
      default: corePalette.primary['50'],
      _dark: corePalette.primary['800'],
    },
    subtle: {
      default: corePalette.primary['100'],
      _dark: corePalette.neutral['700'],
    },
  },
  secondary: {
    default: {
      default: corePalette.secondary['500'],
      _dark: corePalette.secondary['200'],
    },
    hover: {
      default: corePalette.secondary['600'],
      _dark: corePalette.secondary['100'],
    },
    pressed: {
      default: corePalette.secondary['700'],
      _dark: corePalette.secondary['50'],
    },
    inverse: {
      default: corePalette.secondary['200'],
      _dark: corePalette.secondary['500'],
    },
    inverseHover: {
      default: corePalette.secondary['100'],
      _dark: corePalette.secondary['600'],
    },
    inversePressed: {
      default: corePalette.secondary['50'],
      _dark: corePalette.secondary['700'],
    },
    subtle: {
      default: corePalette.secondary['100'],
      _dark: corePalette.secondary['600'],
    },
  },
  danger: {
    default: {
      default: corePalette.danger['600'],
      _dark: corePalette.danger['300'],
    },
    hover: {
      default: corePalette.danger['600'],
      _dark: corePalette.danger['200'],
    },
    pressed: {
      default: corePalette.danger['700'],
      _dark: corePalette.danger['100'],
    },
    inverse: {
      default: corePalette.danger['300'],
      _dark: corePalette.danger['500'],
    },
    inverseHover: {
      default: corePalette.danger['200'],
      _dark: corePalette.danger['600'],
    },
    inversePressed: {
      default: corePalette.danger['100'],
      _dark: corePalette.danger['700'],
    },
    subtle: {
      default: corePalette.danger['200'],
      _dark: corePalette.danger['600'],
    },
  },
  warning: {
    default: {
      default: corePalette.warning['500'],
      _dark: corePalette.warning['600'],
    },
    subtle: {
      default: corePalette.warning['100'],
      _dark: corePalette.warning['700'],
    },
    note: {
      default: corePalette.warning['100'],
      _dark: corePalette.warning['800'],
    },
  },
  success: {
    default: {
      default: corePalette.success['600'],
      _dark: corePalette.success['300'],
    },
    subtle: {
      default: corePalette.success['100'],
      _dark: corePalette.success['700'],
    },
  },
  neutral: {
    light: {
      default: corePalette.neutral['50'],
      _dark: corePalette.neutral['900'],
    },
    subtle1: {
      default: corePalette.neutral['100'],
      _dark: corePalette.neutral['800'],
    },
    subtle2: {
      default: corePalette.neutral['200'],
      _dark: corePalette.neutral['700'],
    },
    subtle3: {
      default: corePalette.neutral['300'],
      _dark: corePalette.neutral['600'],
    },
    subtle4: {
      default: corePalette.neutral['600'],
      _dark: corePalette.neutral['400'],
    },
    dark: {
      default: corePalette.neutral['900'],
      _dark: corePalette.neutral['50'],
    },
    overlay: {
      default: corePalette.neutral['50'],
      _dark: corePalette.neutral['800'],
    },
    backdrop: {
      default: corePalette.blackAlpha['600'],
      _dark: corePalette.blackAlpha['600'],
    },
    subtle1Inverse: {
      default: corePalette.neutral['800'],
      _dark: corePalette.neutral['100'],
    },
    subtle2Inverse: {
      default: corePalette.neutral['700'],
      _dark: corePalette.neutral['200'],
    },
    subtle3Inverse: {
      default: corePalette.neutral['600'],
      _dark: corePalette.neutral['300'],
    },
    subtle4Inverse: {
      default: corePalette.neutral['400'],
      _dark: corePalette.neutral['600'],
    },
  },
  primaryAlpha: {
    default: {
      default: corePalette.primaryAlpha['50'],
      _dark: corePalette.primaryAlphaInverse['300'],
    },
    hover: {
      default: corePalette.primaryAlpha['100'],
      _dark: corePalette.primaryAlphaInverse['400'],
    },
    pressed: {
      default: corePalette.primaryAlpha['200'],
      _dark: corePalette.primaryAlphaInverse['500'],
    },
    inverse: {
      default: corePalette.primaryAlphaInverse['300'],
      _dark: corePalette.primaryAlpha['50'],
    },
    inverseHover: {
      default: corePalette.primaryAlphaInverse['400'],
      _dark: corePalette.primaryAlpha['100'],
    },
    inversePressed: {
      default: corePalette.primaryAlphaInverse['500'],
      _dark: corePalette.primaryAlpha['200'],
    },
  },
  secondaryAlpha: {
    default: {
      default: corePalette.secondaryAlpha['50'],
      _dark: corePalette.secondaryAlphaInverse['300'],
    },
    hover: {
      default: corePalette.secondaryAlpha['100'],
      _dark: corePalette.secondaryAlphaInverse['400'],
    },
    pressed: {
      default: corePalette.secondaryAlpha['200'],
      _dark: corePalette.secondaryAlphaInverse['500'],
    },
    inverse: {
      default: corePalette.secondaryAlphaInverse['300'],
      _dark: corePalette.secondaryAlpha['50'],
    },
    inverseHover: {
      default: corePalette.secondaryAlphaInverse['400'],
      _dark: corePalette.secondaryAlpha['100'],
    },
    inversePressed: {
      default: corePalette.secondaryAlphaInverse['500'],
      _dark: corePalette.secondaryAlpha['200'],
    },
  },
  dangerAlpha: {
    default: {
      default: corePalette.dangerAlpha['50'],
      _dark: corePalette.dangerAlphaInverse['300'],
    },
    hover: {
      default: corePalette.dangerAlpha['100'],
      _dark: corePalette.dangerAlphaInverse['400'],
    },
    pressed: {
      default: corePalette.dangerAlpha['200'],
      _dark: corePalette.dangerAlphaInverse['500'],
    },
    inverse: {
      default: corePalette.dangerAlphaInverse['300'],
      _dark: corePalette.dangerAlpha['50'],
    },
    inverseHover: {
      default: corePalette.dangerAlphaInverse['400'],
      _dark: corePalette.dangerAlpha['100'],
    },
    inversePressed: {
      default: corePalette.dangerAlphaInverse['500'],
      _dark: corePalette.dangerAlpha['200'],
    },
  },
  neutralAlpha: {
    default: {
      default: corePalette.blackAlpha['50'],
      _dark: corePalette.whiteAlpha['300'],
    },
    hover: {
      default: corePalette.blackAlpha['100'],
      _dark: corePalette.whiteAlpha['400'],
    },
    pressed: {
      default: corePalette.blackAlpha['200'],
      _dark: corePalette.whiteAlpha['500'],
    },
    dark: {
      default: corePalette.blackAlpha['500'],
      _dark: corePalette.whiteAlpha['800'],
    },
    inverse: {
      default: corePalette.whiteAlpha['300'],
      _dark: corePalette.blackAlpha['50'],
    },
    inverseHover: {
      default: corePalette.whiteAlpha['400'],
      _dark: corePalette.blackAlpha['100'],
    },
    inversePressed: {
      default: corePalette.whiteAlpha['500'],
      _dark: corePalette.blackAlpha['200'],
    },
    inverseDark: {
      default: corePalette.whiteAlpha['800'],
      _dark: corePalette.blackAlpha['500'],
    },
  },
};

// Once we upgrade Chakra to < v2.6 we can nest these
export const semanticColorTokens: Record<SemanticColorToken, Record<'default' | '_dark', string>> = {
  // token structure = [property][variant][alpha?][contrast?][state?]
  'text.default': aliasMap.neutral.dark,
  'text.subtle': aliasMap.neutral.subtle4,
  'text.interactive': aliasMap.secondary.default,
  'text.interactive.hover': aliasMap.secondary.hover,
  'text.brand': aliasMap.primary.default,
  'text.brand.hover': aliasMap.primary.hover,
  'text.info': aliasMap.secondary.default,
  'text.success': aliasMap.success.default,
  'text.warning': aliasMap.warning.default,
  'text.danger': aliasMap.danger.default,
  'text.placeholder': aliasMap.neutralAlpha.dark,
  'text.accent': aliasMap.secondary.default,
  'text.accentSubtle': aliasMap.secondary.subtle,
  'text.onContrast': aliasMap.neutral.light,
  'text.subtleOnContrast': aliasMap.neutral.subtle4Inverse,
  'text.interactiveOnContrast': aliasMap.secondary.inverse,
  'text.interactiveOnContrast.hover': aliasMap.secondary.inverseHover,
  'text.brandOnContrast': aliasMap.primary.inverse,
  'text.brandOnContrast.hover': aliasMap.primary.inverseHover,
  'text.dangerOnContrast': aliasMap.danger.inverse,
  'text.placeholderOnContrast': aliasMap.neutralAlpha.inverseDark,
  // backgrounds
  'background.default': aliasMap.neutral.light,
  'background.subtle': aliasMap.neutral.subtle1,
  'background.lessSubtle': aliasMap.neutral.subtle2,
  'background.primary': aliasMap.primary.default,
  'background.primary.hover': aliasMap.primary.hover,
  'background.primary.pressed': aliasMap.primary.pressed,
  'background.secondary': aliasMap.secondary.default,
  'background.secondary.hover': aliasMap.secondary.hover,
  'background.secondary.pressed': aliasMap.secondary.pressed,
  'background.brand': aliasMap.primary.pressed,
  'background.info': aliasMap.secondary.subtle,
  'background.success': aliasMap.success.subtle,
  'background.warning': aliasMap.warning.subtle,
  'background.danger': aliasMap.danger.subtle,
  'background.destructive': aliasMap.danger.default,
  'background.destructive.hover': aliasMap.danger.hover,
  'background.destructive.pressed': aliasMap.danger.pressed,
  'background.accent': aliasMap.secondary.default,
  'background.accentSubtle': aliasMap.secondary.subtle,
  'background.note': aliasMap.warning.note,
  'background.note.hover': aliasMap.warning.subtle,
  'background.alpha': aliasMap.neutralAlpha.default,
  'background.alpha.hover': aliasMap.neutralAlpha.hover,
  'background.alpha.pressed': aliasMap.neutralAlpha.pressed,
  'background.primaryAlpha': aliasMap.primaryAlpha.default,
  'background.primaryAlpha.hover': aliasMap.primaryAlpha.hover,
  'background.primaryAlpha.pressed': aliasMap.primaryAlpha.pressed,
  'background.secondaryAlpha': aliasMap.secondaryAlpha.default,
  'background.secondaryAlpha.hover': aliasMap.secondaryAlpha.hover,
  'background.secondaryAlpha.pressed': aliasMap.secondaryAlpha.pressed,
  'background.destructiveAlpha': aliasMap.dangerAlpha.default,
  'background.destructiveAlpha.hover': aliasMap.dangerAlpha.hover,
  'background.destructiveAlpha.pressed': aliasMap.dangerAlpha.pressed,
  'background.overlay': aliasMap.neutral.overlay,
  'background.backdrop': aliasMap.neutral.backdrop,
  'background.contrast': aliasMap.neutral.subtle1Inverse,
  'background.subtleContrast': aliasMap.neutral.subtle2Inverse,
  'background.moreSubtleContrast': aliasMap.neutral.subtle3Inverse,
  'background.mostSubtleContrast': aliasMap.neutral.subtle3,
  'background.primaryContrast': aliasMap.primary.inverse,
  'background.primaryContrast.hover': aliasMap.primary.inverseHover,
  'background.primaryContrast.pressed': aliasMap.primary.inversePressed,
  'background.secondaryContrast': aliasMap.secondary.inverse,
  'background.secondaryContrast.hover': aliasMap.secondary.inverseHover,
  'background.secondaryContrast.pressed': aliasMap.secondary.inversePressed,
  'background.successContrast': aliasMap.success.default,
  'background.warningContrast': aliasMap.warning.default,
  'background.dangerContrast': aliasMap.danger.default,
  'background.destructiveContrast': aliasMap.danger.inverse,
  'background.destructiveContrast.hover': aliasMap.danger.inverseHover,
  'background.destructiveContrast.pressed': aliasMap.danger.inversePressed,
  'background.alphaContrast': aliasMap.neutralAlpha.inverse,
  'background.alphaContrast.hover': aliasMap.neutralAlpha.inverseHover,
  'background.alphaContrast.pressed': aliasMap.neutralAlpha.inversePressed,
  'background.primaryAlphaContrast': aliasMap.primaryAlpha.inverse,
  'background.primaryAlphaContrast.hover': aliasMap.primaryAlpha.inverseHover,
  'background.primaryAlphaContrast.pressed': aliasMap.primaryAlpha.inversePressed,
  'background.secondaryAlphaContrast': aliasMap.secondaryAlpha.inverse,
  'background.secondaryAlphaContrast.hover': aliasMap.secondaryAlpha.inverseHover,
  'background.secondaryAlphaContrast.pressed': aliasMap.secondaryAlpha.inversePressed,
  'background.destructiveAlphaContrast': aliasMap.dangerAlpha.inverse,
  'background.destructiveAlphaContrast.hover': aliasMap.dangerAlpha.inverseHover,
  'background.destructiveAlphaContrast.pressed': aliasMap.dangerAlpha.inversePressed,
  // borders
  'divider.default': aliasMap.neutral.subtle2,
  'border.default': aliasMap.neutral.subtle2,
  'border.subtle': aliasMap.neutral.subtle4,
  'border.strong': aliasMap.neutral.dark,
  'border.focus': aliasMap.secondary.default,
  'border.brand': aliasMap.primary.default,
  'border.info': aliasMap.secondary.default,
  'border.success': aliasMap.success.default,
  'border.warning': aliasMap.warning.default,
  'border.danger': aliasMap.danger.default,
  'border.accent': aliasMap.secondary.default,
  'border.accentSubtle': aliasMap.secondary.subtle,
  'border.contrast': aliasMap.neutral.subtle2Inverse,
  'border.subtleContrast': aliasMap.neutral.subtle4Inverse,
  'border.focusContrast': aliasMap.secondary.inverse,
  'border.strongContrast': aliasMap.neutral.light,
  'border.dangerContrast': aliasMap.danger.inverse,
};
