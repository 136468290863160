import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCreditCardLogoJcb = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="#CFD8DC" d="M45 35a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V13a4 4 0 0 1 4-4h34a4 4 0 0 1 4 4v22z" />
    <path fill="#3F51B5" d="M10.669 13C8.58 13 7 14.324 7 16.617V35h6.483C15.114 35 17 33.469 17 31.483V13h-6.331z" />
    <path
      fill="#E53935"
      d="M22.669 13C20.58 13 19 14.324 19 16.617V35h6.483C27.114 35 29 33.469 29 31.483V13h-6.331z"
    />
    <path
      fill="#388E3C"
      d="M34.669 13C32.58 13 31 14.324 31 16.617V35h6.483C39.114 35 41 33.469 41 31.483V13h-6.331z"
    />
    <path
      fill="#FAFAFA"
      d="M15.806 24.453c0 1.835-2.369 2.803-4.101 2.803-1.884 0-3.567-1.12-3.926-2.545h1.274c.152.866 1.021 1.475 1.987 1.475 1.07 0 1.834-.764 1.834-1.732v-3.311h2.931v3.31zm22.8-.415c.762 0 1.295-.759 1.295-1.519 0-.762-.663-1.422-1.425-1.422h-6.262v5.852h6.392c.863 0 1.522-.757 1.523-1.519 0-.759-.659-1.392-1.523-1.392zm-3.955-2.331h1.979c.504 0 .961.453.961.959 0 .509-.457.967-.961.967h-1.979v-1.926zm2.028 4.636h-2.027v-1.928h2.027c.508 0 .965.456.965.965 0 .505-.457.963-.965.963zm-8.868-4.167c-1.168-.27-2.286-.427-2.845-.427-1.218 0-2.181 1.017-2.181 2.303 0 1.292.963 2.311 2.181 2.311.559 0 1.677-.107 2.845-.38v1.021h-4.875c-1.522 0-2.79-1.342-2.79-2.952 0-1.607 1.268-2.948 2.79-2.948h4.875v1.072z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCreditCardLogoJcb);
export default ForwardRef;
