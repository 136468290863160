import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgLoudspeaker = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M6 8.497h2v5.913H6V8.497Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 5.8v-.002a2.801 2.801 0 0 0-3.74-2.635L8.453 6.664H5.6a3.602 3.602 0 0 0-3.6 3.6v2.398c0 1.987 1.613 3.6 3.6 3.6h2.808l-.673 1.402a2 2 0 0 0 1.162 2.76l2.503.847a2 2 0 0 0 2.42-.98l.916-1.786 3.523 1.258A2.8 2.8 0 0 0 22 17.128V5.8Zm-2 0a.8.8 0 0 0-1.069-.753L8.801 8.664H5.6a1.598 1.598 0 0 0-1.6 1.6v2.398a1.603 1.603 0 0 0 1.6 1.6h3.2l10.131 3.617A.8.8 0 0 0 20 17.126V5.8Zm-7.163 12.027-2.528-.903-.771 1.606 2.503.846.796-1.55Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLoudspeaker);
export default ForwardRef;
