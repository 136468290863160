import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgPrinter = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.001 12v-2h-4v2h4Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.001 7h1c1.654 0 3 1.346 3 3v7c0 1.103-.897 2-2 2h-2v3h-12v-3h-2c-1.103 0-2-.897-2-2v-7c0-1.654 1.346-3 3-3h1V2h12v5Zm-2-3h-8v3h8V4Zm-8 16h8v-4h-8v4Zm10-3h2v-7c0-.551-.448-1-1-1h-14c-.551 0-1 .449-1 1v7h2v-3h12v3Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPrinter);
export default ForwardRef;
