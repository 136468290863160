import React, { ReactNode } from 'react';
import { Text as CText, TextProps as CTextProps } from '@chakra-ui/react';
import { SemanticTypographyToken } from 'theme/tokens';
import { TypographyVariant } from 'shared/types/typography';
import { TextSize } from './text.types';
import { withTruncation, WithTruncationProps } from 'shared/hocs/withTruncation';

export interface KTextProps extends WithTruncationProps {
  color?: SemanticTypographyToken | string;
  size?: TextSize;
  variant?: TypographyVariant;
  children?: string | ReactNode;
}

export type TextProps = KTextProps & CTextProps;

const Text = ({ children, size = 'md', variant = 'default', color, as = 'span', ...rest }: TextProps): JSX.Element => {
  return (
    <CText variant={variant} size={size} letterSpacing=".01em" {...(color && { color })} as={as} {...rest}>
      {children}
    </CText>
  );
};

Text.displayName = 'Text';

const WrappedText = withTruncation(Text);

export { WrappedText as Text };
