export const swatchSizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
export type SwatchSize = (typeof swatchSizes)[number];

export const SwatchSizeMap: Record<SwatchSize, number> = {
  xs: 14,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 32,
};
