export { IconName } from '../../icons/index';

export const iconSizes = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'] as const;
export type IconSize = (typeof iconSizes)[number];

export const IconSizeMap: Record<IconSize, number> = {
  xs: 14,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 32,
  '2xl': 38,
};
