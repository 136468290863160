import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgBone = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19.038 4.942a3.953 3.953 0 0 0-1.028-1.8c-1.51-1.51-4.146-1.51-5.656 0a4.009 4.009 0 0 0-.618 4.86l-3.714 3.713c-1.505-.89-3.59-.649-4.86.618a4.004 4.004 0 0 0 0 5.657c.498.497 1.12.852 1.8 1.028.178.681.53 1.302 1.028 1.8a3.964 3.964 0 0 0 2.828 1.173 3.974 3.974 0 0 0 2.828-1.172 4.007 4.007 0 0 0 .617-4.859l3.714-3.714c1.507.891 3.593.65 4.861-.619a4.003 4.003 0 0 0 0-5.656 3.943 3.943 0 0 0-1.8-1.029Zm.387 5.271c-.756.755-2.073.756-2.829 0l-.707-.707-6.363 6.364.707.707a2.003 2.003 0 0 1 0 2.828c-.757.757-2.074.755-2.829 0a1.963 1.963 0 0 1-.57-1.31l-.048-.9-.9-.047a1.971 1.971 0 0 1-1.31-.57 2.003 2.003 0 0 1 0-2.83 1.986 1.986 0 0 1 1.413-.585 1.99 1.99 0 0 1 1.414.585l.707.707 6.364-6.363-.707-.707a2.003 2.003 0 0 1 0-2.829c.756-.754 2.072-.754 2.828 0 .343.343.546.81.572 1.312l.048.897.897.048c.503.026.97.23 1.312.572.377.378.585.88.585 1.414 0 .534-.207 1.036-.584 1.414Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBone);
export default ForwardRef;
