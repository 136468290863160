import React, { ForwardedRef, forwardRef, useMemo } from 'react';
import { Button as CButton, ButtonProps as CButtonProps, IconButton as CIconButton } from '@chakra-ui/react';
import { Icon } from '../Icon/icon.component';
import { ButtonIconPosition, ButtonSize, ButtonVariant } from './button.types';
import { camelCaseToLabel } from 'shared/utils/strings';
import { IconName } from '../../icons';

export interface KButtonProps {
  iconName?: IconName;
  iconPosition?: ButtonIconPosition;
  isActive?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isOnContrast?: boolean;
  loadingText?: string;
  size?: ButtonSize;
  spinnerPlacement?: 'start' | 'end';
  variant?: ButtonVariant;
}

export type ButtonProps = KButtonProps & Omit<CButtonProps, 'variant'>;

export const Button = forwardRef(
  (
    {
      children,
      iconName,
      iconPosition,
      isActive = false,
      isOnContrast = false,
      size = 'md',
      variant = 'primary',
      ...domProps
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ): JSX.Element => {
    const computedVariant = useMemo(() => `${variant}${isOnContrast ? 'OnContrast' : ''}`, [isOnContrast, variant]);

    if (iconName) {
      const ButtonIcon = <Icon size={size} name={iconName} />;

      if (!children) {
        const label = camelCaseToLabel(iconName);
        return (
          <CIconButton
            variant={computedVariant}
            ref={ref}
            aria-label={label}
            icon={ButtonIcon}
            size={size}
            isActive={isActive}
            {...domProps}
          />
        );
      } else {
        const position = iconPosition === 'right' ? 'rightIcon' : 'leftIcon';
        domProps[position] = ButtonIcon;
      }
    }

    return (
      <CButton
        fontWeight="semibold"
        cursor="pointer"
        ref={ref}
        size={size}
        iconSpacing={size === 'sm' || size === 'xs' ? '4px' : '8px'}
        borderRadius="base"
        variant={computedVariant}
        isActive={isActive}
        {...domProps}
      >
        {children}
      </CButton>
    );
  },
);

Button.displayName = 'Button';
