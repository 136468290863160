import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgGif = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 12c0-.614.129-1.173.387-1.677A2.995 2.995 0 0 1 6.52 9.125c.498-.295 1.078-.442 1.742-.442.559 0 1.047.107 1.465.322.417.215.746.498.986.848.245.344.405.716.479 1.115H9.948a1.721 1.721 0 0 0-.59-.848c-.276-.22-.636-.331-1.078-.331a1.94 1.94 0 0 0-1.069.295 1.953 1.953 0 0 0-.71.801A2.458 2.458 0 0 0 6.254 12c0 .418.077.796.23 1.133.154.332.378.596.673.793.301.19.66.285 1.078.285.295 0 .568-.067.82-.202.252-.142.458-.314.617-.516.166-.21.27-.412.314-.609H8.317v-1.031h2.875v3.39h-1.07l-.073-.847c-.153.215-.405.423-.755.626a2.373 2.373 0 0 1-1.198.295c-.633 0-1.183-.147-1.65-.442a2.946 2.946 0 0 1-1.077-1.198A3.773 3.773 0 0 1 5 12ZM12.295 15.243V8.757h1.253v6.486h-1.253ZM14.725 8.757H19v1.105h-3.022v1.622h2.432v1.032h-2.432v2.727h-1.253V8.757Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.998 6a2 2 0 0 1 2-2h16.004a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3.998a2 2 0 0 1-2-2V6Zm2 0h16.004v12H3.998V6Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGif);
export default ForwardRef;
