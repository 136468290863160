import { NotificationCounterSize } from './notificationCounter.types';

export const MAX_COUNT = 99;

export const notificationCounterSizes: Record<NotificationCounterSize, number> = {
  xs: 3,
  sm: 4,
  md: 5,
  lg: 6,
};

export const notificationCounterFontSizes: Record<NotificationCounterSize, string> = {
  xs: '8px', // Not used since the xs size doesn't show a label
  sm: '10px',
  md: '12px',
  lg: '14px',
};

export const maxNotificationCounterSizes: Record<NotificationCounterSize, string> = {
  xs: '1px', // Not used since the xs size doesn't show a label
  sm: '2px',
  md: '3px',
  lg: '4px',
};
