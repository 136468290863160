import { ComponentStyleConfig } from '@chakra-ui/react';

export const AlertConfig: ComponentStyleConfig = {
  baseStyle: {
    container: {
      borderRadius: 'md',
      width: 'inherit',
      color: 'text.default',
      py: 2,
    },
  },
  variants: {
    info: {
      container: {
        bg: 'background.info',
      },
    },
    success: {
      container: {
        bg: 'background.success',
      },
    },
    warning: {
      container: {
        bg: 'background.warning',
      },
    },
    error: {
      container: {
        bg: 'background.danger',
      },
    },
    default: {
      container: {
        bg: 'background.default',
      },
    },
    infoToast: {
      container: {
        bg: 'background.contrast',
        color: 'text.onContrast',
      },
    },
    successToast: {
      container: {
        bg: 'background.successContrast',
        color: 'text.onContrast',
      },
    },
    warningToast: {
      container: {
        bg: 'background.warningContrast',
        color: 'text.onContrast',
      },
    },
    errorToast: {
      container: {
        bg: 'background.dangerContrast',
        color: 'text.onContrast',
      },
    },
    defaultToast: {
      container: {
        bg: 'background.contrast',
        color: 'text.onContrast',
      },
    },
  },
};
