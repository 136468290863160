import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgAnimalMouse = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.775 14.492c1.167 1.058.392 3.008-1.175 3.008H9.217A4.2 4.2 0 0 1 5 13.283v-.041a4.155 4.155 0 0 1-3.333-4.075A4.163 4.163 0 0 1 5.833 5h2.084c.25 0 .416-.192.416-.417a.407.407 0 0 0-.416-.416H5.833V2.5h2.084A2.09 2.09 0 0 1 10 4.583a2.079 2.079 0 0 1-2.083 2.084H5.833a2.491 2.491 0 0 0-2.5 2.5c0 1.141.767 2.083 1.784 2.391.466-1.833 2.108-3.225 4.1-3.225.666 0 1.333.184 1.916.459-1.175.658-1.966 1.875-1.966 3.291a3.64 3.64 0 0 0 1.108 2.642l.583-.583c-.541-.509-.858-1.25-.858-2.059 0-2.158 1.95-2.916 2.917-2.916 1.733 0 3.291 1.575 2.866 3.525l1.992 1.8ZM15 15.833a.825.825 0 0 0 .833-.833.825.825 0 0 0-.833-.833.825.825 0 0 0-.833.833c0 .467.366.833.833.833Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAnimalMouse);
export default ForwardRef;
