import React, { ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme as defaultTheme } from './theme.config';
import { Theme } from './theme.types';
import 'focus-visible/dist/focus-visible';
import { Global, css } from '@emotion/core';
import Fonts from './tokens/fonts/fonts.component';

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

export const KibbleThemeProvider = ({ children, theme }: { children: ReactNode; theme?: Theme }): JSX.Element => {
  return (
    <ChakraProvider theme={theme ?? defaultTheme}>
      <Global styles={GlobalStyles} />
      <Fonts />
      {children}
    </ChakraProvider>
  );
};
