import React from 'react';
import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { Timestamp, TimestampProps } from 'patterns/Timestamp';
import { NotificationCounter } from 'components/NotificationCounter';
import { Icon } from 'components/Icon';
import { Heading } from 'components/Heading';
import { Text } from 'components/Text';
import { ConversationCardAvatarGroup, ConversationCardAvatarGroupProps } from './conversationCardAvatarGroup.component';

export interface ConversationCardHeaderProps {
  avatarGroupProps?: ConversationCardAvatarGroupProps;
  showNotificationIndicator?: boolean;
  isCardPinned?: boolean;
  isHoveringCard?: boolean;
  subtitle?: string;
  timestampProps: TimestampProps;
  title: string;
}

export const ConversationCardHeader = ({
  avatarGroupProps,
  timestampProps,
  isHoveringCard,
  isCardPinned,
  title,
  showNotificationIndicator,
  subtitle,
}: ConversationCardHeaderProps): JSX.Element => {
  return (
    <HStack className="ConversationCard__Header" justify="space-between" align="flex-start">
      <HStack className="ConversationCard__Header--Left" cursor="pointer">
        {avatarGroupProps && (
          <ConversationCardAvatarGroup {...avatarGroupProps} tooltipLabel={avatarGroupProps?.tooltipLabel || title} />
        )}
        <VStack className="ConversationCard__HeaderText" justify="center" align="left" spacing="0">
          <Heading className="ConversationCard__HeaderText--Title" size="sm" isTruncated>
            {title}
          </Heading>
          {subtitle && (
            <Text className="ConversationCard__HeaderNamesText--Subtitle" as="p" size="sm" variant="subtle" isTruncated>
              {subtitle}
            </Text>
          )}
        </VStack>
      </HStack>
      <HStack
        className="ConversationCard__Header--Right"
        visibility={isHoveringCard ? 'hidden' : 'visible'}
        align="center"
        spacing={1}
      >
        <Timestamp {...timestampProps} />
        {showNotificationIndicator && (
          <Box
            as="span"
            className="ConversationCard__HeaderNotificationCounter"
            data-testid="conversation-card-unread-indicator"
          >
            <NotificationCounter size="xs" />
          </Box>
        )}
        {isCardPinned && (
          <Flex data-testid="conversation-card-pinned-icon">
            <Icon size="md" variant="info" name="pin" />
          </Flex>
        )}
      </HStack>
    </HStack>
  );
};
