import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgChainLink = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.844 20.862a4.982 4.982 0 0 1-1.622-1.084 5.006 5.006 0 0 1 0-7.071l2.829-2.828 1.414 1.414-2.829 2.828a3.005 3.005 0 0 0 0 4.243 3.007 3.007 0 0 0 4.243 0l2.828-2.83 1.414 1.415-2.828 2.829a4.985 4.985 0 0 1-3.536 1.462 4.983 4.983 0 0 1-1.913-.378ZM21.24 7.757a5.008 5.008 0 0 1-1.462 3.536l-2.829 2.828-1.414-1.414 2.829-2.828a3.005 3.005 0 0 0 0-4.243 3.007 3.007 0 0 0-4.243 0l-2.828 2.829-1.414-1.414 2.828-2.83a5.006 5.006 0 0 1 7.071 0 5.008 5.008 0 0 1 1.461 3.536Z"
      fill={color}
    />
    <path d="m7.049 15.536 1.415 1.414 8.486-8.485-1.414-1.415-8.487 8.486Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgChainLink);
export default ForwardRef;
