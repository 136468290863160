import React, { useRef } from 'react';
import { useMonth } from '@datepicker-react/hooks';
import { Text } from '../../../../Text/text.component';
import { Box, Center, Grid, GridItem } from '@chakra-ui/react';
import { Day } from '../Day/day.component';
import { currentMonth, currentYear, useDatePickerContext } from 'components/DatePicker/context/datepicker.context';

export interface MonthProps {
  onDaySelected: () => void;
}

export const Month = ({ onDaySelected }: MonthProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { firstDayOfWeek, activeMonth } = useDatePickerContext();

  const { days, weekdayLabels } = useMonth({
    year: activeMonth?.year || currentYear,
    month: activeMonth?.month !== undefined ? activeMonth.month : currentMonth,
    firstDayOfWeek,
  });

  return (
    <Box ref={ref}>
      <Grid templateColumns="repeat(7, 1fr)" my={2}>
        {weekdayLabels.map((weekdayLabel: string) => (
          <GridItem key={weekdayLabel}>
            <Center>
              <Text size="xs" variant="subtle">
                {weekdayLabel}
              </Text>
            </Center>
          </GridItem>
        ))}
      </Grid>
      <Grid templateColumns="repeat(7, 1fr)">
        {days.map((day, index) =>
          typeof day === 'object' ? (
            <Day key={day.date.toString()} date={day.date} dayLabel={day.dayLabel} onDaySelected={onDaySelected} />
          ) : (
            <div key={index} />
          ),
        )}
      </Grid>
    </Box>
  );
};
