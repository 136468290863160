import { Props } from 'framer-motion/types/types';
import { LogoColor } from './logo.types';
import {
  DefaultLogo,
  DefaultLogoIcon,
  DefaultOnContrastLogo,
  DefaultOnContrastLogoIcon,
  PrimaryLogo,
  PrimaryLogoIcon,
  PrimaryOnContrastLogo,
  PrimaryOnContrastLogoIcon,
} from './logos';

export const logoIconMap: Record<LogoColor, React.FC<Props>> = {
  default: DefaultLogoIcon,
  defaultOnContrast: DefaultOnContrastLogoIcon,
  primary: PrimaryLogoIcon,
  primaryOnContrast: PrimaryOnContrastLogoIcon,
};

export const fullLogoMap: Record<LogoColor, React.FC<Props>> = {
  default: DefaultLogo,
  defaultOnContrast: DefaultOnContrastLogo,
  primary: PrimaryLogo,
  primaryOnContrast: PrimaryOnContrastLogo,
};
