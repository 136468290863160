import { Flex } from '@chakra-ui/react';
import { Icon, IconProps } from 'components/Icon';
import { Text, TextProps } from 'components/Text';
import React, { ReactNode } from 'react';

export interface ConversationCardFooterProps {
  iconProps?: IconProps;
  content: string | ReactNode;
  contentProps?: Omit<TextProps, 'children'>;
}

export const ConversationCardFooter = ({
  iconProps,
  content,
  contentProps,
}: ConversationCardFooterProps): JSX.Element => {
  const _content =
    typeof content === 'string' ? (
      <Text
        className="ConversationCard__Footer--Message"
        as="p"
        size="sm"
        variant="subtle"
        {...contentProps}
        {...(iconProps && { ml: 1 })}
      >
        {content}
      </Text>
    ) : (
      content
    );
  return (
    <Flex className="ConversationCard__Footer" mt={3} alignItems="center">
      {iconProps && <Icon size="sm" variant="subtle" {...iconProps} />}
      {_content}
    </Flex>
  );
};
