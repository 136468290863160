import React, { ReactNode } from 'react';
import { Text, TextProps } from '../../Text/text.component';

export interface AlertDescriptionProps {
  description?: string | ReactNode;
  descriptionProps?: TextProps;
  isToast: boolean;
}

export const AlertDescription = (props: AlertDescriptionProps): JSX.Element => {
  const { description, descriptionProps, isToast } = props;
  return typeof description === 'string' ? (
    <Text className="Alert__Description" size="md" variant={isToast ? 'onContrast' : 'default'} {...descriptionProps}>
      {description}
    </Text>
  ) : (
    <>{description}</>
  );
};
