import React, { useCallback, KeyboardEvent, useEffect, ChangeEvent } from 'react';
import { MenuList as CMenuList, MenuListProps as CMenuListProps, Box, Flex } from '@chakra-ui/react';
import { Heading } from '../../../Heading/heading.component';
import { Text } from '../../../Text/text.component';
import { MenuItem, MenuItemProps } from '../MenuItem/menuItem.component';
import { MenuSearchBar } from '../MenuSearchBar/menuSearchBar.component';
import { MenuSelectAllButtons } from '../MenuSelectAllButtons/menuSelectAllButtons.component';
import { useMenuContext } from '../../context/menu.context';
import { Spinner } from 'components/Spinner';

export type MenuListOnSelect =
  | ((selectedItem: MenuItemProps, allSelectedItems?: MenuItemProps[]) => void)
  | ((selectedItem: MenuItemProps, allSelectedItems?: MenuItemProps[]) => Promise<void>);

export type MenuListOnSelectAll =
  | ((selectedItems: MenuItemProps[]) => void)
  | ((selectedItems: MenuItemProps[]) => Promise<void>);

export interface KMenuListProps {
  canSelectAll?: boolean;
  canSelectGroups?: boolean;
  isGrouped?: boolean;
  isLoading?: boolean;
  isMatchWidthSet?: boolean;
  isMultiSelect?: boolean;
  isSearchable?: boolean;
  onSearchCallback?: (e: ChangeEvent<HTMLInputElement>) => void;
  menuItems?: MenuItemProps[];
  onSelect?: MenuListOnSelect;
  onSelectAll?: MenuListOnSelectAll;
  searchPlaceholder?: string;
  shouldRetainSelection?: boolean;
  shouldTruncateText?: boolean;
  shouldUsePortal?: boolean;
  emptyStateText?: string;
}

export type MenuListProps = KMenuListProps & Omit<CMenuListProps, 'onSelect'>;

export const MenuList = ({
  canSelectAll = false,
  canSelectGroups = false,
  children,
  isGrouped = false,
  isMatchWidthSet,
  isMultiSelect = false,
  isSearchable = false,
  onSearchCallback,
  isLoading = false,
  menuItems,
  onSelect,
  onSelectAll,
  searchPlaceholder,
  shouldRetainSelection = false,
  shouldTruncateText = true,
  minW = '240px',
  maxW,
  maxH = '480px',
  shouldUsePortal = false,
  emptyStateText = 'No results found',
  ...rest
}: MenuListProps): JSX.Element => {
  const {
    filteredMenuItems,
    setFilteredMenuItems,
    allMenuItems,
    groups,
    setGroups,
    focusedItemIndex,
    setFocusedItemIndex,
    searchInputRef,
    menuItemRefs,
    dataTestId,
  } = useMenuContext();

  const focusNextListItem = useCallback(
    (direction) => {
      if (!menuItemRefs?.current) return;

      const isFirstOption = focusedItemIndex === 0;
      const isLastOption = focusedItemIndex === menuItemRefs?.current.length - 1;
      let newIndex = -1;

      if (direction === 'ArrowDown') {
        if (isLastOption) {
          if (isSearchable) {
            searchInputRef?.current?.focus();
            setFocusedItemIndex(-1);
            return;
          }

          newIndex = 0;
        } else {
          newIndex = focusedItemIndex + 1;
        }
      } else if (direction === 'ArrowUp') {
        if (isFirstOption) {
          if (isSearchable) {
            searchInputRef?.current?.focus();
            setFocusedItemIndex(-1);
            return;
          }

          newIndex = menuItemRefs.current.length - 1;
        } else {
          newIndex = focusedItemIndex - 1;
        }
      }

      const nextListItem = menuItemRefs?.current[newIndex];

      if (nextListItem) {
        nextListItem?.focus();
        setFocusedItemIndex(newIndex);
      } else {
        setFocusedItemIndex(-1);
      }
    },
    [focusedItemIndex, isSearchable, menuItemRefs, searchInputRef, setFocusedItemIndex],
  );

  const handleKeyboardNavigation = useCallback(
    (e: KeyboardEvent<HTMLInputElement | HTMLDivElement>, elementIsInputField): void => {
      e.persist();

      if (!menuItemRefs?.current) return;

      if (e.key === 'ArrowDown' || e.key === 'Tab') {
        focusNextListItem('ArrowDown');
      }

      if (e.key === 'ArrowUp') {
        focusNextListItem('ArrowUp');
      }

      if (e.key === 'Enter') {
        e.preventDefault();
        (e.target as HTMLElement).click();
      }
    },
    [focusNextListItem, menuItemRefs],
  );

  const handleToggleSingleItem = useCallback(
    (selectedValue: MenuItemProps, itemOnSelect) => {
      if (filteredMenuItems && allMenuItems) {
        const _filteredMenuItemsCopy = [...filteredMenuItems];
        const selectedItem = _filteredMenuItemsCopy.find((item) => item.value === selectedValue.value);

        if (isMultiSelect && selectedItem) {
          selectedItem.isSelected = !selectedItem.isSelected;
        }

        setFilteredMenuItems(_filteredMenuItemsCopy);

        const allSelectedItems = allMenuItems.filter((item) => item.isSelected);

        if (onSelect && selectedItem) {
          onSelect(selectedItem, allSelectedItems);
        }

        if (itemOnSelect && selectedItem) {
          itemOnSelect(selectedItem, allSelectedItems);
        }
      }
    },
    [allMenuItems, filteredMenuItems, isMultiSelect, onSelect, setFilteredMenuItems],
  );

  const handleToggleAllItems = useCallback(
    ({ groupName, clear = false }: { groupName?: string; clear?: boolean }) => {
      if (filteredMenuItems && allMenuItems) {
        const _filteredMenuItemsCopy = [...filteredMenuItems];
        const value: string[] = [];

        _filteredMenuItemsCopy.forEach((item) => {
          if (groupName) {
            if (groupName === item.groupLabel) {
              item.isSelected = !clear;

              if (item.value && item.isSelected) {
                value.push(item.value);
              }
            }
          } else {
            item.isSelected = !clear;

            if (item.value && item.isSelected) {
              value.push(item.value);
            }
          }
        });

        setFilteredMenuItems(_filteredMenuItemsCopy);

        if (onSelectAll) {
          onSelectAll(allMenuItems.filter((item) => item.isSelected));
        }
      }
    },
    [filteredMenuItems, allMenuItems, onSelectAll, setFilteredMenuItems],
  );

  useEffect(() => {
    if (isGrouped) {
      const groupNames = [...new Set(filteredMenuItems?.map((item) => item.groupLabel))];
      if (groupNames.length) {
        setGroups(groupNames);
      }
    }
  }, [filteredMenuItems, isGrouped, setGroups]);

  const getMenuItem = useCallback(
    (item: MenuItemProps, index: number) => {
      if (item.isPlaceholder) return <React.Fragment key={item.value}></React.Fragment>;

      return (
        <MenuItem
          {...item}
          key={`${item.value}${index}`}
          canSelectAll={canSelectAll}
          handleKeyboardNavigation={handleKeyboardNavigation}
          isMultiSelect={isMultiSelect}
          itemIndex={index.toString()}
          menuItemRefs={menuItemRefs}
          onSelect={(selectedItem: MenuItemProps): void => handleToggleSingleItem(selectedItem, item.onSelect)}
          shouldRetainSelection={shouldRetainSelection}
          shouldTruncateText={shouldTruncateText}
          data-testid={`${dataTestId}-list-item`}
        />
      );
    },
    [
      canSelectAll,
      handleKeyboardNavigation,
      isMultiSelect,
      menuItemRefs,
      shouldRetainSelection,
      shouldTruncateText,
      dataTestId,
      handleToggleSingleItem,
    ],
  );

  if (!isMatchWidthSet) {
    maxW = '320px';
  }

  // Return default Chakra menu if user passes in custom child elements
  if (children) {
    return <CMenuList {...rest}>{children}</CMenuList>;
  }

  const shouldShowEmptyState =
    !filteredMenuItems?.length || (filteredMenuItems?.length === 1 && filteredMenuItems[0].isPlaceholder === true);

  return (
    <CMenuList
      className="MeuList"
      {...rest}
      maxW={maxW || 'unset'}
      maxH={maxH}
      minW={minW}
      overflow="hidden auto"
      zIndex="popover"
    >
      {(isSearchable || (canSelectAll && isMultiSelect)) && (
        <Box className="MenuList__Header" m="0 12px 8px">
          {isSearchable && (
            <MenuSearchBar
              handleKeyboardNavigation={handleKeyboardNavigation}
              menuItems={menuItems || []}
              searchInputRef={searchInputRef}
              setFilteredMenuItems={setFilteredMenuItems}
              placeholder={searchPlaceholder}
              onSearchCallback={onSearchCallback}
            />
          )}
          {canSelectAll && isMultiSelect && (
            <MenuSelectAllButtons
              handleToggleAllItems={handleToggleAllItems}
              hasSelectedItems={Boolean(filteredMenuItems?.find((item) => item.isSelected))}
              isGrouped={false}
            />
          )}
        </Box>
      )}
      <Box className="MenuList__Body" position="relative">
        {shouldShowEmptyState && !isLoading ? (
          <Box textAlign="center" display="block" p={3}>
            <Text size="sm" variant="subtle">
              {emptyStateText}
            </Text>
          </Box>
        ) : isGrouped ? (
          groups?.map((groupName, index) => {
            const menuItems = filteredMenuItems?.filter((item) => item.groupLabel === groupName);
            return (
              <Box className="MenuList__Group" key={`${groupName || 'noGroup'}-${index}`}>
                {groupName && (
                  <Flex minH={8} pt={2} pr={3} pb={1} pl={5} justify="space-between" align="center">
                    <Box textAlign="left" className="MenuList__GroupTitle">
                      <Heading size="xs">{groupName}</Heading>
                    </Box>
                    {isMultiSelect && canSelectGroups && (
                      <MenuSelectAllButtons
                        handleToggleAllItems={handleToggleAllItems}
                        hasSelectedItems={Boolean(menuItems?.find((item) => item.isSelected))}
                        isGrouped={true}
                        groupName={groupName}
                      />
                    )}
                  </Flex>
                )}
                {menuItems?.map((item: MenuItemProps, index: number) => {
                  return getMenuItem(item, index);
                })}
              </Box>
            );
          })
        ) : (
          filteredMenuItems?.map((item: MenuItemProps, index: number) => {
            return getMenuItem(item, index);
          })
        )}
        {isLoading && (
          <Box position="absolute" w="100%" h="100%" top="0" left="0" bg="background.alphaContrast.pressed">
            <Flex h="100%" align="center" justify="center">
              <Spinner />
            </Flex>
          </Box>
        )}
      </Box>
    </CMenuList>
  );
};
