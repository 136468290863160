import { extendTheme, space, transition } from '@chakra-ui/react';
import { Theme } from './theme.types';
// Foundational style overrides
import {
  blur,
  borders,
  corePalette,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  semanticColorTokens,
  shadows,
  sizes,
  zIndices,
} from './tokens';
import { RadioConfig } from 'components/Radio/radio.config';
import { TagConfig } from 'components/Tag/tag.config';
import { TabsConfig } from 'components/Tabs/tabs.config';
import { TooltipConfig, TooltipGlobalStyle } from 'components/Tooltip/tooltip.config';
import { HeadingConfig } from 'components/Heading/heading.config';
import { SelectConfig } from 'components/Select/select.config';
import { SwitchConfig } from 'components/Switch/switch.config';
import { TextConfig } from 'components/Text/text.config';
import { TextareaConfig } from 'components/Textarea/textarea.config';
import { TextInputConfig } from 'components/TextInput/textInput.config';
import { AvatarConfig } from 'components/Avatar/avatar.config';
import { AlertConfig } from 'components/Alert/alert.config';
import { MenuConfig } from 'components/Menu/menu.config';
import { PopoverConfig } from 'components/Popover/popover.config';
import { CheckboxConfig } from 'components/Checkbox/checkbox.config';
import { LinkConfig } from 'components/Link/link.config';
import { ButtonConfig } from 'components/Button/button.config';

// @ts-ignore
export const theme: Theme = extendTheme({
  styles: {
    global: {
      ...TooltipGlobalStyle,
      'html, body': {
        color: 'text.default',
      },
    },
  },
  blur: blur,
  borders: borders,
  colors: corePalette,
  radii: radii,
  shadows: shadows,
  sizes: sizes,
  fonts: fonts,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  lineHeights: lineHeights,
  space: space,
  transition: transition,
  zIndices: zIndices,
  semanticTokens: {
    colors: semanticColorTokens,
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  components: {
    Button: ButtonConfig,
    Drawer: {
      variants: {
        alwaysOpen: {
          parts: ['dialog, dialogContainer'],
          dialog: {
            pointerEvents: 'auto',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
    Divider: {
      baseStyle: {
        borderColor: 'divider.default',
      },
    },
    Heading: HeadingConfig,
    Select: SelectConfig,
    Switch: SwitchConfig,
    Text: TextConfig,
    Textarea: TextareaConfig,
    Input: TextInputConfig,
    Tooltip: TooltipConfig,
    Avatar: AvatarConfig,
    Alert: AlertConfig,
    Menu: MenuConfig,
    Popover: PopoverConfig,
    Tag: TagConfig,
    Tabs: TabsConfig,
    Radio: RadioConfig,
    Checkbox: CheckboxConfig,
    Link: LinkConfig,
  },
});
