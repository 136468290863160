import React from 'react';
import { RadioGroup as CRadioGroup, RadioGroupProps as CRadioGroupProps, Stack, SystemProps } from '@chakra-ui/react';
import { withFormControl, WithFormControlProps } from 'shared/hocs/FormControl/withFormControl';
import { FlexDirection } from 'shared/types/alignment';
import { Radio, RadioProps } from '../Radio/radio.component';
import { RadioSize } from '../Radio/radio.types';

export interface KRadioGroupProps extends WithFormControlProps {
  size?: RadioSize;
  radios: RadioProps[];
  direction?: FlexDirection;
  spacing?: SystemProps['margin'];
  isOnContrast?: boolean;
  name?: string;
  value?: string | number;
  onChange?: (value: string) => void;
}

export type RadioGroupProps = KRadioGroupProps & Omit<CRadioGroupProps, 'children'>;

const RadioGroup = ({
  radios,
  size = 'md',
  direction = 'row',
  spacing,
  isFieldInline,
  isOnContrast,
  isRequired,
  ...rest
}: RadioGroupProps): JSX.Element => {
  return (
    <CRadioGroup data-testid="radio-group-container" {...rest}>
      <Stack direction={direction} spacing={spacing} maxW={rest.maxW || 'max-content'}>
        {radios.map((radio, index) => (
          <Radio isOnContrast={isOnContrast} key={index} {...radio} size={size} isRequired={isRequired} />
        ))}
      </Stack>
    </CRadioGroup>
  );
};

RadioGroup.displayName = 'RadioGroup';

const WrappedRadioGroup = withFormControl(RadioGroup);

export { WrappedRadioGroup as RadioGroup };
