import React, { forwardRef, ForwardedRef, ReactElement, useMemo } from 'react';
import {
  Input as CInput,
  InputProps as CInputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Box,
  HStack,
} from '@chakra-ui/react';
import { withFormControl, WithFormControlProps } from 'shared/hocs/FormControl/withFormControl';
import { FormControlSize } from 'shared/types/formControl';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { closeButtonSizeMap } from './textInput.types';

export interface TextInputBaseProps extends WithFormControlProps {
  charLimit?: number;
  inputRef?: ForwardedRef<HTMLInputElement>;
  isOnContrast?: boolean;
  leftElement?: ReactElement;
  rightElement?: ReactElement;
  size?: FormControlSize;
}

export interface DefaultTextInputProps extends TextInputBaseProps {
  isClearable?: never;
  onClear?: never;
}

export interface ClearableTextInputProps extends TextInputBaseProps {
  isClearable: boolean;
  onClear: (val: string | number | readonly string[]) => void;
}

export type KTextInputProps = DefaultTextInputProps | ClearableTextInputProps;

export type TextInputProps = KTextInputProps & CInputProps;

const TextInput = withFormControl(
  ({
    isClearable = false,
    size = 'md',
    leftElement,
    rightElement,
    inputRef,
    isFieldInline,
    onClear,
    value,
    isOnContrast,
    charLimit = undefined,
    ...rest
  }: TextInputProps): JSX.Element => {
    let _computedVariant = isOnContrast ? 'onContrast' : 'default';

    const handleClear = (): void => {
      if (onClear) {
        onClear(value || '');
      }
    };

    if (isClearable && !!value) {
      rightElement = (
        <Button size={closeButtonSizeMap[size]} variant="ghostNeutral" onClick={handleClear} iconName="close" />
      );
    }

    const currentCharCount = useMemo(() => value?.toString()?.length || 0, [value]);

    return (
      <Box className="TextInput__Container" pos="relative" w="100%">
        <InputGroup size={size}>
          {leftElement && <InputLeftElement className="TextInput__LeftElement" children={leftElement} h="100%" />}
          <CInput
            className="TextInput__Input"
            data-testid="text-input-container"
            variant={_computedVariant}
            {...rest}
            ref={inputRef}
            value={value}
          />
          {rightElement && <InputRightElement className="TextInput__RightElement" children={rightElement} />}
        </InputGroup>
        {!!charLimit && (
          <HStack
            className="TextInput__CharCount"
            spacing={1}
            bg="background.default"
            pos="absolute"
            right="0"
            bottom="-20px"
          >
            <Text size="xs" variant={currentCharCount > charLimit ? 'danger' : 'subtle'}>
              {currentCharCount}
            </Text>
            <Text size="xs" variant="subtle">
              / {charLimit}
            </Text>
          </HStack>
        )}
      </Box>
    );
  },
);

const WrappedTextInput = forwardRef((props: TextInputProps, ref: ForwardedRef<HTMLInputElement>) => (
  <TextInput inputRef={ref} {...props} />
));

WrappedTextInput.displayName = 'TextInput';

export { WrappedTextInput as TextInput };
