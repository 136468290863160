import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgEyeOpen = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.885 9.884A3.02 3.02 0 0 1 12 9c1.641 0 3 1.359 3 3 0 1.642-1.359 3-3 3a3.02 3.02 0 0 1-3-3 3.02 3.02 0 0 1 .885-2.116Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.052 11.684C2.073 11.617 4.367 5 12 5c7.633 0 9.927 6.617 9.95 6.684l.104.316-.106.316C21.928 12.383 19.633 19 12 19c-7.633 0-9.927-6.617-9.949-6.684L1.946 12l.106-.316ZM4.074 12c.502 1.154 2.575 5 7.926 5 5.348 0 7.422-3.842 7.926-5-.502-1.154-2.575-5-7.926-5-5.348 0-7.422 3.842-7.926 5Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEyeOpen);
export default ForwardRef;
