import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgWrench = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.128 21c.378.378.88.586 1.414.586.534 0 1.036-.208 1.414-.586l4.336-4.336a7.494 7.494 0 0 0 2.217.333 7.446 7.446 0 0 0 5.302-2.195 7.484 7.484 0 0 0 1.632-8.158l-.57-1.388-4.244 4.243-2.12-2.122 4.242-4.243-1.389-.571A7.478 7.478 0 0 0 14.505 2c-2.003 0-3.886.78-5.3 2.196a7.479 7.479 0 0 0-1.863 7.518L3.006 16.05a2.001 2.001 0 0 0 0 2.828L5.128 21Zm4.548-8.791-.254-.616a5.486 5.486 0 0 1 1.196-5.983 5.46 5.46 0 0 1 4.413-1.585l-3.353 3.353 4.95 4.95 3.354-3.355a5.49 5.49 0 0 1-1.587 4.416c-1.55 1.55-3.964 2.027-5.984 1.196l-.615-.255-5.254 5.256h.001v1-1L4.42 17.464l5.256-5.255Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWrench);
export default ForwardRef;
