import React from 'react';
import { Tabs, TabsProps } from '../Tabs/tabs.component';

export interface TabsLayoutProps extends TabsProps {
  tabPanels: JSX.Element[];
}

export const TabsLayout = (props: TabsLayoutProps): JSX.Element => {
  return <Tabs {...props} />;
};
