import React, { ReactNode } from 'react';
import { Heading, HeadingProps } from 'components/Heading';

export interface AlertTitleProps {
  title?: string | ReactNode;
  titleProps?: HeadingProps;
  isToast?: boolean;
}

export const AlertTitle = (props: AlertTitleProps): JSX.Element => {
  const { title, titleProps, isToast } = props;
  return (
    <Heading
      className="Alert__Title"
      flexGrow={1}
      variant={isToast ? 'onContrast' : 'default'}
      lineHeight={6}
      size="sm"
      {...titleProps}
    >
      {title}
    </Heading>
  );
};
