import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCellphoneCancel = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 2H7a2.002 2.002 0 0 0-1.993 1.829L3.47 2.29 2.057 3.703 19.886 21.53l-.002.003.002.002L21.3 20.12l-2.3-2.3V4c0-1.103-.897-2-2-2ZM7 5.821l10.002 10.002L17 5H7v.821Z"
      fill={color}
    />
    <path d="M17.47 21.944 12.525 17H7v-5.525l-2-2V20c0 1.103.897 2 2 2h10c.162 0 .32-.02.47-.056Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgCellphoneCancel);
export default ForwardRef;
