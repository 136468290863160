import { Box } from '@chakra-ui/react';
import { Picture } from 'components/Picture';
import React from 'react';

interface AvatarImageProps {
  src: string;
  webpSrc: string;
}

const AvatarImage = ({ src, webpSrc }: AvatarImageProps): JSX.Element => {
  return (
    <Box className="Avatar__Image">
      <Picture
        src={src}
        webpSrc={webpSrc}
        imgProps={{
          w: '100%',
          h: '100%',
          objectFit: 'cover',
          borderRadius: 'full',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </Box>
  );
};

export default AvatarImage;
