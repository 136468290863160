import { Flex } from '@chakra-ui/react';
import { Avatar } from 'components/Avatar';
import { Tooltip } from 'components/Tooltip';
import React, { ReactNode } from 'react';

export interface ConversationCardAvatarGroupProps {
  avatars: ReactNode[];
  max?: number;
  onAvatarGroupClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  tooltipLabel?: string;
}

export const ConversationCardAvatarGroup = ({
  avatars,
  max = 1,
  onAvatarGroupClick,
  tooltipLabel,
}: ConversationCardAvatarGroupProps): JSX.Element => {
  return (
    <Tooltip label={tooltipLabel || ''} isDisabled={!tooltipLabel?.length}>
      <Flex className="ConversationCardAvatarGroup__Container" align="flex-start" justify="flex-start">
        {max && avatars.length > max ? (
          <Avatar
            onClick={(e): void => {
              e.stopPropagation();
              if (onAvatarGroupClick) {
                onAvatarGroupClick(e);
              }
            }}
            cursor="pointer"
            name={avatars.length.toString()}
            showTooltip={false}
          />
        ) : (
          <>{avatars.map((avatar) => avatar)}</>
        )}
      </Flex>
    </Tooltip>
  );
};
