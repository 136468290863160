import React from 'react';
import { Heading as CHeading, HeadingProps as CHeadingProps } from '@chakra-ui/react';
import { HeadingSize } from './heading.types';
import { SemanticTypographyToken } from 'theme/tokens';
import { TypographyVariant } from 'shared/types/typography';
import { withTruncation, WithTruncationProps } from 'shared/hocs/withTruncation';

export interface KHeadingProps extends WithTruncationProps {
  color?: SemanticTypographyToken | string;
  size?: HeadingSize;
  variant?: TypographyVariant;
}

export type HeadingProps = KHeadingProps & Omit<CHeadingProps, 'variant'>;

const Heading = ({ children, size = 'lg', variant = 'default', color, ...rest }: HeadingProps): JSX.Element => {
  return (
    <CHeading variant={variant} size={size} {...(color && { color })} {...rest}>
      {children}
    </CHeading>
  );
};

Heading.displayName = 'Heading';

const WrappedHeading = withTruncation(Heading);

export { WrappedHeading as Heading };
