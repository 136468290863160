import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMessages = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8 2h12c1.103 0 2 .897 2 2v8c0 1.103-.897 2-2 2V6c0-1.103-.897-2-2-2H6c0-1.103.897-2 2-2Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 21.766V18H4c-1.103 0-2-.897-2-2V8c0-1.103.897-2 2-2h12c1.103 0 2 .897 2 2v8c0 1.103-.897 2-2 2h-4.723L5 21.766ZM16 8H4v8h3v2.234L10.723 16H16V8Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMessages);
export default ForwardRef;
