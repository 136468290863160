import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgSliders = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13 5h9v2h-9V5ZM2 7h7v2h2V3H9v2H2v2Zm7 10h13v2H9v-2Zm10-6h3v2h-3v-2Zm-2 4V9.012h-2V11H2v2h13v2h2ZM7 21v-6H5v2H2v2h3v2h2Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSliders);
export default ForwardRef;
