import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  ChangeEvent,
} from 'react';
import { TextInput } from '../../../TextInput/textInput.component';
import { MenuItemProps } from '../MenuItem/menuItem.component';
import { useMenuState } from '@chakra-ui/react';
import { useMenuContext } from 'components/Menu/context/menu.context';

export interface MenuSearchBarProps {
  handleKeyboardNavigation: (e: KeyboardEvent<HTMLInputElement>, elementIsInputField: boolean) => void;
  menuItems: MenuItemProps[];
  onSearchCallback?: (e: ChangeEvent<HTMLInputElement>) => void;
  searchInputRef?: MutableRefObject<HTMLInputElement | null>;
  setFilteredMenuItems: Dispatch<SetStateAction<MenuItemProps[]>>;
  placeholder?: string;
}

export const MenuSearchBar = ({
  handleKeyboardNavigation,
  menuItems,
  onSearchCallback,
  searchInputRef,
  setFilteredMenuItems,
  placeholder = 'Search...',
}: MenuSearchBarProps): JSX.Element => {
  const { isOpen } = useMenuState();
  const { searchText, setSearchText } = useMenuContext();

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const searchInput = e.target.value;
      setSearchText(searchInput);

      if (onSearchCallback) {
        onSearchCallback(e);
      } else {
        if (searchInput === '' || !searchInput) {
          setFilteredMenuItems(menuItems);
        } else {
          const filterdItems = menuItems.filter((item) => {
            const labelText = typeof item.label === 'string' ? item.label : (item.label?.key as string);

            return labelText?.toLowerCase().includes(searchInput.toLowerCase());
          });
          setFilteredMenuItems(filterdItems);
        }
      }
    },
    [menuItems, onSearchCallback, setFilteredMenuItems, setSearchText],
  );

  useEffect(() => {
    let focusTimeout: NodeJS.Timeout;

    if (isOpen) {
      focusTimeout = setTimeout(() => {
        searchInputRef?.current?.focus();
      }, 200);
    }

    return (): void => clearTimeout(focusTimeout);
  }, [searchInputRef, isOpen]);

  return (
    <TextInput
      className="MenuList__SearchBar"
      placeholder={placeholder}
      onChange={handleSearch}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>): void => handleKeyboardNavigation(e, true)}
      size="sm"
      ref={searchInputRef}
      value={searchText}
      isClearable
      onClear={(): void => {
        setSearchText('');
        handleSearch({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
      }}
      formControlStyle={{ mt: 1 }}
    />
  );
};
