import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgHeartSolid = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20.205 5.254A5.938 5.938 0 0 0 15.996 3.5c-1.48 0-2.906.556-3.996 1.558a5.904 5.904 0 0 0-6.278-1.1 5.942 5.942 0 0 0-1.931 1.3c-2.353 2.363-2.352 6.059.002 8.412L12 21.877l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHeartSolid);
export default ForwardRef;
