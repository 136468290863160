import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgWarningSign = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.003 10h-2v5h2v-5ZM13.002 16h-2v2h2v-2Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.002 3.138c.742 0 1.42.407 1.768 1.062l7.339 13.864a1.99 1.99 0 0 1-.054 1.968A1.985 1.985 0 0 1 19.34 21H4.663a1.984 1.984 0 0 1-1.713-.968 1.986 1.986 0 0 1-.054-1.968l7.338-13.863a1.997 1.997 0 0 1 1.768-1.063Zm0 1.999L4.662 19h14.684L12.002 5.137Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWarningSign);
export default ForwardRef;
