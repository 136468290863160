import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgWebsite = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.001 6h-2v2h2V6ZM19.001 6h-2v2h2V6Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.001 3h16c1.103 0 2 .897 2 2v14c0 1.103-.897 2-2 2h-16c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2Zm16.001 6-.001-4h-16v4h16.001ZM4.001 11v8h16.002l-.001-8H4.001Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWebsite);
export default ForwardRef;
