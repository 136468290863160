import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgPenSquare = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.413 16.998 7 17.013v-4.43l9.634-9.542c.75-.749 2.069-.753 2.825.003l1.586 1.586c.378.378.586.88.586 1.414 0 .534-.208 1.036-.586 1.414l-9.632 9.54Zm8.221-10.957-1.589-1.583-1.594 1.58 1.586 1.585 1.597-1.582ZM15.03 7.444 9 13.417v1.589l1.587-.005 6.029-5.971-1.586-1.586Z"
      fill={color}
    />
    <path
      d="M19 21H5c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2h8.847l-2 2H5v14h2.979c.016 0 .032.003.048.005.017.003.035.005.052.005.013 0 .026-.003.04-.005.013-.002.026-.005.039-.005H19v-6.668l2-2V19c0 1.103-.897 2-2 2Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPenSquare);
export default ForwardRef;
