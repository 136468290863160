import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextConfig } from '../Text/text.config';

const BaseLinkConfig: ComponentStyleConfig = {
  baseStyle: {
    textDecoration: 'underline',
  },
};

export const LinkConfig = { ...BaseLinkConfig, ...TextConfig };
