import { Box, BoxProps, Image, ImageProps } from '@chakra-ui/react';
import React from 'react';

export interface PictureProps extends BoxProps {
  webpSrc: string;
  src: string;
  imgProps?: ImageProps;
}

export const Picture = ({ src, webpSrc, imgProps, ...rest }: PictureProps): JSX.Element => {
  return (
    <Box as="picture" {...rest}>
      <Box as="source" srcSet={webpSrc} type="image/webp" />
      <Image src={src} {...imgProps} />
    </Box>
  );
};
