import * as React from 'react';
import { Text, Menu, Button, MenuButtonProps, MenuListProps, Tag, Spinner, MenuContainerProps } from '../../components';
import { Box, HStack, Flex } from 'chakra';

export enum TagDisabledState {
  Inactive = 'Inactive',
  Deleted = 'Deleted',
}

export interface TagBlockTag {
  label: string;
  value: string;
}

export interface TagBlockProps {
  title?: string;
  menuButtonProps?: MenuButtonProps;
  menuListProps?: MenuListProps;
  menuContainerProps?: MenuContainerProps;
  tags: TagBlockTag[];
  canBeDeleted?: boolean;
  onClearAll?: () => void;
  onRemoveTag?: (tag: string, selectedTags: TagBlockTag[]) => void;
  handleDelete?: () => void;
  hasError?: boolean;
  errorText?: string;
  isLoading?: boolean;
  dataTestId?: string;
}

export const TagBlock = ({
  title,
  menuButtonProps = {
    size: 'sm',
    variant: 'secondarySubtle',
    leftIconName: 'plus',
    text: 'Add',
    showRightIcon: false,
  },
  menuListProps = {
    isMultiSelect: true,
  },
  menuContainerProps,
  tags,
  canBeDeleted = false,
  onClearAll,
  onRemoveTag,
  handleDelete,
  hasError = false,
  errorText = 'This field contains an error',
  isLoading = false,
  dataTestId = 'tag-block',
}: TagBlockProps): JSX.Element => {
  const handleRemoveTag = (value: string): void => {
    if (onRemoveTag) {
      onRemoveTag(value, tags);
    }
  };

  return (
    <Box
      className="TagBlock"
      position="relative"
      mb={3}
      py={2}
      pl={4}
      pr={2}
      bg="background.subtle"
      borderRadius="lg"
      boxShadow={hasError ? `0 0 0 2px var(--chakra-colors-border-danger)` : ''}
      border="1px"
      borderColor="border.default"
    >
      <Flex
        className="TagBlock__ButtonContainer"
        data-testid={`${dataTestId}-container`}
        justifyContent="space-between"
      >
        <HStack spacing={1} minH={10}>
          {title && (
            <Text className="TagBlock__Title" mr={2} fontWeight="bold">
              {title}
            </Text>
          )}
          <Menu
            buttonProps={menuButtonProps}
            listProps={menuListProps}
            containerProps={menuContainerProps}
            dataTestId={`${dataTestId}-menu`}
          />
          <Button
            className="TagBlock__Button--ClearAll"
            data-testid={`${dataTestId}-clear-all-button`}
            size="sm"
            iconName="arrowCircle"
            variant="ghostNeutral"
            onClick={onClearAll}
            isDisabled={tags.length <= 0}
          >
            Clear All
          </Button>
        </HStack>
        {canBeDeleted && (
          <Box>
            <Button
              className="TagBlock__Button--Delete"
              data-testid={`${dataTestId}-delete-button`}
              iconName="trashcan"
              variant="ghostNeutral"
              onClick={handleDelete}
              justifySelf="flex-end"
            />
          </Box>
        )}
      </Flex>
      {Boolean(tags.length) && (
        <Flex
          className="TagBlock__TagContainer"
          mt={2}
          pb={1}
          alignContent="space-between"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {tags.map((tag) => {
            const isDisabled =
              tag.label.includes(TagDisabledState.Inactive) || tag.label.includes(TagDisabledState.Deleted);

            return (
              <Tag
                className="TagBlock__Tag"
                data-testid={`${dataTestId}-tag`}
                mr={1}
                mb={1}
                key={tag.value}
                label={tag.label}
                onClose={(): void => handleRemoveTag(tag.value)}
                closeButtonDisplay="always"
                fontWeight="normal"
                color={isDisabled ? 'text.placeholder' : 'text.default'}
                size="md"
              />
            );
          })}
        </Flex>
      )}
      {isLoading && <Spinner isFullScreen />}
      {hasError && (
        <Text className="TagBlock__Error" data-testid={`${dataTestId}-error`} mt={2} size="xs" as="p" variant="danger">
          {errorText}
        </Text>
      )}
    </Box>
  );
};
