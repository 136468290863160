import React from 'react';
import { MenuList, KMenuListProps, MenuListProps } from '../../../Menu/components/MenuList/menuList.component';
import { SelectOptionProps } from '../SelectOption/selectOption.types';

export interface KSelectListProps
  extends Omit<
    KMenuListProps,
    | 'canSelectAll'
    | 'canSelectGroups'
    | 'isMultiSelect'
    | 'onSelectAll'
    | 'menuItems'
    | 'shouldRetainSelection'
    | 'isMatchWidthSet'
    | 'isLoading'
  > {
  options?: SelectOptionProps[];
}

export type SelectListProps = KSelectListProps & MenuListProps;

export const SelectList = (props: SelectListProps): JSX.Element => {
  const { options, ...rest } = props;

  return <MenuList shouldRetainSelection={true} menuItems={options} isMatchWidthSet={true} {...rest} />;
};
