import React, { ReactNode } from 'react';
import { VStack } from '@chakra-ui/react';

export interface AlertBodyProps {
  children: ReactNode;
  hideIcon: boolean;
  hideCloseButton: boolean;
}

export const AlertBody = (props: AlertBodyProps): JSX.Element => {
  const { children, hideIcon, hideCloseButton } = props;
  return (
    <VStack
      className="Alert__Body"
      justify="flex-start"
      align="flex-start"
      w="100%"
      pl={!hideIcon ? '34px' : ''}
      pr={!hideCloseButton ? '34px' : ''}
    >
      {children}
    </VStack>
  );
};
