import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgAnimalCat = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.5 14c.828 0 1.5-.895 1.5-2s-.672-2-1.5-2-1.5.895-1.5 2 .672 2 1.5 2ZM16.5 14c.828 0 1.5-.895 1.5-2s-.672-2-1.5-2-1.5.895-1.5 2 .672 2 1.5 2ZM10 13.6c0 .44.268 1.2 1 1.812v.352l-.12.239a1.147 1.147 0 0 1-2.016.065l-1.728 1.007C8.196 18.893 10.6 19.11 12 17.8c1.401 1.31 3.803 1.094 4.864-.724l-1.728-1.007c-.46.787-1.609.75-2.017-.065L13 15.764v-.352c.732-.612 1-1.373 1-1.812 0-.6-.895-.6-2-.6s-2 0-2 .6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 13c0 5.145 4.484 10.5 12 10.5S24 18.145 24 13v-.414l-.293-.293c-.504-.504-1.094-1.095-1.62-1.739-.425-.518-.772-1.029-.993-1.508a23.84 23.84 0 0 0 1.368-3.57c.195-.688.346-1.395.366-1.996.01-.298-.01-.635-.114-.951-.106-.327-.332-.713-.777-.929l-.118-.057-.128-.025c-1.506-.292-2.96.034-4.182.514-.997.392-1.893.91-2.596 1.343-.837-.306-1.875-.459-2.913-.459-1.038 0-2.076.153-2.913.46-.703-.435-1.6-.952-2.596-1.344-1.222-.48-2.675-.806-4.182-.514l-.128.025-.118.057c-.445.216-.67.602-.777.929a2.798 2.798 0 0 0-.114.95c.02.602.17 1.31.366 1.998a23.836 23.836 0 0 0 1.368 3.57c-.221.478-.568.989-.992 1.507-.527.643-1.117 1.235-1.621 1.739L0 12.586V13Zm12-8.104c-.944 0-1.888.166-2.553.498l-.504.252-.477-.3c-.75-.474-1.68-1.048-2.707-1.452-.669-.263-1.345-.44-2.01-.478.972.65 1.7 2.086 2.24 3.15.19.374.356.702.502.934l-.02.021c-.48.487-.77.783-1.24 1.641-.586 1.068-1.297 2.365-3.22 4.24C2.24 17.268 5.735 21.5 12 21.5c6.266 0 9.76-4.232 9.988-8.098-1.922-1.875-2.633-3.172-3.219-4.24-.47-.858-.76-1.154-1.24-1.64l-.02-.022c.146-.232.312-.56.502-.935.54-1.063 1.268-2.498 2.24-3.15-.665.039-1.341.216-2.01.479-1.028.404-1.956.978-2.707 1.452l-.477.3-.504-.252c-.665-.332-1.609-.498-2.553-.498Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAnimalCat);
export default ForwardRef;
