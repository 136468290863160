import React, { ReactNode } from 'react';
import { Checkbox as CCheckbox, CheckboxProps as CCheckboxProps } from '@chakra-ui/react';
import { withFormControl, WithFormControlProps } from 'shared/hocs/FormControl/withFormControl';

export interface KibbleCheckboxProps extends WithFormControlProps {
  value?: string | number;
  isChecked?: boolean;
  defaultChecked?: boolean;
  isIndeterminate?: boolean;
  children?: string | ReactNode;
  isOnContrast?: boolean;
}

export type CheckboxProps = KibbleCheckboxProps & Exclude<CCheckboxProps, 'variant'>;

const Checkbox = ({ children, size = 'md', isFieldInline, isOnContrast, ...rest }: CheckboxProps): JSX.Element => {
  const _computedVariant = isOnContrast ? 'onContrast' : 'default';
  return (
    <CCheckbox
      variant={_computedVariant}
      size={size}
      {...rest}
      align="center"
      sx={{ '.chakra-checkbox__label': { mt: 0 } }}
    >
      {children}
    </CCheckbox>
  );
};

Checkbox.displayName = 'Checkbox';

const WrappedCheckbox = withFormControl(Checkbox);

export { WrappedCheckbox as Checkbox };
