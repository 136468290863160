import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCreditCardLogoMastercard = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="#3F51B5" d="M45 35a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V13a4 4 0 0 1 4-4h34a4 4 0 0 1 4 4v22z" />
    <path fill="#FFC107" d="M30 14a10 10 0 1 0 0 20 10 10 0 1 0 0-20Z" />
    <path
      fill="#FF3D00"
      d="M22.014 30a9.983 9.983 0 0 1-1.176-2h5.325a9.9 9.9 0 0 0 .637-2h-6.598A9.976 9.976 0 0 1 20 24h7c0-.686-.07-1.354-.201-2h-6.598a9.964 9.964 0 0 1 .637-2h5.325a9.936 9.936 0 0 0-1.176-2h-2.973c.437-.58.93-1.122 1.481-1.595A9.955 9.955 0 0 0 17 14c-5.523 0-10 4.477-10 10s4.477 10 10 10c3.269 0 6.162-1.575 7.986-4h-2.972z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCreditCardLogoMastercard);
export default ForwardRef;
