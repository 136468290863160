import { BoxProps, Box } from '@chakra-ui/react';
import React from 'react';
import { logoIconMap, fullLogoMap } from './logo.config';
import { LogoType, LogoColor } from './logo.types';

export interface LogoProps extends BoxProps {
  type?: LogoType;
  color?: LogoColor;
  handleClick?: () => void;
}

export const Logo = ({ type = 'full', color = 'default', handleClick, ...rest }: LogoProps): JSX.Element => {
  let LogoComponent;

  if (type === 'icon') {
    LogoComponent = logoIconMap[color];
  } else {
    LogoComponent = fullLogoMap[color];
  }

  return (
    <Box {...rest} {...(handleClick && { cursor: 'pointer', onClick: handleClick })}>
      <LogoComponent />
    </Box>
  );
};

Logo.displayName = 'Logo';
