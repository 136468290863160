import { Box, HStack } from '@chakra-ui/react';
import { Text, TextProps } from 'components/Text';
import React, { ReactNode } from 'react';

export interface ConversationCardContentProps {
  content?: string | ReactNode;
  contentProps?: Omit<TextProps, 'children'>;
  contentMenu?: ReactNode;
}

export const ConversationCardContent = ({
  contentMenu,
  content,
  contentProps,
}: ConversationCardContentProps): JSX.Element => {
  const _content =
    typeof content === 'string' ? (
      <Text className="ConversationCard__ConversationDetails--Content" isTruncated size="sm" {...contentProps}>
        {content}
      </Text>
    ) : (
      content
    );

  return (
    <HStack className="ConversationCard__ConversationDetails" mt={3} justify="space-between" align="center">
      {_content}
      {contentMenu && (
        <Box className="ConversationCard__ConversationDetails--ContentMenu" ml="auto">
          {contentMenu}
        </Box>
      )}
    </HStack>
  );
};
