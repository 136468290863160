import React, { useMemo, ReactNode, useRef, useState, useCallback, useEffect } from 'react';
import { Select as CSelect, SelectProps as CSelectProps, MenuButton } from '@chakra-ui/react';
import { MenuContainer, MenuContainerProps } from '../Menu/components/MenuContainer/menuContainer.component';
import { WithFormControlProps, withFormControl } from 'shared/hocs/FormControl/withFormControl';
import { MenuProvider } from '../Menu/context/menu.context';
import { SelectList, SelectListProps } from './components/SelectList/selectList.component';
import { SelectContainer } from './components/SelectContainer/selectContainer.component';
import { Icon } from '../Icon/icon.component';
import { IconSize } from '../Icon/icon.types';
import { FormControlSize } from 'shared/types/formControl';
import { SelectOptionProps } from './components/SelectOption/selectOption.types';

export interface KSelectProps extends WithFormControlProps {
  defaultLeftElement?: ReactNode;
  listProps?: SelectListProps;
  containerProps?: MenuContainerProps;
  options: SelectOptionProps[];
  size?: FormControlSize;
  isOnContrast?: boolean;
  placeholder?: string;
  emptyStateText?: string;
}

export type SelectProps = KSelectProps & Omit<CSelectProps, 'onSelect'>;

const Select = ({
  defaultLeftElement,
  isFieldInline,
  listProps,
  options,
  placeholder,
  size = 'md',
  value,
  maxW = '100%',
  isOnContrast,
  emptyStateText,
  containerProps,
  ...rest
}: SelectProps): JSX.Element => {
  // TODO: Need to add full support for isOnContrast, currently menu list still renders white
  const _computedVariant = isOnContrast ? 'onContrast' : 'default';
  const selectRef = useRef<HTMLSelectElement>(null);
  const placeholderOption: SelectOptionProps = useMemo(
    () => ({
      label: placeholder,
      value: '',
      variant: isOnContrast ? 'placeholderOnContrast' : 'placeholder',
      leftElement: null,
      isSelected: false,
      isPlaceholder: true,
    }),
    [placeholder, isOnContrast],
  );

  const [allOptions, setAllOptions] = useState<SelectOptionProps[]>([]);

  const selectedOption: SelectOptionProps = useMemo(() => {
    const selectedItem = allOptions.find((option) => option.isSelected);

    return selectedItem ? selectedItem : placeholderOption;
  }, [allOptions, placeholderOption]);

  const handleSelect = useCallback(
    (selectedItem: SelectOptionProps) => {
      if (!allOptions) return;

      const updatedOptions = allOptions.map((option) => {
        return {
          ...option,
          isSelected: option.value === selectedItem.value,
        };
      });
      setAllOptions(updatedOptions);

      if (listProps?.onSelect) {
        listProps.onSelect(selectedItem);
      }
    },
    [allOptions, listProps],
  );

  // If the text is not being truncated it should grow to fit the content
  const flexibleHeightProps = !listProps?.shouldTruncateText ? { height: 'max-content', minH: 10 } : {};

  const selectWidth = useMemo(() => {
    if (!selectRef.current) {
      return '320px';
    }

    return `${selectRef?.current?.clientWidth}px`;

    // Disables the warning for not including unused dependency in the hook array. This dependency is needed to rerender the menu list with the correct width
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef?.current]);

  // Set default selected state
  useEffect(() => {
    const mappedOptions = options.map((option) => ({
      ...option,
      isSelected: option.value === value,
    }));

    setAllOptions([...mappedOptions, placeholderOption]);
  }, [options, placeholderOption, value]);

  return (
    <MenuProvider menuItems={allOptions}>
      <MenuContainer matchWidth={true} isLazy={true} {...containerProps}>
        <CSelect
          icon={<Icon name="chevronDown" size={size as IconSize} />}
          as={MenuButton}
          ref={selectRef}
          onClick={(event: React.MouseEvent): void => event.stopPropagation()}
          type="button"
          className="Select__MenuButton"
          variant={_computedVariant}
          maxW={maxW}
          {...flexibleHeightProps}
          {...rest}
        >
          <SelectContainer
            selectedOption={selectedOption}
            defaultLeftElement={defaultLeftElement}
            shouldTruncateText={listProps?.shouldTruncateText}
          />
        </CSelect>
        <SelectList
          {...listProps}
          onSelect={handleSelect}
          options={allOptions}
          maxW={selectWidth || maxW}
          emptyStateText={emptyStateText}
        />
      </MenuContainer>
    </MenuProvider>
  );
};

Select.displayName = 'Select';

const WrappedSelect = withFormControl(Select);

export { WrappedSelect as Select };
