import React, { forwardRef, ForwardedRef, MouseEventHandler } from 'react';
import { As, HStack, Link as CLink, LinkProps as CLinkProps } from '@chakra-ui/react';
import { LinkIcon, LinkIconProps } from './components/linkIcon.component';
import { TextSize, Text } from 'components/Text';
import { useIconTextSizeMap } from 'shared/hooks/useIconTextSizeMap';
import { TypographyVariant } from 'shared/types/typography';

export interface KLinkProps {
  children?: string;
  iconProps?: LinkIconProps;
  size?: TextSize;
  variant?: TypographyVariant;
  to?: any;
  href?: string;
  isExternal?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  as?: As;
}

export type LinkProps = KLinkProps & Omit<CLinkProps, 'variant'>;

export const Link = forwardRef((props: LinkProps, ref: ForwardedRef<HTMLAnchorElement>): JSX.Element => {
  const { children, iconProps, size = 'md', color, variant = 'interactive', ...rest } = props;
  const mappIconSize = useIconTextSizeMap(size);

  if (iconProps && !iconProps.position) {
    iconProps.position = 'start';
  }

  return (
    <CLink variant={variant} display="inline-block" size={size} ref={ref} {...rest}>
      <HStack spacing={1} align="center">
        {iconProps?.position === 'start' && (
          <LinkIcon variant={iconProps.variant || variant} size={mappIconSize} {...iconProps} />
        )}
        <Text size={size} variant={variant}>
          {children}
        </Text>
        {iconProps?.position === 'end' && (
          <LinkIcon variant={iconProps.variant || variant} size={mappIconSize} {...iconProps} />
        )}
      </HStack>
    </CLink>
  );
});

Link.displayName = 'Link';
