import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgPhoneButtons = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 3h4v3h-4V3Zm0 5h4v3h-4V8Zm0 5h4v3h-4v-3Zm6-10h4v3h-4V3Zm0 5h4v3h-4V8Zm0 5h4v3h-4v-3ZM4 3h4v3H4V3Zm0 5h4v3H4V8Zm0 5h4v3H4v-3Zm6 5h4v3h-4v-3Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPhoneButtons);
export default ForwardRef;
