import React, { ReactNode, useMemo } from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  HStack,
  FormLabelProps,
  VStack,
  Spacer,
} from '@chakra-ui/react';
import { useTheme } from 'shared/hooks/useTheme';
import { FormControlSize } from 'shared/types/formControl';
import { Icon } from 'components/Icon';
import { TypographyVariant } from 'shared/types/typography';
import { Text } from 'components/Text';

export interface WithFormControlProps {
  errorText?: string;
  formControlStyle?: FormControlProps;
  helperText?: string;
  invalidIconColor?: TypographyVariant;
  isDisabled?: boolean;
  isFieldInline?: boolean;
  isInvalid?: boolean;
  isOnContrast?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  showRequiredState?: boolean;
  label?: string | ReactNode;
  labelStyle?: FormLabelProps;
  labelPosition?: 'start' | 'end';
  size?: FormControlSize;
  useInvalidBorder?: boolean;
  useInvalidIcon?: boolean;
  useInvalidText?: boolean;
}

export function withFormControl<P extends WithFormControlProps>(Component: React.ComponentType<P>): React.FC<P> {
  function WithFormControl({
    errorText = 'Field is invalid',
    formControlStyle,
    helperText,
    invalidIconColor = 'warning',
    isDisabled,
    isFieldInline = false,
    isInvalid,
    isOnContrast,
    isReadOnly,
    isRequired,
    showRequiredState,
    label,
    labelPosition = 'start',
    labelStyle,
    size = 'md',
    useInvalidBorder = true,
    useInvalidIcon = false,
    useInvalidText = true,
    ...props
  }: WithFormControlProps): JSX.Element {
    const { fontSizes } = useTheme();

    const OptionalSizeMap: Record<FormControlSize, number> = {
      lg: 14,
      md: 12,
      sm: 10,
    };

    // Default fields set to isRequired to show required state in label
    const showRequired = useMemo(() => {
      if (showRequiredState === undefined) {
        return isRequired;
      }
      return showRequiredState && isRequired;
    }, [isRequired, showRequiredState]);

    // Default fields NOT set to isRequired to NOT show required state in label
    const showOptional = useMemo(() => {
      if (showRequiredState === undefined) {
        return false;
      }
      return showRequiredState && !isRequired;
    }, [isRequired, showRequiredState]);

    return (
      <FormControl
        isInvalid={isInvalid}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        {...(isFieldInline && {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, auto)',
          gridTemplateRows: 'repeat(3, auto)',
          alignItems: 'center',
          w: 'max-content',
        })}
        {...formControlStyle}
      >
        {label && labelPosition === 'start' && (
          <FormLabel
            color={isOnContrast ? 'text.onContrast' : 'text.default'}
            fontSize={fontSizes[size]}
            _disabled={{ opacity: 1 }}
            {...(isFieldInline && { mb: 0, ml: 0, mr: 2 })}
            {...labelStyle}
          >
            {label}
            {showRequired && <Text color="text.danger">{' *'}</Text>}
            {showOptional && (
              <Text fontSize={OptionalSizeMap[size]} fontWeight="normal" color="text.subtle">
                {' (Optional)'}
              </Text>
            )}
          </FormLabel>
        )}
        <HStack w={isFieldInline ? 'max-content' : '100%'} align="center">
          <Component
            size={size}
            isFieldInline={isFieldInline}
            isOnContrast={isOnContrast}
            isRequired={isRequired}
            isDisabled={isDisabled}
            {...(isRequired && { 'aria-required': true })}
            {...(props as P)}
          />
          {useInvalidIcon && isInvalid && <Icon name="warningSign" variant={invalidIconColor} size={size} />}
        </HStack>
        {label && labelPosition === 'end' && (
          <FormLabel
            color={isOnContrast ? 'text.onContrast' : 'text.default'}
            fontSize={fontSizes[size]}
            {...(isFieldInline && { mb: 0, ml: 2, mr: 0 })}
            {...labelStyle}
          >
            {label}
            {showRequired && <Text color="text.danger">{' *'}</Text>}
            {showOptional && (
              <Text fontSize={OptionalSizeMap[size]} fontWeight="normal" color="text.subtle">
                {' (Optional)'}
              </Text>
            )}
          </FormLabel>
        )}
        {labelPosition === 'start' && <Spacer />}
        <VStack align="flex-start">
          {useInvalidText && isInvalid && (
            <FormErrorMessage mt={2} fontSize="xs" color={isOnContrast ? 'text.dangerOnContrast' : 'text.danger'}>
              {errorText}
            </FormErrorMessage>
          )}
          {helperText && (
            <FormHelperText mt={2} color={isOnContrast ? 'text.subtleOnContrast' : 'text.subtle'} fontSize="xs">
              {helperText}
            </FormHelperText>
          )}
        </VStack>
      </FormControl>
    );
  }
  WithFormControl.displayName = `withFormControl(${Component.displayName || Component.name}`;

  return WithFormControl;
}
