import { Menu } from 'components/Menu/menu.component';
import { MenuItemProps } from 'components/Menu/components/MenuItem/menuItem.component';
import range from 'lodash/range';
import { Button } from 'components/Button/button.component';
import { monthNames } from 'components/DatePicker/datepicker.records';
import { set } from 'date-fns';
import React, { useMemo } from 'react';
import { PopoverHeader, HStack, Center } from '@chakra-ui/react';
import { today, useDatePickerContext } from 'components/DatePicker/context/datepicker.context';

interface DatePickerHeaderProps {
  scrollToYear: () => void;
}

export function DatePickerHeader({ scrollToYear }: DatePickerHeaderProps): JSX.Element {
  const { focusedDate, activeMonth, minYear, maxYear, goToDate, goToNextMonths, goToPreviousMonths } =
    useDatePickerContext();

  const monthMenuItems = useMemo(
    () =>
      monthNames.map((month, index) => ({
        label: month,
        value: String(index),
        isSelected: index === activeMonth?.month,
      })),
    [activeMonth?.month],
  );

  const yearMenuItems = useMemo(
    () =>
      range(minYear, maxYear + 1).map((year) => {
        const stringYear = String(year);

        return {
          label: stringYear,
          value: stringYear,
          isSelected: year === activeMonth?.year,
        };
      }),
    [maxYear, minYear, activeMonth?.year],
  );

  const handleMonthChange = (selectedItem: MenuItemProps): void =>
    goToDate(set(focusedDate || today, { month: Number(selectedItem.value), year: activeMonth?.year }));

  const handleYearChange = (selectedItem: MenuItemProps): void =>
    goToDate(set(focusedDate || today, { month: activeMonth?.month, year: Number(selectedItem.value) }));

  return (
    <PopoverHeader justifyItems="space-between">
      <HStack w="100%" justify="space-between">
        <Button iconName="chevronLeft" onClick={goToPreviousMonths} variant="ghostNeutral" size="sm" />
        <Center gap={2} h="100%">
          <Menu
            buttonProps={{
              variant: 'tertiary',
              text: monthNames[activeMonth?.month || 0],
              size: 'sm',
            }}
            listProps={{
              menuItems: monthMenuItems,
              onSelect: handleMonthChange,
              maxH: 400,
              shouldRetainSelection: true,
            }}
          />
          <Menu
            buttonProps={{
              variant: 'tertiary',
              text: String(activeMonth?.year),
              size: 'sm',
            }}
            listProps={{
              menuItems: yearMenuItems,
              onSelect: handleYearChange,
              maxH: 200,
              shouldRetainSelection: true,
            }}
            onOpen={scrollToYear}
          />
        </Center>
        <Button iconName="chevronRight" onClick={goToNextMonths} variant="ghostNeutral" size="sm" />
      </HStack>
    </PopoverHeader>
  );
}
