import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMicrophoneMute = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.316 16.905 3.388 3.388-1.414 1.414-18-18 1.414-1.414 4.293 4.293v-.565a4.005 4.005 0 0 1 3.812-3.996.926.926 0 0 1 .209-.025c2.194 0 3.979 1.804 3.979 4.021v6c0 .734-.213 1.413-.561 2.004l1.452 1.452a5.95 5.95 0 0 0 1.109-3.456h2c0 1.77-.592 3.489-1.681 4.884ZM11.997 4.021c-1.103 0-2 .897-2 2v2.565l3.929 3.929a1.92 1.92 0 0 0 .071-.494v-6c0-1.065-.812-1.941-1.836-2.015a.919.919 0 0 1-.164.015Z"
      fill={color}
    />
    <path
      d="M3.997 12.021h2c0 3.308 2.691 6 6 6a5.978 5.978 0 0 0 1.66-.239l1.558 1.558a7.99 7.99 0 0 1-2.218.611v2.07h-2v-2.069c-3.94-.495-7-3.859-7-7.931Z"
      fill={color}
    />
    <path d="M9.176 14.841a3.993 3.993 0 0 1-1.168-2.709l3.877 3.877a3.993 3.993 0 0 1-2.709-1.168Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgMicrophoneMute);
export default ForwardRef;
