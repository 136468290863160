import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Card } from 'components/Card';
import { MenuProps } from 'components/Menu';
import { ConversationCardHeader, ConversationCardHeaderProps } from './components/conversationCardHeader.component';
import { ConversationCardButtons, ConversationCardPinProps } from './components/conversationCardButtons.component';
import { ConversationCardTag, ConversationCardTags } from './components/conversationCardTags.component';
import { ConversationCardFooter, ConversationCardFooterProps } from './components/conversationCardFooter.component';
import { ConversationCardContent, ConversationCardContentProps } from './components/conversationCardContent';
import { ConversationCardAvatarGroupProps } from './components/conversationCardAvatarGroup.component';

export interface ConversationCardProps extends ConversationCardContentProps {
  allowHoverActions?: boolean;
  avatarGroupProps?: ConversationCardAvatarGroupProps;
  footerProps?: ConversationCardFooterProps;
  headerMenuProps?: MenuProps;
  headerProps: ConversationCardHeaderProps;
  isActive?: boolean;
  onClick?: () => void;
  pinProps?: ConversationCardPinProps;
  shouldPreservePosition?: boolean;
  showNotificationIndicator?: boolean;
  tags?: ConversationCardTag[];
}

export const ConversationCard = ({
  allowHoverActions = true,
  avatarGroupProps,
  content,
  contentProps,
  contentMenu,
  footerProps,
  headerMenuProps,
  headerProps,
  isActive,
  onClick,
  pinProps,
  shouldPreservePosition,
  showNotificationIndicator,
  tags,
}: ConversationCardProps): JSX.Element => {
  const { isPinned } = pinProps || {};
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Card
      className="ConversationCard"
      id="conversation-card-item"
      data-testid="conversation-card-item"
      isInteractive
      isActive={isActive}
      size="sm"
      onClick={(): void => {
        if (onClick) {
          onClick();
        }
      }}
      bg={
        showNotificationIndicator || isActive || isHovering ? 'background.default' : 'background.alphaContrast.pressed'
      }
      onMouseEnter={(): void => {
        if (allowHoverActions) {
          setIsHovering(true);
        }
      }}
      onMouseLeave={(): void => setIsHovering(false)}
      pos="relative"
      sx={{ overflowAnchor: shouldPreservePosition ? 'auto' : 'none' }}
    >
      {isHovering && (
        <Box
          className="ConversationCard__Menu--Container"
          pos="absolute"
          top={2}
          zIndex="banner"
          right={2}
          bg="background.default"
          borderRadius="md"
          onClick={(e): void => e.stopPropagation()}
        >
          <ConversationCardButtons
            pinProps={pinProps}
            headerMenuProps={headerMenuProps}
            isHovering={isHovering}
            setIsHovering={setIsHovering}
          />
        </Box>
      )}
      <ConversationCardHeader
        avatarGroupProps={avatarGroupProps}
        isHoveringCard={isHovering}
        isCardPinned={isPinned}
        showNotificationIndicator={showNotificationIndicator}
        {...headerProps}
      />
      <ConversationCardContent content={content} contentProps={contentProps} contentMenu={contentMenu} />
      {!!tags?.length && <ConversationCardTags tags={tags} />}
      {footerProps && <ConversationCardFooter {...footerProps} />}
    </Card>
  );
};
