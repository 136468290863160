import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgAnimalBird = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15 7c.552 0 1-.672 1-1.5S15.552 4 15 4s-1 .672-1 1.5.448 1.5 1 1.5Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.825 10.998c.385-.01.74-.058 1.07-.144a3.544 3.544 0 0 0 1.203-.559c.21.06.429.117.66.175l.8.2.348-.747c.972-2.083 1.044-4.046.429-5.686-.611-1.629-1.866-2.845-3.373-3.502-3.052-1.33-7.103-.37-9.343 3.79l-.787 1.467c-1.156 2.158-2.268 4.234-3.392 6.12-1.379 2.311-2.736 4.27-4.147 5.68l-.863.863 1.015.677c1.565 1.043 3.624 1.618 5.74 1.255.707.375 1.397.657 2.066.852-.586.173-1.157.396-1.698.667l.894 1.788c2.436-1.218 5.372-1.146 6.998-.062l1.11-1.664c-.682-.454-1.474-.772-2.327-.96 1.15-.518 2.086-1.352 2.78-2.344 1.537-2.201 1.965-5.339.817-7.866Zm.338-8.43c-2.073-.902-5.023-.362-6.783 2.906l-.776 1.447c-1.157 2.16-2.297 4.286-3.446 6.215-1.134 1.902-2.303 3.655-3.548 5.096 1.107.46 2.383.614 3.647.298 1.644-.411 3.058-1.785 3.773-3.326.747-1.609.543-2.865-.155-3.423l1.25-1.562c1.802 1.442 1.598 3.936.72 5.827-.615 1.324-1.653 2.61-2.989 3.486.958.273 1.8.301 2.52.17 1.26-.227 2.277-.96 2.992-1.983 1.476-2.114 1.519-5.216-.116-7.055l-.488-.549 4.241-7.067a4.513 4.513 0 0 0-.842-.48Zm2.193 2.116-1.72 2.865c.462.29 1.01.525 1.77.753.453-1.33.393-2.465.056-3.363a4.037 4.037 0 0 0-.106-.255Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAnimalBird);
export default ForwardRef;
