import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMicrophone = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6.001v6c0 2.206-1.794 4-4 4s-4-1.794-4-4v-6a4.006 4.006 0 0 1 3.812-3.996.933.933 0 0 1 .209-.025C14.215 1.98 16 3.784 16 6.001Zm-6 0v6c0 1.103.897 2 2 2s2-.897 2-2v-6c0-1.065-.812-1.94-1.837-2.015a.891.891 0 0 1-.163.015c-1.103 0-2 .897-2 2Z"
      fill={color}
    />
    <path
      d="M4 12.001h2c0 3.309 2.691 6 6 6s6-2.691 6-6h2c0 4.073-3.061 7.436-7 7.931v2.069h-2v-2.069c-3.939-.495-7-3.859-7-7.931Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMicrophone);
export default ForwardRef;
