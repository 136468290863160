import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgAnimalHorse = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#animal-horse_svg__a)" fill={color}>
      <path d="M15.5.5h-1c-1.068 0-2.274.13-3.371.757a4.922 4.922 0 0 0-.384.243H10.5c-3.014 0-5.402 1.105-7.096 2.87C1.724 6.119.776 8.462.506 10.89c-.216 1.948.009 3.83.196 5.393.043.353.083.69.116 1.007.193 1.871.097 2.88-.525 3.503l1.288 1.52c1.256-.896 1.63-2.463 1.802-3.905.09-.747.135-1.558.179-2.363v-.019c.046-.823.092-1.652.182-2.48.181-1.662.535-3.223 1.341-4.528.669-1.083 1.683-2.044 3.298-2.728.594-.217 1.231-.35 1.89-.444l.176-.53c.437-1.312 1.05-1.967 1.672-2.323.401-.229.856-.363 1.363-.432-.036.903-.146 1.735-.472 2.924l-.272.988.995.247c1.097.273 1.81.706 2.355 1.25.564.562.996 1.29 1.461 2.248.165.34.332.705.51 1.092.797 1.742 1.797 3.928 3.736 6.253.045.104.093.296.095.605a5.05 5.05 0 0 1-.167 1.219c-.192.768-.51 1.485-.814 1.944-1.085.226-2.532.03-3.607-1.425l-.071-.097-.093-.077c-.854-.712-1.877-1.159-2.808-1.517a59.457 59.457 0 0 0-.786-.295c-.657-.243-1.247-.46-1.795-.739-.726-.37-1.227-.778-1.523-1.323-.294-.54-.464-1.357-.242-2.694l-1.972-.328c-.272 1.628-.113 2.93.457 3.977.566 1.042 1.462 1.687 2.373 2.15.064.033.128.065.194.096.032.268.083.543.14.81.084.397.193.83.3 1.252l.052.21c.257 1.027.47 1.957.47 2.669h2c0-1.003-.287-2.18-.53-3.153l-.055-.219c-.06-.239-.117-.467-.17-.685.126.046.25.092.368.138.857.33 1.587.664 2.162 1.118 1.81 2.354 4.361 2.462 6.01 1.972l.287-.085.193-.23c.61-.724 1.126-1.883 1.4-2.985a7.02 7.02 0 0 0 .227-1.717c-.003-.546-.096-1.153-.405-1.669l-.041-.068-.052-.061c-1.792-2.13-2.681-4.066-3.484-5.816a60.882 60.882 0 0 0-.56-1.196c-.491-1.01-1.04-1.985-1.847-2.79a6.275 6.275 0 0 0-.333-.31l1.547-1.547C18.06 3.445 17 2.5 15.497 2.11c.002-.199.003-.4.003-.609v-1Z" />
      <path d="M16 11.5c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5.448-1.5 1-1.5 1 .672 1 1.5Z" />
    </g>
    <defs>
      <clipPath id="animal-horse_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgAnimalHorse);
export default ForwardRef;
