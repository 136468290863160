import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgFileHealth = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11 9h2v2.268l1.964-1.134 1 1.732L14 13l1.964 1.134-1 1.732L13 14.732V17h-2v-2.268l-1.964 1.134-1-1.732L10 13l-1.964-1.134 1-1.732L11 11.268V9Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.414 5H20c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2h5a.996.996 0 0 1 .707.293L11.414 5ZM4 7v12h16.002L20 7H4Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFileHealth);
export default ForwardRef;
