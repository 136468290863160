import React, { ReactNode, useMemo } from 'react';
import {
  Popover as CPopover,
  PopoverProps as CPopoverProps,
  PopoverTrigger as CPopoverTrigger,
  PopoverContent as CPopoverContent,
  PopoverArrow as CPopoverArrow,
  PopoverBody as CPopoverBody,
  PopoverContentProps,
} from '@chakra-ui/react';
import { PopoverPlacement, PopoverSize, PopoverVariant, PopoverTriggerType } from './popover.types';
import { PopoverHeader, PopoverHeaderProps } from './components/PopoverHeader';
import { PopoverFooter, PopoverFooterProps } from './components/PopoverFooter';
import { Text } from '../Text';

export interface KPopoverProps {
  allowEscape?: boolean;
  anchor: ReactNode;
  children: ReactNode;
  footerProps?: PopoverFooterProps;
  headerProps?: PopoverHeaderProps;
  contentProps?: PopoverContentProps;
  placement?: PopoverPlacement;
  size?: PopoverSize;
  trigger?: PopoverTriggerType;
  variant?: PopoverVariant;
}

export type PopoverProps = KPopoverProps & CPopoverProps;

export const Popover = ({
  allowEscape = true,
  anchor,
  children,
  contentProps,
  footerProps,
  headerProps,
  placement = 'auto',
  size = 'sm',
  trigger = 'click',
  variant = 'contrast',
  ...rest
}: PopoverProps): JSX.Element => {
  // Manually setting the variant styles here rather than in the config
  // because chakra doesn't style the tooltip/popover arrow properly when styles are customized
  // See GH issue for reference: https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
  const styles = useMemo(() => {
    switch (variant) {
      case 'contrast':
      case 'contrastDestructive':
        return {
          bg: 'background.contrast',
          color: 'text.onContrast',
        };
      case 'default':
      case 'defaultDestructive':
        return {
          bg: 'background.default',
          color: 'text.default',
        };
      default:
        break;
    }
  }, [variant]);

  const isOnContrast = variant === 'contrast' || variant === 'contrastDestructive';
  const isDestructive = variant === 'contrastDestructive' || variant === 'defaultDestructive';

  return (
    <CPopover
      size={size}
      closeOnBlur={allowEscape}
      closeOnEsc={allowEscape}
      placement={placement}
      trigger={trigger}
      {...rest}
    >
      <CPopoverTrigger>{anchor}</CPopoverTrigger>
      <CPopoverContent {...styles} {...contentProps} shadow="md">
        <CPopoverArrow {...styles} />
        {headerProps && <PopoverHeader isOnContrast={isOnContrast} {...styles} {...headerProps} />}
        <CPopoverBody py={3} borderRadius="inherit" {...styles}>
          {typeof children === 'string' ? (
            <Text variant={isOnContrast ? 'onContrast' : 'default'} size="sm">
              {children}
            </Text>
          ) : (
            children
          )}
        </CPopoverBody>
        {footerProps && (
          <PopoverFooter isDestructive={isDestructive} isOnContrast={isOnContrast} {...footerProps} {...styles} />
        )}
      </CPopoverContent>
    </CPopover>
  );
};
