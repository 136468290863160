import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgRobot = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 13c0 1.105-.672 2-1.5 2-.829 0-1.5-.895-1.5-2s.671-2 1.5-2c.828 0 1.5.895 1.5 2ZM15.5 15c.828 0 1.5-.895 1.5-2s-.672-2-1.5-2c-.829 0-1.5.895-1.5 2s.671 2 1.5 2ZM16.148 17.793 14.597 16.5a4.002 4.002 0 0 1-5.195 0l-1.55 1.293a5.963 5.963 0 0 0 2.941 1.543 6.043 6.043 0 0 0 2.415 0 5.974 5.974 0 0 0 2.94-1.543Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.928 12.607c-.202-.488-.635-.605-.928-.633V9c0-1.103-.897-2-2-2h-6V5.61a1.488 1.488 0 0 0 .06-2.17A1.5 1.5 0 0 0 10.5 4.5c0 .442.195.836.5 1.11V7H5c-1.103 0-2 .897-2 2v2.997l-.082.006A1 1 0 0 0 1.99 13v2a1 1 0 0 0 1 1H3v5c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5a1 1 0 0 0 1-1v-1.938a1.006 1.006 0 0 0-.072-.455ZM19 9l.001 3.996L19 13v2l.001.005.001 5.995H5V9h14Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRobot);
export default ForwardRef;
