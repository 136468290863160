import React, { ForwardedRef, forwardRef } from 'react';
import { Textarea as CTextarea, TextareaProps as CTextareaProps } from '@chakra-ui/react';
import { withFormControl, WithFormControlProps } from 'shared/hocs/FormControl/withFormControl';
import { FormControlSize } from 'shared/types/formControl';

export interface TextareaProps extends WithFormControlProps, CTextareaProps {
  textareaRef?: ForwardedRef<HTMLTextAreaElement>;
  size?: FormControlSize;
}

const Textarea = withFormControl(
  ({ isFieldInline, textareaRef, isOnContrast, ...rest }: TextareaProps): JSX.Element => {
    const _computedVariant = isOnContrast ? 'onContrast' : 'default';

    return <CTextarea variant={_computedVariant} {...rest} ref={textareaRef} />;
  },
);

const WrappedTextArea = forwardRef((props: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) => (
  <Textarea textareaRef={ref} {...props} />
));

WrappedTextArea.displayName = 'Textarea';

export { WrappedTextArea as Textarea };
