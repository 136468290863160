import { ComponentStyleConfig, cssVar } from '@chakra-ui/react';

const $trackWidth = cssVar('switch-track-width');
const $trackHeight = cssVar('switch-track-height');
const $thumbWidth = cssVar('switch-thumb-width');
const $thumbHeight = cssVar('switch-thumb-height');

const defaultStyle = {
  trackBg: 'background.alpha.pressed',
  thumbBg: 'text.subtleOnContrast',
  thumbBgChecked: 'background.primary',
};

const onContrastStyle = {
  trackBg: 'background.alphaContrast.pressed',
  thumbBg: 'text.subtle',
  thumbBgChecked: 'text.interactiveOnContrast',
};

export const SwitchConfig: ComponentStyleConfig = {
  baseStyle: {
    track: {
      p: 0,
      pos: 'relative',
      _active: {
        outline: '0px',
        boxShadow: 'none',
      },
      _focus: {
        outline: '0px',
        boxShadow: 'none',
      },
    },
    thumb: {
      h: $thumbHeight.reference,
      w: $thumbWidth.reference,
      pos: 'absolute',
      top: '50%',
      transform: 'translate(0%, -50%)',
      _checked: {
        transform: 'translate(100%, -50%)',
      },
    },
  },

  variants: {
    default: {
      track: {
        bg: defaultStyle.trackBg,
        _checked: {
          bg: defaultStyle.trackBg,
        },
      },
      thumb: {
        bg: defaultStyle.thumbBg,
        _checked: {
          bg: defaultStyle.thumbBgChecked,
        },
      },
    },
    onContrast: {
      track: {
        bg: onContrastStyle.trackBg,
        _checked: {
          bg: onContrastStyle.trackBg,
        },
      },
      thumb: {
        bg: onContrastStyle.thumbBg,
        _checked: {
          bg: onContrastStyle.thumbBgChecked,
        },
      },
    },
    indeterminate: {
      track: {
        bg: defaultStyle.trackBg,
        _checked: {
          bg: defaultStyle.trackBg,
        },
      },
      thumb: {
        bg: defaultStyle.thumbBg,
        left: '50%',
        transform: `translate(-50%, -50%)`,
        transitionDuration: 'slower',
        _checked: {
          bg: defaultStyle.thumbBg,
          left: '50%',
          transform: `translate(-50%, -50%)`,
          transitionDuration: 'slower',
        },
      },
    },
    indeterminateOnContrast: {
      track: {
        bg: onContrastStyle.trackBg,
        _checked: {
          bg: onContrastStyle.trackBg,
        },
      },
      thumb: {
        bg: onContrastStyle.thumbBg,
        left: '50%',
        transform: `translate(-50%, -50%)`,
        transitionDuration: 'slower',
        _checked: {
          bg: onContrastStyle.thumbBg,
          left: '50%',
          transform: `translate(-50%, -50%)`,
          transitionDuration: 'slower',
        },
      },
    },
  },
  sizes: {
    sm: {
      container: {
        [$thumbWidth.variable]: '12px',
        [$thumbHeight.variable]: '12px',
        [$trackWidth.variable]: '24px',
        [$trackHeight.variable]: '6px',
      },
    },
    md: {
      container: {
        [$thumbWidth.variable]: '16px',
        [$thumbHeight.variable]: '16px',
        [$trackWidth.variable]: '32px',
        [$trackHeight.variable]: '8px',
      },
    },
    lg: {
      container: {
        [$thumbWidth.variable]: '20px',
        [$thumbHeight.variable]: '20px',
        [$trackWidth.variable]: '40px',
        [$trackHeight.variable]: '10px',
      },
    },
  },
};
