import { LogicalPlacementMap, ToastPosition, ToastPositionWithLogical } from './toast.types';

export function getToastPlacement(
  position: ToastPositionWithLogical | undefined,
  dir: 'ltr' | 'rtl',
): ToastPosition | undefined {
  if (!position) return;
  const logicals: LogicalPlacementMap = {
    'top-start': { ltr: 'top-left', rtl: 'top-right' },
    'top-end': { ltr: 'top-right', rtl: 'top-left' },
    'bottom-start': { ltr: 'bottom-left', rtl: 'bottom-right' },
    'bottom-end': { ltr: 'bottom-right', rtl: 'bottom-left' },
  };

  const logical = logicals[position as keyof typeof logicals];
  return logical?.[dir] ?? position;
}
