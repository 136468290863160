import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMoveDown = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19 20v2H5v-2h14ZM11 2v11.586L5.707 8.293 4.293 9.707 12 17.414l7.707-7.707-1.414-1.414L13 13.586V2h-2Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMoveDown);
export default ForwardRef;
