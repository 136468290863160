export const popoverSizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
export type PopoverSize = (typeof popoverSizes)[number];

export const popoverVariants = ['default', 'defaultDestructive', 'contrastDestructive', 'contrast'] as const;
export type PopoverVariant = (typeof popoverVariants)[number];

export const popoverTriggerTypes = ['click', 'hover'] as const;
export type PopoverTriggerType = (typeof popoverTriggerTypes)[number];

export const popoverPlacements = [
  'auto-end',
  'auto-start',
  'auto',
  'bottom-end',
  'bottom-start',
  'bottom',
  'end-end',
  'end-start',
  'end',
  'left-end',
  'left-start',
  'left',
  'right-end',
  'right-start',
  'right',
  'start-end',
  'start-start',
  'start',
  'top-end',
  'top-start',
  'top',
] as const;
export type PopoverPlacement = (typeof popoverPlacements)[number];
