import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgTrashcan = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11 10H9v8h2v-8ZM15 10h-2v8h2v-8Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.586 21.414A2 2 0 0 1 5 20V8H3V6h4V4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4v2h-2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-1.414-.586ZM15.001 4H9v2h6V4Zm2 4H7v12h10V8Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTrashcan);
export default ForwardRef;
