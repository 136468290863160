import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgDotsVertical = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDotsVertical);
export default ForwardRef;
