import { useState } from "react";

interface UseExpandButtonOutput {
  isExpanded: boolean;
  handleExpandClick: () => void;
}

export const useExpandButton = (): UseExpandButtonOutput => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const handleExpandClick = (): void => {
    setIsExpanded(currentValue => !currentValue);
  };
  
  return { isExpanded, handleExpandClick };
}
