import { TypographyVariantConfig } from 'shared/types/typography';

export const typographyVariantConfig: TypographyVariantConfig = {
  default: {
    color: 'text.default',
  },
  subtle: {
    color: 'text.subtle',
  },
  info: {
    color: 'text.info',
  },
  interactive: {
    color: 'text.interactive',
    _hover: {
      color: 'text.interactive.hover',
    },
  },
  success: {
    color: 'text.success',
  },
  warning: {
    color: 'text.warning',
  },
  danger: {
    color: 'text.danger',
  },
  placeholder: {
    color: 'text.placeholder',
  },
  accent: {
    color: 'text.accent',
  },
  accentSubtle: {
    color: 'text.accentSubtle',
  },
  onContrast: {
    color: 'text.onContrast',
  },
  subtleOnContrast: {
    color: 'text.subtleOnContrast',
  },
  interactiveOnContrast: {
    color: 'text.interactiveOnContrast',
    _hover: {
      color: 'text.interactiveOnContrast.hover',
    },
  },
  placeholderOnContrast: {
    color: 'text.placeholderOnContrast',
  },
  dangerOnContrast: {
    color: 'text.dangerOnContrast',
  },
};
