import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMoveUp = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19 2v2H5V2h14Zm.707 12.293L12 6.586l-7.707 7.707 1.414 1.414L11 10.414V22h2V10.414l5.293 5.293 1.414-1.414Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMoveUp);
export default ForwardRef;
