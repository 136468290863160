import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgBellCancel = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.997 22a2.98 2.98 0 0 0 2.818-2H9.179a2.98 2.98 0 0 0 2.818 2Zm9-4v-2a.998.998 0 0 0-.293-.707l-1.707-1.707V10c0-3.217-2.185-5.927-5.145-6.742A1.99 1.99 0 0 0 11.997 2a1.99 1.99 0 0 0-1.855 1.258c-1.323.364-2.463 1.128-3.346 2.127L3.704 2.293 2.29 3.707l18 18 1.414-1.414-1.362-1.362a.993.993 0 0 0 .655-.931Zm-9-13c2.757 0 5 2.243 5 5v4c0 .266.105.52.293.707l1.707 1.707V17h-.586L8.204 6.793C9.117 5.705 10.468 5 11.997 5Zm-5.293 9.707A.996.996 0 0 0 6.997 14v-2.879L5.065 9.189c-.031.268-.068.535-.068.811v3.586L3.29 15.293a.996.996 0 0 0-.293.707v2a1 1 0 0 0 1 1h10.879l-2-2H4.997v-.586l1.707-1.707Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBellCancel);
export default ForwardRef;
