import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgClinic = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.707 2.293a1 1 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a.998.998 0 0 0 .707-1.707l-9-9ZM18.001 20H6v-9.586l6-6 6 6L18.001 20Z"
      fill={color}
    />
    <path d="M13 10h-2v3H8v2h3v3h2v-3h3v-2h-3v-3Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgClinic);
export default ForwardRef;
