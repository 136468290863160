import React from 'react';
import {
  CheckboxGroup as CCheckboxGroup,
  CheckboxGroupProps as CCheckboxGroupProps,
  Stack,
  SystemProps,
} from '@chakra-ui/react';
import { withFormControl, WithFormControlProps } from 'shared/hocs/FormControl/withFormControl';
import { FlexDirection } from 'shared/types/alignment';
import { Checkbox, CheckboxProps } from '../Checkbox/checkbox.component';
import { FormControlSize } from 'shared/types/formControl';

export interface KCheckboxGroupProps extends WithFormControlProps {
  size?: FormControlSize;
  checkboxes: CheckboxProps[];
  direction?: FlexDirection;
  spacing?: SystemProps['margin'];
  isFieldInline?: boolean;
  defaultValue?: (string | number)[];
  onChange?: (value: (string | number)[]) => void;
}

export type CheckboxGroupProps = KCheckboxGroupProps & CCheckboxGroupProps;

const CheckboxGroup = ({
  checkboxes,
  size = 'md',
  direction = 'row',
  spacing,
  isFieldInline,
  isOnContrast,
  ...rest
}: CheckboxGroupProps): JSX.Element => {
  return (
    <CCheckboxGroup {...rest}>
      <Stack data-testid="multiple-selection-container" direction={direction} spacing={spacing} width="fit-content">
        {checkboxes.map((checkbox, index) => (
          <Checkbox isOnContrast={isOnContrast} key={index} {...checkbox} size={size} />
        ))}
      </Stack>
    </CCheckboxGroup>
  );
};

CheckboxGroup.displayName = 'CheckboxGroup';

const WrappedCheckboxGroup = withFormControl(CheckboxGroup);

export { WrappedCheckboxGroup as CheckboxGroup };
