import * as React from 'react';
import { ColorMode, ThemeProvider, useChakra } from '@chakra-ui/system';
import defaultTheme from '@chakra-ui/theme';
import { noop } from '@chakra-ui/utils';
import { useLatestRef } from '@chakra-ui/hooks';
import { CloseAllToastsOptions, RenderProps, toast, ToastOptions, ToastPositionWithLogical } from '@chakra-ui/react';
import { ToastId } from '@chakra-ui/toast/dist/declarations/src/toast.types';
import { getToastPlacement } from './toast.utils';
import { Alert, AlertProps } from '../Alert/alert.component';
import { WithoutLogicalPosition } from './toast.types';
import { useTheme } from 'shared/hooks/useTheme';

interface Toaster {
  (options?: UseToastOptions | undefined): string | number | undefined;
  close: (id: React.ReactText) => void;
  closeAll: (options?: CloseAllToastsOptions | undefined) => void;
  update(id: ToastId, options: Omit<UseToastOptions, 'id'>): void;
  isActive: (id: React.ReactText) => boolean | undefined;
}

export interface UseToastOptions extends Omit<AlertProps, 'id' | 'position'> {
  /**
   * The placement of the toast
   *
   * @default "top"
   */
  position?: ToastPositionWithLogical;
  /**
   * The delay before the toast hides (in milliseconds)
   * If set to `null`, toast will never dismiss.
   *
   * @default 5000 ( = 5000ms )
   */
  duration?: ToastOptions['duration'];
  /**
   * The `id` of the toast.
   *
   * Mostly used when you need to prevent duplicate.
   * By default, we generate a unique `id` for each toast
   */
  id?: ToastId;
  /**
   * Callback function to run side effects after the toast has closed.
   */
  onCloseComplete?: () => void;
  /**
   * Optional style overrides for the container wrapping the toast component.
   */
  containerStyle?: React.CSSProperties;
}

type UseToastOptionsNormalized = WithoutLogicalPosition<UseToastOptions>;

const Toast: React.FC<UseToastOptions> = (props) => {
  const { position, duration, id, onCloseComplete, containerStyle, ...rest } = props;

  return <Alert id={id as string | undefined} isToast {...rest} />;
};

const defaults: UseToastOptions = {
  title: '',
  duration: 5000,
  position: 'top',
};

type CreateStandAloneToastParam = Partial<
  ReturnType<typeof useChakra> & {
    setColorMode: (value: ColorMode) => void;
    defaultOptions: UseToastOptions;
  }
>;

const defaultStandaloneParam: Required<CreateStandAloneToastParam> = {
  theme: defaultTheme,
  colorMode: 'dark',
  toggleColorMode: noop,
  setColorMode: noop,
  defaultOptions: defaults,
};
/**
 * Create a toast from outside of React Components
 */
const createStandaloneToast = ({
  theme = defaultStandaloneParam.theme,
  defaultOptions = defaultStandaloneParam.defaultOptions,
}: CreateStandAloneToastParam = defaultStandaloneParam): Toaster => {
  const renderWithProviders = (
    props: React.PropsWithChildren<RenderProps>,
    options: UseToastOptionsNormalized,
  ): JSX.Element => (
    <ThemeProvider theme={theme}>
      <Toast {...props} {...options} />
    </ThemeProvider>
  );

  const toastImpl = (options?: UseToastOptions): ToastId | undefined => {
    const opts = { ...defaultOptions, ...options } as UseToastOptionsNormalized;
    opts.position = getToastPlacement(opts.position, theme.direction);

    const Message: React.FC<RenderProps> = (props) => renderWithProviders(props, opts);

    return toast.notify(Message, opts);
  };

  toastImpl.close = toast.close;
  toastImpl.closeAll = toast.closeAll;

  // toasts can only be updated if they have a valid id
  toastImpl.update = (id: ToastId, options: Omit<UseToastOptions, 'id'>): void => {
    if (!id) return;

    const opts = { ...defaultOptions, ...options } as UseToastOptionsNormalized;
    opts.position = getToastPlacement(opts.position, theme.direction);

    toast.update(id, {
      ...opts,
      message: (props) => renderWithProviders(props, opts),
    });
  };

  toastImpl.isActive = toast.isActive;

  return toastImpl;
};

/**
 * React hook used to create a function that can be used
 * to show toasts in an application.
 */

export const useToast = (options?: UseToastOptions): Toaster => {
  const theme = useTheme();
  const toastOptions = useLatestRef(options);
  const defaultOptions = { ...defaults, ...toastOptions.current };
  return createStandaloneToast({ theme, defaultOptions });
};
