import React, { useContext } from 'react';
import { SortableItemContext } from '../SortableItem/sortableItem.context';
import { useDndContext } from '@dnd-kit/core';
import { Button, ButtonProps } from '../../../Button/button.component';

export interface DragHandleProps extends ButtonProps {}

export const DragHandle = ({ ...rest }: DragHandleProps): JSX.Element => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);
  const dndContext = useDndContext();

  return (
    <Button
      cursor={dndContext.activatorEvent ? 'grabbing' : 'grab'}
      iconName="grid"
      size="sm"
      variant="ghostNeutral"
      className="DragHandle"
      {...attributes}
      {...listeners}
      ref={ref}
      {...rest}
    />
  );
};
