import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { Icon, IconProps } from '../../Icon/icon.component';
import { IconName } from 'components/Icon';
import { Status } from 'shared/types/status';
import { TypographyVariant } from 'shared/types/typography';

export const AlertStatusIcon: Record<Status, IconName> = {
  error: 'warningSign',
  warning: 'warningSign',
  success: 'checkmark',
  info: 'infoCircle',
  default: 'infoCircle',
};

export interface AlertIconProps {
  iconProps?: IconProps;
  status: Status;
  isToast: boolean;
}

export const AlertIcon = (props: AlertIconProps): JSX.Element => {
  const { iconProps, status, isToast = false } = props;

  const computedVariant: TypographyVariant = useMemo(() => (status === 'error' ? 'danger' : status), [status]);

  return (
    <Box className="Alert__Icon" maxH="24px">
      <Icon
        variant={isToast ? 'onContrast' : computedVariant}
        size="lg"
        name={iconProps?.name || AlertStatusIcon[status]}
        {...iconProps}
      />
    </Box>
  );
};
