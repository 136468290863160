import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMoveLeft = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2 5h2v14H2V5Zm12.293-.707L6.586 12l7.707 7.707 1.414-1.414L10.414 13H22v-2H10.414l5.293-5.293-1.414-1.414Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMoveLeft);
export default ForwardRef;
