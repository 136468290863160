import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgHashtag = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.018 4.815 15.232 9h-4.966l.716-3.815-1.964-.37L8.232 9H4v2h3.857l-.751 4H3v2h3.731l-.714 3.805 1.965.369L8.766 17h4.966l-.714 3.805 1.965.369.783-4.174H20v-2h-3.859l.751-4H21V9h-3.733l.716-3.815-1.965-.37ZM14.106 15H9.141l.751-4h4.966l-.752 4Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHashtag);
export default ForwardRef;
