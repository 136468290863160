import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgLogo = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19.662 8.97c-1.04 1.037-2.64 1.442-3.792-.174-.582-.83-.291-2.195.576-2.942 1.37-1.184 3.653-1.628 4.136.226.271 1.036-.298 2.272-.92 2.89ZM6.936 7.522c-1.463.006-2.144-.747-1.999-2.382.1-1.12.82-2.202 2.098-2.144 1.258-.052 1.952 1.52 1.952 2.536 0 1.056-.668 2.028-2.051 1.99ZM22.316 12.665c-.16 1.288-1.231 2.073-2.323 2.202-1.165.142-2.323-.464-2.336-2.04-.027-1.237 1.145-1.99 2.323-2.08 1.184-.09 2.501.63 2.336 1.918ZM12.813 2.024c-1.344.245-2.184 1.545-2.436 2.569-.284 1.158.133 2.35 1.767 2.736 1.006.231 2.336-.496 2.614-1.584.556-1.816-.43-3.998-1.945-3.721ZM14.659 10.245c-1.304-1.236-3.09-1.893-5.169-1.893-2.084 0-3.878.657-5.175 1.893-1.297 1.242-1.985 2.948-1.985 4.944 0 1.983.688 3.682 1.985 4.918C5.612 21.343 7.405 22 9.49 22c2.078 0 3.865-.657 5.169-1.893 1.304-1.236 1.998-2.942 1.998-4.918-.006-1.996-.694-3.702-1.998-4.944Zm-1.39 4.938c0 2.272-1.515 3.804-3.772 3.804-2.257 0-3.772-1.532-3.772-3.804 0-2.292 1.515-3.83 3.772-3.83 2.25 0 3.772 1.538 3.772 3.83Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLogo);
export default ForwardRef;
