import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgThumbsDown = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4 16h5.612l-1.123 3.367c-.202.608-.1 1.282.275 1.802s.983.831 1.624.831H12c.297 0 .578-.132.769-.36l4.7-5.64H20c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H6.693A2.01 2.01 0 0 0 4.82 4.298l-2.757 7.351A1 1 0 0 0 2 12v2c0 1.103.897 2 2 2Zm16-2h-2V5h2v9ZM4 12.181 6.693 5H16v9.638L11.532 20h-1.146l1.562-4.683A.998.998 0 0 0 11 14H4v-1.819Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgThumbsDown);
export default ForwardRef;
