import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgThumbsUp = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20 8H14.39l1.123-3.367c.201-.608.1-1.282-.276-1.802A2.009 2.009 0 0 0 13.614 2H12c-.297 0-.579.132-.77.36L6.532 8h-2.53c-1.104 0-2 .897-2 2v9c0 1.103.896 2 2 2h13.307a2.01 2.01 0 0 0 1.872-1.298l2.758-7.351c.041-.112.063-.231.063-.351v-2c0-1.103-.898-2-2-2ZM4 10h2v9H4v-9Zm16 1.819L17.308 19H8V9.362L12.47 4h1.146l-1.563 4.683A.998.998 0 0 0 13.002 10h7v1.819Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgThumbsUp);
export default ForwardRef;
