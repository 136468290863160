import { Avatar, AvatarProps } from 'components/Avatar';
import React from 'react';
import { StyledAvatarType } from './styledAvatar.types';

export interface BaseStyledAvatarProps {
  type?: StyledAvatarType;
}

export interface DefaultStyledAvatarProps extends BaseStyledAvatarProps {
  isGrouped?: false;
  index?: never;
}

export interface GroupedStyledAvatarProps extends BaseStyledAvatarProps {
  isGrouped: boolean;
  index: number;
}

export type KStyledAvatarProps = DefaultStyledAvatarProps | GroupedStyledAvatarProps;

export type StyledAvatarProps = KStyledAvatarProps & AvatarProps;

export const StyledAvatar = ({ type = 'Client', isGrouped, index, ...rest }: StyledAvatarProps): JSX.Element => {
  const { size, name, tooltipText, handleClick } = rest;
  const groupedStyledAvatarSpacing = size === 'lg' || size === 'md' ? -2 : -1;

  const testClientStyleProps: Partial<AvatarProps> = {
    variant: 'outlineYellow',
    tooltipText: tooltipText || `${name} (Test Client)`,
  };

  const smsStyleProps: Partial<AvatarProps> = {
    iconName: 'cellphone',
    tooltipText: tooltipText || `Text messages sent from ${name || 'unknown number'}`,
  };

  const automatedStyleProps: Partial<AvatarProps> = {
    iconName: 'robot',
    backgroundColor: 'background.primary',
    hideBorder: true,
    color: 'text.onContrast',
    size: 'sm',
    showTooltip: false,
  };

  const clinicStyleProps: Partial<AvatarProps> = {
    variant: 'outlineAccent',
    name: name || 'Sys',
  };

  return (
    <Avatar
      data-testid={`${type}-styled-avatar`}
      className="StyledAvatar"
      tooltipPlacement="top"
      handleClick={(e): void => {
        e.stopPropagation();
        if (handleClick) {
          handleClick(e);
        }
      }}
      {...rest}
      {...(isGrouped && { marginInlineStart: index === 0 ? 0 : groupedStyledAvatarSpacing })}
      {...(type === 'TestClient' ? testClientStyleProps : {})}
      {...(type === 'SMS' ? smsStyleProps : {})}
      {...(type === 'Automated' ? automatedStyleProps : {})}
      {...(type === 'Clinic' ? clinicStyleProps : {})}
    />
  );
};
