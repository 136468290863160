import React, { ReactNode } from 'react';
import {
  PopoverFooterProps as CPopoverFooterProps,
  PopoverFooter as CPopoverFooter,
  usePopoverContext,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { Button } from '../../../Button/button.component';

export interface KPopoverFooterProps {
  submitText?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isDestructive?: boolean;
  onSubmit?: () => void;
  hideCancelButton?: boolean;
  onCancel?: () => void;
  isOnContrast?: boolean;
  hideButtons?: boolean;
  children?: ReactNode;
}

export type PopoverFooterProps = KPopoverFooterProps & CPopoverFooterProps;

export const PopoverFooter = ({
  submitText = 'Submit',
  isLoading,
  isDisabled,
  isDestructive,
  children,
  onSubmit,
  hideCancelButton = false,
  hideButtons = false,
  onCancel,
  isOnContrast = true,
  ...rest
}: PopoverFooterProps): JSX.Element => {
  const { onClose } = usePopoverContext();

  const handleCancel = (): void => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };

  return (
    <CPopoverFooter {...rest} pb={3} pt={0} borderRadius="inherit" borderTop={0}>
      {children}
      {!hideButtons && (
        <Flex w="100%">
          {!hideCancelButton && (
            <Button variant="tertiary" isOnContrast={isOnContrast} size="sm" onClick={handleCancel}>
              Cancel
            </Button>
          )}
          <Spacer />
          <Button
            onClick={onSubmit}
            isLoading={isLoading}
            isDisabled={isDisabled}
            size="sm"
            isOnContrast={isOnContrast}
            variant={isDestructive ? 'primaryDestructive' : 'primary'}
          >
            {submitText}
          </Button>
        </Flex>
      )}
    </CPopoverFooter>
  );
};
