import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgImage = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m9 13 1 1 3-4 5 7H6l3-4Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3h14c1.103 0 2 .897 2 2v14c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2Zm0 2v14h14.002L19 5H5Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImage);
export default ForwardRef;
