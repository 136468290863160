import React, { useCallback, MouseEvent, MouseEventHandler, Dispatch, SetStateAction } from 'react';
import { Flex } from '@chakra-ui/react';
import { Icon } from 'components/Icon/icon.component';

interface AvatarRemoveButtonProps {
  handleRemoveButtonClick?: MouseEventHandler<HTMLDivElement>;
  isBeingHovered: boolean;
  setIsBeingHovered: Dispatch<SetStateAction<boolean>>;
}

const AvatarRemoveButton = ({
  handleRemoveButtonClick,
  isBeingHovered,
  setIsBeingHovered,
}: AvatarRemoveButtonProps): JSX.Element => {
  const onRemoveButtonClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (handleRemoveButtonClick) {
        handleRemoveButtonClick(e);
      }
    },
    [handleRemoveButtonClick],
  );
  return (
    <Flex
      className="Avatar__RemoveButton"
      backgroundColor="background.default"
      borderRadius="full"
      cursor="pointer"
      position="absolute"
      top="-6px"
      h={4}
      w={4}
      right="-6px"
      justify="center"
      align="center"
      onClick={onRemoveButtonClick}
      onMouseEnter={(): void => setIsBeingHovered(true)}
      zIndex="tooltip"
      onMouseLeave={(): void => setIsBeingHovered(false)}
    >
      <Icon variant="default" name="closeCircleSolid" size={isBeingHovered ? 'sm' : 'xs'} />
    </Flex>
  );
};

export default AvatarRemoveButton;
