import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgHeart = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.973 5.558a5.904 5.904 0 0 0-6.278-1.1 5.942 5.942 0 0 0-1.93 1.3c-2.354 2.363-2.353 6.059.001 8.412l7.332 7.332c.17.299.498.492.875.492a.99.99 0 0 0 .792-.409l7.415-7.415c2.354-2.354 2.354-6.049-.002-8.416A5.938 5.938 0 0 0 15.97 4c-1.48 0-2.906.556-3.996 1.558Zm6.791 1.61c1.563 1.571 1.564 4.025.002 5.588l-6.793 6.793-6.793-6.793c-1.562-1.563-1.56-4.017-.002-5.584A3.953 3.953 0 0 1 7.978 6c1.044 0 2.034.416 2.788 1.17l.5.5a1 1 0 0 0 1.414 0l.5-.5c1.512-1.509 4.074-1.505 5.584-.002Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHeart);
export default ForwardRef;
