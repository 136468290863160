import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgStopRecording = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12a9.959 9.959 0 0 1-2.282 6.36l1.933 1.933-1.414 1.414-.029-.029.029-.028L2.317 3.73l1.386-1.386L5.64 4.282A9.959 9.959 0 0 1 12 2c5.522 0 10 4.477 10 10Zm-2 0a7.966 7.966 0 0 1-1.704 4.937l-2.832-2.832A4.05 4.05 0 0 0 9.898 8.54L7.063 5.705A8 8 0 0 1 20 12Z"
      fill={color}
    />
    <path
      d="M3.132 7.374A9.957 9.957 0 0 0 2 12c0 5.523 4.478 10 10 10a9.957 9.957 0 0 0 4.626-1.132l-1.5-1.501A8 8 0 0 1 4.634 8.876L3.13 7.373Z"
      fill={color}
    />
    <path d="m11.805 16.047-3.85-3.85a4.05 4.05 0 0 0 3.85 3.85Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgStopRecording);
export default ForwardRef;
