import { ButtonProps } from '../../../../Button/button.component';

export type DayVariant = 'default' | 'selectedStart' | 'disabled' | 'inRange' | 'selectedEnd';
export type CustomizableProperty = 'color' | 'background' | 'borderColor';

export const dayStyles: Record<DayVariant, Partial<ButtonProps>> = {
  default: {
    variant: 'ghostNeutral',
  },
  selectedStart: {
    variant: 'primary',
  },
  disabled: {
    isDisabled: true,
    variant: 'ghostNeutral',
  },
  inRange: {
    variant: 'ghost',
    borderRadius: '0',
    isActive: true,
  },
  selectedEnd: {
    variant: 'ghost',
    borderWidth: '2px',
    borderColor: 'border.info',
  },
};
