import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgPinCancel = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m12 22 1-2v-2.776L10.776 15H7v-.586l1.595-1.595L7 11.224v.362L5.586 13A2.01 2.01 0 0 0 5 14.414V16a1 1 0 0 0 1 1h5v3l1 2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.36 16.933A1.001 1.001 0 0 0 19 16v-1.586c0-.526-.214-1.042-.586-1.414L17 11.586V8a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v.574L3.718 2.292 2.304 3.706 20.317 21.72l1.414-1.414-3.372-3.372ZM9.425 8H15v4c0 .266.105.52.293.707L17 14.414V15h-.574l-7-7ZM8 4h8v2H8V4Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPinCancel);
export default ForwardRef;
