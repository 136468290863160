import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgEyeSlash = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757c-.273.021-.55.038-.841.038-5.351 0-7.424-3.846-7.926-5a8.641 8.641 0 0 1 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379a.994.994 0 0 0 0 .633C2.072 12.383 4.367 19 12 19Zm0-14c-1.837 0-3.346.396-4.604.981l-3.69-3.688-1.413 1.414 18 18 1.414-1.414-3.32-3.319c2.615-1.951 3.548-4.615 3.562-4.657a.994.994 0 0 0 0-.633C21.927 11.617 19.633 5 11.999 5Zm4.972 10.558-2.28-2.28c.19-.39.308-.819.308-1.278 0-1.641-1.36-3-3-3-.46 0-.888.118-1.277.309L8.915 7.501A9.26 9.26 0 0 1 12 7c5.35 0 7.424 3.846 7.926 5-.302.692-1.166 2.342-2.954 3.558Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEyeSlash);
export default ForwardRef;
