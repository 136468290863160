import { ComponentStyleConfig } from '@chakra-ui/react';

export const PopoverConfig: ComponentStyleConfig = {
  sizes: {
    xs: {
      content: {
        width: 160,
      },
    },
    sm: {
      content: {
        width: 200,
      },
    },
    md: {
      content: {
        width: 260,
      },
    },
    lg: {
      content: {
        width: 320,
      },
    },
    xl: {
      content: {
        width: 480,
      },
    },
  },
};
