import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgSmileyFace = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.557 15.686a4.054 4.054 0 0 0 1.761-1.45l1.658 1.119a5.949 5.949 0 0 1-.734.889 5.971 5.971 0 0 1-3.034 1.634 6.043 6.043 0 0 1-2.415 0 5.963 5.963 0 0 1-2.148-.903 6.063 6.063 0 0 1-1.62-1.62l1.657-1.119a4.002 4.002 0 0 0 4.875 1.45ZM8.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16.986 10.493a1.493 1.493 0 1 1-2.985 0 1.493 1.493 0 0 1 2.986 0Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.486 6.486 2 12 2c5.515 0 10 4.486 10 10s-4.485 10-10 10C6.487 22 2 17.514 2 12Zm2 0c0 4.411 3.59 8 8 8 4.412 0 8-3.589 8-8s-3.588-8-8-8c-4.41 0-8 3.589-8 8Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSmileyFace);
export default ForwardRef;
