import React from 'react';
import { HStack } from '@chakra-ui/react';
import { Button } from 'components/Button';
import { Menu, MenuProps, MenuItemProps } from 'components/Menu';
import { Icon } from 'components/Icon';

export interface ConversationCardPinProps {
  isPinned?: boolean;
  togglePin: (isPinned: boolean) => void;
}

export interface ConversationCardButtonsProps {
  pinProps?: ConversationCardPinProps;
  headerMenuProps?: MenuProps;
}

interface ConversationCardButtonsComponentProps extends ConversationCardButtonsProps {
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
}

export const ConversationCardButtons = ({
  pinProps,
  headerMenuProps,
  isHovering,
  setIsHovering,
}: ConversationCardButtonsComponentProps): JSX.Element => {
  const { isPinned, togglePin } = pinProps || {};
  const { listProps } = headerMenuProps || {};
  const pinIconName = isPinned ? 'pinCancel' : 'pin';

  return (
    <HStack className="ConversationCard__Menu" spacing={1}>
      {pinProps && (
        <Button
          data-testid="conversation-card-pin-toggle-icon-button"
          variant={isPinned ? 'secondarySubtleDestructive' : 'secondarySubtle'}
          onClick={(): void => {
            if (togglePin) {
              togglePin(!isPinned);
            }

            if (isHovering) {
              setIsHovering(false);
            }
          }}
          iconName={pinIconName}
          size="sm"
        />
      )}
      {headerMenuProps && (
        <Menu
          buttonProps={
            headerMenuProps.buttonProps || {
              variant: 'secondarySubtle',
              size: 'sm',
              leftIconName: 'dotsVertical',
              showRightIcon: false,
            }
          }
          closeOnSelect={false}
          isLazy
          {...headerMenuProps}
          listProps={{
            ...listProps,
            menuItems: [
              ...(pinProps
                ? [
                    {
                      'data-testid': 'conversation-card-pin-toggle-dropdown-button',
                      label: `${isPinned ? 'Unpin' : 'Pin'} Conversation`,
                      value: 'PinConversation',
                      variant: isPinned ? 'danger' : 'default',
                      leftElement: <Icon size="md" variant={isPinned ? 'danger' : 'default'} name={pinIconName} />,
                      onSelect: (): void => {
                        if (togglePin) {
                          togglePin(!isPinned);
                        }
                      },
                    } as MenuItemProps,
                  ]
                : []),
              ...(listProps?.menuItems || []),
            ],
          }}
        />
      )}
    </HStack>
  );
};
