import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCreditCardLogoDiscover = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="#E1E7EA" d="M45 35c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V13c0-2.2 1.8-4 4-4h34c2.2 0 4 1.8 4 4v22z" />
    <path
      fill="#FF6D00"
      d="M45 35c0 2.2-1.8 4-4 4H16s23.6-3.8 29-15v11zM22 24c0 1.7 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3-3 1.3-3 3z"
    />
    <path d="M11.2 21h1.1v6h-1.1v-6zm6 3c0 1.7 1.3 3 3 3 .5 0 .9-.1 1.4-.3v-1.3c-.4.4-.8.6-1.4.6-1.1 0-1.9-.8-1.9-2 0-1.1.8-2 1.9-2 .5 0 .9.2 1.4.6v-1.3c-.5-.2-.9-.4-1.4-.4-1.7.1-3 1.5-3 3.1zm13.4.9L29 21h-1.2l2.5 6h.6l2.5-6h-1.2l-1.6 3.9zm3.3 2.1h3.2v-1H35v-1.6h2v-1h-2V22h2.1v-1h-3.2v6zm7.6-4.2c0-1.1-.7-1.8-2-1.8h-1.7v6h1.1v-2.4h.1l1.6 2.4H42l-1.8-2.5c.8-.2 1.3-.8 1.3-1.7zm-2.3 1h-.3V22h.3c.7 0 1.1.3 1.1.9 0 .5-.3.9-1.1.9zM7.7 21H6v6h1.6c2.5 0 3.1-2.1 3.1-3 .1-1.8-1.2-3-3-3zm-.3 5h-.3v-4h.4c1.5 0 2.1 1 2.1 2 0 .4-.1 2-2.2 2zm7.9-2.7c-.7-.3-.9-.4-.9-.7 0-.4.4-.6.8-.6.3 0 .6.1.9.5l.6-.8c-.5-.5-1-.7-1.7-.7-1 0-1.8.7-1.8 1.7 0 .8.4 1.2 1.4 1.6.6.2 1.1.4 1.1.9s-.4.8-.9.8-1-.3-1.2-.8l-.7.7c.5.8 1.1 1.1 2 1.1 1.2 0 2-.8 2-1.9 0-.9-.4-1.3-1.6-1.8z" />
  </svg>
);
const ForwardRef = forwardRef(SvgCreditCardLogoDiscover);
export default ForwardRef;
