import React, { ReactNode } from 'react';
import {
  PopoverHeader as CPopoverHeader,
  PopoverHeaderProps as CPopoverHeaderProps,
  usePopoverContext,
} from '@chakra-ui/react';
import { Heading } from '../../../Heading/heading.component';
import { HeadingSize } from '../../../Heading/heading.types';
import { Button } from 'components/Button';
import { SemanticColorToken } from 'theme/tokens';

export interface KPopoverHeaderProps {
  children?: ReactNode;
  color?: SemanticColorToken | string;
  hideCloseButton?: boolean;
  onClose?: () => void;
  isOnContrast?: boolean;
  size?: HeadingSize;
}

export type PopoverHeaderProps = KPopoverHeaderProps & CPopoverHeaderProps;

export const PopoverHeader = ({
  size = 'sm',
  children,
  color,
  hideCloseButton = false,
  isOnContrast = true,
  onClose,
  ...rest
}: PopoverHeaderProps): JSX.Element => {
  const { onClose: contextOnClose } = usePopoverContext();

  return (
    <CPopoverHeader px={3} pt={3} pb={0} borderBottom={0} borderRadius="inherit" minH={6} {...rest}>
      <Heading size={size} color={color || 'inherit'}>
        {children}
      </Heading>
      {!hideCloseButton && (
        <Button
          variant="ghostNeutral"
          isOnContrast={isOnContrast}
          onClick={onClose ? onClose : contextOnClose}
          iconName="close"
          position="absolute"
          right={1}
          top={1}
          size="xs"
          {...rest}
        />
      )}
    </CPopoverHeader>
  );
};
