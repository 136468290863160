import React, { MutableRefObject, useCallback } from 'react';
import { Box } from '@chakra-ui/react';

interface TimeOptionProps {
  value: string;
  innerRef?: MutableRefObject<HTMLDivElement | null> | null;
  isSelected: boolean;
  handleClick: (selectedValue: string) => void;
}

export const TimeOption = ({ value, innerRef, isSelected, handleClick }: TimeOptionProps): JSX.Element => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const selectedValue = event.currentTarget.textContent || '';
      handleClick(selectedValue);
    },
    [handleClick],
  );

  return (
    <Box
      ref={innerRef}
      onClick={onClick}
      background={isSelected ? 'background.primary' : 'background.default'}
      color={isSelected ? 'text.onContrast' : 'text.subtle'}
      fontSize="sm"
      textAlign="center"
      px={3}
      py={1}
      borderRadius="md"
      cursor="pointer"
      _hover={{
        background: isSelected ? 'background.primary.hover' : 'background.primaryAlpha.hover',
      }}
    >
      {value}
    </Box>
  );
};
