import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCheckmarkCircle = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Zm0 18c-4.41 0-8-3.589-8-8s3.59-8 8-8c4.411 0 8 3.589 8 8s-3.589 8-8 8Z"
      fill={color}
    />
    <path d="m10.711 13.294-2.299-2.295L7 12.415l3.713 3.705 6.706-6.706L16.005 8l-5.294 5.294Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgCheckmarkCircle);
export default ForwardRef;
