import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgPill = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.433 20.567a4.969 4.969 0 0 0 3.536-1.465l7.134-7.133a5.007 5.007 0 0 0-.001-7.073 4.975 4.975 0 0 0-3.536-1.463c-1.335 0-2.591.52-3.534 1.464l-7.134 7.134a5.009 5.009 0 0 0 1.62 8.158 4.97 4.97 0 0 0 1.915.378ZM13.446 6.31a2.98 2.98 0 0 1 2.12-.878c.802 0 1.556.313 2.122.878a3.004 3.004 0 0 1 0 4.244l-2.892 2.892-4.242-4.244 2.892-2.892Zm-7.134 7.134 2.828-2.828 4.242 4.243-2.827 2.828c-1.133 1.133-3.11 1.132-4.243 0a3.005 3.005 0 0 1 0-4.242Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPill);
export default ForwardRef;
