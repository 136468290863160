import { AvatarGroup as CAvatarGroup } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Avatar, AvatarProps } from '../Avatar/avatar.component';
import { AvatarSize } from '../Avatar/avatar.types';

export interface AvatarGroupProps {
  addButtonTooltipText?: string;
  avatars: AvatarProps[];
  handleAddButtonClick?: () => void;
  handleRemoveButtonClick?: () => void;
  max?: number;
  showAddButton?: boolean;
  showRemoveAvatarButton?: boolean;
  size?: AvatarSize;
}

export const AvatarGroup = ({
  addButtonTooltipText,
  avatars,
  handleAddButtonClick,
  handleRemoveButtonClick,
  max = 3,
  showAddButton = true,
  showRemoveAvatarButton = false,
  size = 'md',
}: AvatarGroupProps): JSX.Element => {
  const mergedNames = useMemo(() => avatars.map((avatar) => avatar.name).join(', '), [avatars]);
  const spacing = size === 'lg' || size === 'md' ? -2 : -1;

  return (
    <CAvatarGroup size={size} spacing={spacing} alignItems="flex-start">
      {avatars.length > max ? (
        <Avatar
          name={`${avatars.length}`}
          size={size}
          variant="contrast"
          showRemoveButton={false}
          tooltipText={mergedNames}
        />
      ) : (
        avatars.map((avatar) => (
          <Avatar
            {...avatar}
            key={avatar.name}
            size={size}
            showRemoveButton={showRemoveAvatarButton}
            handleRemoveButtonClick={handleRemoveButtonClick}
          />
        ))
      )}
      {showAddButton && (
        <Avatar
          variant="dashed"
          iconName="plus"
          handleClick={handleAddButtonClick}
          tooltipText={addButtonTooltipText}
          size={size}
        />
      )}
    </CAvatarGroup>
  );
};
