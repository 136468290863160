import { ComponentStyleConfig, SystemStyleObject } from '@chakra-ui/react';

const CheckedStyleDefault: SystemStyleObject = {
  bg: 'background.primary',
  borderColor: 'border.subtle',
  color: 'text.onContrast',
  _hover: {
    bg: 'background.primary.hover',
    borderColor: 'background.primary.hover',
  },
  _disabled: {
    bg: 'background.primary',
    borderColor: 'background.primary',
    color: 'text.onContrast',
  },
};
const CheckedStyleOnCOntrast: SystemStyleObject = {
  bg: 'background.primaryContrast',
  borderColor: 'background.primaryContrast',
  color: 'text.default',
  _hover: {
    bg: 'background.primaryContrast.hover',
    borderColor: 'background.primaryContrast.hover',
  },
  _disabled: {
    bg: 'background.primaryContrast',
    borderColor: 'background.primaryContrast',
    color: 'text.default',
  },
};

export const CheckboxConfig: ComponentStyleConfig = {
  baseStyle: {
    control: {
      border: '1px',
      borderRadius: 'xs',
      borderColor: 'border.subtle',
      _focus: {
        boxShadow: 'none',
      },
      _disabled: {
        opacity: 0.6,
      },
    },
  },
  variants: {
    default: {
      control: {
        bg: 'background.default',
        color: 'text.onContrast',
        _hover: {
          borderColor: 'border.strong',
        },
        _checked: { ...CheckedStyleDefault },
        _indeterminate: { ...CheckedStyleDefault },
        _disabled: {
          bg: 'background.lessSubtle',
          borderColor: 'background.lessSubtle',
        },
        _invalid: {
          borderColor: 'border.danger',
          _hover: {
            borderColor: 'border.danger',
          },
        },
      },
      label: {
        color: 'text.default',
        _disabled: {
          opacity: 0.6,
        },
      },
    },
    onContrast: {
      label: {
        color: 'text.onContrast',
      },
      control: {
        bg: 'background.contrast',
        borderColor: 'border.subtle',
        _hover: {
          borderColor: 'border.strongContrast',
        },
        _checked: { ...CheckedStyleOnCOntrast },
        _indeterminate: { ...CheckedStyleOnCOntrast },
        _disabled: {
          bg: 'background.moreSubtleContrast',
          borderColor: 'background.moreSubtleContrast',
        },
        _invalid: {
          borderColor: 'border.dangerContrast',
          _hover: {
            borderColor: 'border.dangerContrast',
          },
        },
      },
    },
  },
  sizes: {
    sm: {
      control: {
        height: 3,
        width: 3,
      },
      label: {
        fontSize: 'sm',
        ml: 2,
        mt: -1,
      },
    },
    md: {
      control: {
        height: 4,
        width: 4,
      },
      label: {
        fontSize: 'md',
        ml: 2,
        mt: -1,
      },
    },
    lg: {
      control: {
        height: 5,
        width: 5,
      },
      label: {
        fontSize: 'lg',
        ml: 3,
        mt: '-3px',
      },
    },
  },
};
