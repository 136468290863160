import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgTable = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4 21h15.893c1.104 0 2-.897 2-2V5c0-1.103-.896-2-2-2H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Zm0-2v-5h4v5H4ZM14 7v5h-4V7h4ZM8 7v5H4V7h4Zm2 12v-5h4v5h-4Zm6 0v-5h3.895v5H16Zm3.893-7H16V7h3.893v5Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTable);
export default ForwardRef;
