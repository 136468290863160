import React, { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';
import { Text } from '../../../Text/text.component';
import { SelectOptionProps } from '../SelectOption/selectOption.types';

export interface SelectContainerProps {
  selectedOption: SelectOptionProps;
  defaultLeftElement: ReactNode;
  shouldTruncateText?: boolean;
}

export const SelectContainer = ({
  selectedOption,
  defaultLeftElement,
  shouldTruncateText = true,
}: SelectContainerProps): JSX.Element => {
  return (
    <Flex
      data-testid="dropdown-container"
      className="Select__Container"
      justify="flex-start"
      align="center"
      textAlign="left"
      color={selectedOption.variant ? `text.${selectedOption.variant}` : 'text.default'}
    >
      {selectedOption?.leftElement ? (
        <Flex align="center" className="Select__LeftElement" pr={2}>
          {selectedOption.leftElement}
        </Flex>
      ) : defaultLeftElement ? (
        <Flex align="center" className="Select__LeftElement" pr={2}>
          {defaultLeftElement}
        </Flex>
      ) : null}
      {typeof selectedOption.label === 'string' ? (
        <Text className="Select__Text" isTruncated={shouldTruncateText} variant={selectedOption.variant || 'default'}>
          {selectedOption.label}
        </Text>
      ) : (
        selectedOption.label
      )}
    </Flex>
  );
};
