import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCancel = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.486 6.486 2 12 2s10 4.486 10 10-4.486 10-10 10S2 17.514 2 12Zm2 0c0 4.411 3.589 8 8 8a7.96 7.96 0 0 0 4.903-1.683L5.683 7.097A7.96 7.96 0 0 0 4 12Zm3.097-6.317 11.22 11.22A7.96 7.96 0 0 0 20 12c0-4.411-3.589-8-8-8a7.96 7.96 0 0 0-4.903 1.683Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCancel);
export default ForwardRef;
