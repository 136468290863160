export const iconButtonPaddingMap = {
  lg: '8px',
  md: '8px',
  sm: '6px',
  xs: '4px',
};

export const buttonPaddingMap = {
  lg: '12px 20px',
  md: '10px 16px',
  sm: '8px 12px',
  xs: '5px 8px',
};

export const textSpacingMap = {
  lg: '8px',
  md: '8px',
  sm: '4px',
  xs: '4px',
};
