import React, { ReactNode, useMemo } from 'react';
import { Icon } from '../../../Icon/icon.component';
import { MenuButton as CMenuButton, MenuButtonProps as CMenuButtonProps, Box, HStack } from '@chakra-ui/react';
import { Button } from '../../../Button/button.component';
import { ButtonSize, ButtonVariant } from '../../../Button/button.types';
import { IconName } from '../../../../icons';
import { buttonPaddingMap, iconButtonPaddingMap, textSpacingMap } from './menuButton.records';
import { Text, TextProps } from 'components/Text';

export interface KMenuButtonProps {
  leftElement?: ReactNode;
  leftIconName?: IconName;
  rightIconName?: IconName;
  showRightIcon?: boolean;
  size?: ButtonSize;
  text?: string;
  textProps?: TextProps;
  variant?: ButtonVariant;
}

export type MenuButtonProps = KMenuButtonProps & CMenuButtonProps;

export const MenuButton = ({
  leftElement,
  leftIconName,
  rightIconName = 'chevronDown',
  showRightIcon = true,
  size,
  text,
  textProps,
  variant,
  ...rest
}: MenuButtonProps): JSX.Element => {
  const isIconButton = leftIconName && !text;

  const buttonPadding = useMemo((): string => {
    if (!size) return '10px 16px';
    return isIconButton ? iconButtonPaddingMap[size] : buttonPaddingMap[size];
  }, [isIconButton, size]);

  const textSpacing = useMemo((): string => {
    if (!size) return '8px';
    return isIconButton ? '0' : textSpacingMap[size];
  }, [isIconButton, size]);

  return (
    <CMenuButton
      as={Button}
      variant={variant}
      size={size}
      position="relative"
      className="MenuButton"
      p={buttonPadding}
      lineHeight={0}
      _active={{ transform: 'none' }}
      borderRadius="md"
      {...rest}
    >
      <HStack spacing={textSpacing} className="MenuButton__ContentContainer" justify="center">
        {leftElement && leftElement}
        {leftIconName && (
          <Box className="MenuButton__LeftIcon">
            <Icon name={leftIconName} size={size} />
          </Box>
        )}
        {text && (
          <Text
            as="p"
            className="MenuButton__Text"
            display="block"
            size={size}
            color={variant ? 'inherit' : textProps?.color || 'text.onContrast'}
            isTruncated
            noOfLines={1}
            {...textProps}
          >
            {text}
          </Text>
        )}
        {showRightIcon && (
          <Box className="MenuButton__RightIcon">
            <Icon name={rightIconName} size={size === 'lg' ? 'sm' : 'xs'} />
          </Box>
        )}
      </HStack>
    </CMenuButton>
  );
};
