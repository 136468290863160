import { typographyVariantConfig } from 'shared/config/typographyVariant.config';
import { TypographyConfig } from 'shared/types/typography';

export const TextConfig: TypographyConfig = {
  variants: typographyVariantConfig,
  sizes: {
    xs: {
      fontSize: '12px',
      lineHeight: '150%',
    },
    sm: {
      fontSize: '14px',
      lineHeight: '150%',
    },
    md: {
      fontSize: '16px',
      lineHeight: '150%',
    },
    lg: {
      fontSize: '18px',
      lineHeight: '150%',
    },
    xl: {
      fontSize: '20px',
      lineHeight: '150%',
    },
    '2xl': {
      fontSize: '24px',
      lineHeight: '150%',
    },
    '3xl': {
      fontSize: '30px',
      lineHeight: '150%',
    },
    '4xl': {
      fontSize: '36px',
      lineHeight: '150%',
    },
    '5xl': {
      fontSize: '48px',
      lineHeight: '150%',
    },
    '6xl': {
      fontSize: '60px',
      lineHeight: '150%',
    },
  },
};
