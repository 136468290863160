import React, { useMemo } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SortableItemContext } from './sortableItem.context';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Box, StyleProps } from '@chakra-ui/react';

export interface SortableItemProps {
  id: UniqueIdentifier;
  style?: StyleProps;
  onRemove?(id: UniqueIdentifier): void;
}

export const SortableItem = ({
  children,
  id,
  style: userStyle,
  onRemove,
}: PropsWithChildren<SortableItemProps>): JSX.Element => {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <Box
        className="SortableItem"
        ref={setNodeRef}
        listStyleType="none"
        opacity={isDragging ? 0.4 : undefined}
        {...userStyle}
        style={style}
      >
        {children}
      </Box>
    </SortableItemContext.Provider>
  );
};
