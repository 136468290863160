import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgForm = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16 14v-2H8v2h8ZM16 18v-2H8v2h8ZM10 8H8v2h2V8Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.707 8.293a.997.997 0 0 1 .226.375c0 .004.002.007.003.011.029.084.046.17.051.259 0 .01.004.02.007.029.003.01.006.022.006.033v11c0 1.103-.897 2-2 2H6c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2h7c.011 0 .022.003.033.007l.028.006a.991.991 0 0 1 .289.06c.022.008.043.014.064.024a.998.998 0 0 1 .293.196l6 6ZM14 8h2.586L14 5.414V8ZM6 4v16h12.002L18 10h-5a1 1 0 0 1-1-1V4H6Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgForm);
export default ForwardRef;
