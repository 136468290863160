import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgTwoSquares = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.104 0 2-.897 2-2v-4h4c1.104 0 2-.897 2-2V4c0-1.103-.896-2-2-2ZM4 20V10h10l.002 10H4Zm16-6h-4v-4c0-1.103-.896-2-2-2h-4V4h10v10Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTwoSquares);
export default ForwardRef;
