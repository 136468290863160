import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMailbox = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 19.88a2 2 0 0 1-2-2v-8a4 4 0 0 1 4-4h7V4a2 2 0 0 1 2-2h4.043c1.534 0 2.497 1.655 1.74 2.988L19.71 6.875c-.038.067-.08.132-.125.193A5.991 5.991 0 0 1 22 11.88v6a2 2 0 0 1-2 2h-7V22h-2v-2.12H4ZM15 7.887v2.65h-2V7.88H9.916c.34.588.535 1.271.535 2v8H20v-6a4 4 0 0 0-3.762-3.993H15ZM8.451 17.88v-8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8h4.451ZM16 5.88c.098 0 .195.002.292.007h1.679L19.043 4H15v1.88h1Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMailbox);
export default ForwardRef;
