import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Button } from '../../../Button/button.component';

export interface MenuSelectAllButtonsProps {
  groupName?: string;
  handleToggleAllItems: ({ groupName, clear }: { groupName?: string; clear?: boolean }) => void;
  hasSelectedItems: boolean;
  isGrouped: boolean;
}

export const MenuSelectAllButtons = ({
  groupName,
  handleToggleAllItems,
  hasSelectedItems,
  isGrouped,
}: MenuSelectAllButtonsProps): JSX.Element => {
  if (isGrouped) {
    return (
      <Box textAlign="right" w="50%" className="MenuListItem__SelectAllButton">
        {hasSelectedItems ? (
          <Button
            variant="ghostNeutral"
            iconName="arrowCircle"
            size="xs"
            onClick={(): void => handleToggleAllItems({ groupName, clear: true })}
            isDisabled={!hasSelectedItems}
          >
            Clear all
          </Button>
        ) : (
          <Button variant="ghost" size="xs" onClick={(): void => handleToggleAllItems({ groupName, clear: false })}>
            Select all
          </Button>
        )}
      </Box>
    );
  }

  return (
    <Box mt="8px" className="MenuList__SelectAllButtons">
      <Flex justifyContent="space-between">
        <Button variant="ghost" size="xs" onClick={(): void => handleToggleAllItems({ clear: false })}>
          Select all
        </Button>
        <Button
          variant="ghostNeutral"
          iconName="arrowCircle"
          size="xs"
          onClick={(): void => handleToggleAllItems({ clear: true })}
          isDisabled={!hasSelectedItems}
        >
          Clear all
        </Button>
      </Flex>
    </Box>
  );
};
