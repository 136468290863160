import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgGear = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.423 12c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4Zm-2 0c0-1.084-.916-2-2-2s-2 .916-2 2 .916 2 2 2 2-.916 2-2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.268 17.866-1-1.73a2.003 2.003 0 0 1 .73-2.733l.506-.292a7.683 7.683 0 0 1 0-2.223l-.505-.292a2.001 2.001 0 0 1-.731-2.732l.999-1.729c.53-.919 1.807-1.263 2.73-.732l.53.306a8.132 8.132 0 0 1 1.896-1.111V4c0-1.103.897-2 2-2h2c1.103 0 2 .897 2 2v.598a8.1 8.1 0 0 1 1.896 1.112l.529-.306c.923-.533 2.2-.188 2.73.732l1 1.729a2.002 2.002 0 0 1-.731 2.732l-.505.292a7.716 7.716 0 0 1 0 2.224l.505.292a2.001 2.001 0 0 1 .73 2.732l-.998 1.729c-.533.919-1.808 1.261-2.731.731l-.53-.306a8.13 8.13 0 0 1-1.895 1.111V20c0 1.103-.897 2-2 2h-2c-1.103 0-2-.897-2-2v-.598a8.1 8.1 0 0 1-1.896-1.112l-.53.306c-.92.531-2.198.187-2.73-.73ZM5.423 12c0 .465.058.927.17 1.378a1 1 0 0 1-.47 1.108l-1.124.651 1 1.729 1.144-.662a.992.992 0 0 1 1.188.141 6.083 6.083 0 0 0 2.384 1.399 1 1 0 0 1 .708.956V20h2.002l-.002-1.3a1 1 0 0 1 .708-.956 6.071 6.071 0 0 0 2.384-1.399.996.996 0 0 1 1.188-.142l1.145.661.998-1.729-1.123-.649a1 1 0 0 1-.471-1.108c.113-.453.17-.917.17-1.378 0-.462-.057-.926-.17-1.378a1 1 0 0 1 .47-1.108l1.125-.649-1-1.729-1.144.661a.999.999 0 0 1-1.188-.142 6.083 6.083 0 0 0-2.384-1.399 1 1 0 0 1-.708-.956V4h-2v1.3a1 1 0 0 1-.708.956A6.071 6.071 0 0 0 7.33 7.655a.997.997 0 0 1-1.188.142l-1.145-.662L4 8.864l1.123.65a.999.999 0 0 1 .47 1.108c-.112.452-.17.916-.17 1.378Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGear);
export default ForwardRef;
