import { Borders, Radii } from './borders.types';

export const borders: Borders = {
  none: 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '4px': '4px solid',
  '8px': '8px solid',
};

export const radii: Radii = {
  none: '0',
  xs: '0.125rem',
  sm: '0.375rem',
  base: '0.5rem',
  md: '0.75rem',
  lg: '1rem',
  xl: '1.5rem',
  '2xl': '2rem',
  '3xl': '2.5rem',
  full: '9999px',
};
