import React, { ReactNode } from 'react';
import { Radio as CRadio, RadioProps as CRadioProps } from '@chakra-ui/react';
import { RadioSize } from './radio.types';

export interface KRadioProps {
  children?: ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isOnContrast?: boolean;
  size?: RadioSize;
  value?: string | number;
}

export type RadioProps = KRadioProps & CRadioProps;

export const Radio = ({ children, size = 'md', isOnContrast, ...rest }: RadioProps): JSX.Element => {
  const _computedVariant = isOnContrast ? 'onContrast' : 'default';
  return (
    <CRadio data-testid="radio-options-container" variant={_computedVariant} {...rest} size={size} alignItems="flex-start">
      {children}
    </CRadio>
  );
};
