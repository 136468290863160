import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgDownload = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m16 11-4 5-4-5h3V4h2v7h3Z" fill={color} />
    <path d="M4 18h16v-7h2v7c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2v-7h2v7Z" fill={color} />
  </svg>
);
const ForwardRef = forwardRef(SvgDownload);
export default ForwardRef;
