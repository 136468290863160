import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgTeam = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.356 7.608a5.67 5.67 0 0 1-.751 3.44C20.045 12.715 21 16.211 21 18h-2c0-.043-.05-4.299-4.281-5.539l-1.618-.475 1.192-1.192a3.694 3.694 0 0 0 1.072-2.986c-.118-1.175-.8-2.232-1.918-2.974l1.105-1.666c1.628 1.079 2.624 2.656 2.803 4.44Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 8c0 2.206-1.793 4-4 4-2.206 0-4-1.794-4-4s1.794-4 4-4c2.207 0 4 1.794 4 4Zm-2 0c0-1.103-.896-2-2-2-1.103 0-2 .897-2 2s.897 2 2 2c1.104 0 2-.897 2-2Z"
      fill={color}
    />
    <path
      d="M2 19c0-3.309 2.691-6 6-6h3c3.31 0 6 2.691 6 6v1h-2v-1c0-2.206-1.793-4-4-4H8c-2.206 0-4 1.794-4 4v1H2v-1Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTeam);
export default ForwardRef;
