import { Tab as CTab, TabProps as CTabProps, Box } from '@chakra-ui/react';
import { NotificationCounter } from '../../../NotificationCounter/notificationCounter.component';
import { Icon } from '../../../Icon/icon.component';
import React from 'react';
import { NotificationCounterVariant } from '../../../NotificationCounter/notificationCounter.types';
import { TabSize } from './tab.types';
import { notificationCounterSize, tabHeadingSize } from './tab.records';
import { IconName } from '../../../../icons';
import { Heading } from 'components/Heading';

export interface TabProps extends CTabProps {
  iconName?: IconName;
  label: string;
  showNotificationCounter?: boolean;
  notificationCount?: number;
  notificationCounterVariant?: NotificationCounterVariant;
  size?: TabSize;
}

export const Tab = ({
  iconName,
  label,
  showNotificationCounter = false,
  notificationCount = 0,
  notificationCounterVariant = NotificationCounterVariant.Red,
  size = 'md',
  ...rest
}: TabProps): JSX.Element => {
  return (
    <CTab {...rest}>
      {!!iconName && (
        <Box pr={2}>
          <Icon name={iconName} size={size} />
        </Box>
      )}
      <Heading size={tabHeadingSize[size]}>{label}</Heading>
      {showNotificationCounter && (
        <NotificationCounter
          count={notificationCount}
          variant={notificationCounterVariant}
          size={notificationCounterSize[size]}
          ml={2}
        />
      )}
    </CTab>
  );
};
