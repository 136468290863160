import React, { useMemo } from 'react';
import { Switch as CSwitch, SwitchProps as CSwitchProps } from '@chakra-ui/react';
import { WithFormControlProps, withFormControl } from 'shared/hocs/FormControl/withFormControl';
import { FormControlSize } from 'shared/types/formControl';

export interface KSwitchProps extends WithFormControlProps {
  isChecked?: boolean;
  isFieldInline?: boolean;
  isIndeterminate?: boolean;
  isOnContrast?: boolean;
  size?: FormControlSize;
}

export type SwitchProps = KSwitchProps & CSwitchProps;

const Switch = ({ isChecked, isIndeterminate, isFieldInline, isOnContrast, ...rest }: SwitchProps): JSX.Element => {
  const _computedVariant = useMemo(() => {
    if (isIndeterminate) {
      return isOnContrast ? 'indeterminateOnContrast' : 'indeterminate';
    } else {
      return isOnContrast ? 'onContrast' : 'default';
    }
  }, [isIndeterminate, isOnContrast]);

  return (
    <CSwitch
      isChecked={isChecked}
      display={isFieldInline ? 'inline-block' : 'block'}
      variant={_computedVariant}
      {...rest}
    />
  );
};

Switch.displayName = 'Switch';

const WrappedSwitch = withFormControl(Switch);

export { WrappedSwitch as Switch };
