import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgBank = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M2 8v4.001h1V18H2v3h16l3 .001L22 21v-3h-1v-5.999h1V8L12 2 2 8Zm4 10v-5.999h2V18H6Zm5 0v-5.999h2V18h-2Zm7 0h-2v-5.999h2V18ZM14 8a2.001 2.001 0 1 1-4.002-.001A2.001 2.001 0 0 1 14 8Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBank);
export default ForwardRef;
