export const avatarSizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
export type AvatarSize = (typeof avatarSizes)[number];

export const avatarTypes = ['outline', 'solid'] as const;
export type AvatarType = (typeof avatarTypes)[number];

export const avatarVariants = ['default', 'contrast', 'dashed', 'accent', 'outlineAccent', 'outlineYellow'] as const;

export type AvatarVariant = (typeof avatarVariants)[number];

export const avatarBadePositions = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'] as const;

export type AvatarBadgePosition = (typeof avatarBadePositions)[number];
