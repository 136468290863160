import React, { ChangeEvent, RefObject } from 'react';
import { PopoverTrigger, Flex, HStack } from '@chakra-ui/react';
import { TextInput, TextInputProps } from 'components/TextInput/textInput.component';
import { Icon } from 'components/Icon/icon.component';
import { Text } from 'components/Text/text.component';
import isDate from 'date-fns/isDate';
import { getInputValue, parseDate } from '@datepicker-react/hooks';
import { IconSizeMap } from '../../datepicker.records';
import { isDateAfterMin, isDateBeforeMax } from '../../datepicker.utils';
import { FocusedInput, today, useDatePickerContext } from 'components/DatePicker/context/datepicker.context';
import format from 'date-fns/format';

interface DatePickerInputProps {
  inputProps?: Omit<TextInputProps, 'onChange' | 'value' | 'isClearable' | 'onClear'>;
  startDateInputRef: RefObject<HTMLInputElement>;
}

const focusedInputStyles = {
  borderColor: 'var(--chakra-colors-border-info) !important',
  borderWidth: '2px !important',
};

const hoverInputStyles = {
  _hover: {
    borderWidth: '1px',
    borderColor: 'border.strong',
  },
};

export function DatePickerInput({ inputProps, startDateInputRef }: DatePickerInputProps): JSX.Element {
  const {
    setFocusedInput,
    startDate,
    endDate,
    focusedInput,
    dateFormat,
    isDateRange,
    minDate,
    maxDate,
    onDateChange,
    onDateSelect,
    isOpen,
    onClose,
  } = useDatePickerContext();
  const iconSize = inputProps?.size ? IconSizeMap[inputProps.size] : 'md';

  const onKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && isOpen) {
      onClose();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const dateValue = e.target.value;
    const parsedDate = parseDate(dateValue, dateFormat, today);

    if (
      dateValue.length === dateFormat.length &&
      isDate(parsedDate) &&
      isDateAfterMin(parsedDate, minDate) &&
      isDateBeforeMax(parsedDate, maxDate)
    ) {
      onDateSelect(parsedDate);
    }

    // Trigger change when input is empty
    if (!dateValue.length && onDateChange) {
      onDateChange({ startDate: null, endDate: null });
    }
  };

  return (
    <PopoverTrigger>
      <HStack w="100%">
        <TextInput
          data-testid="date-picker-text-input"
          rightElement={
            <Flex
              {...(!inputProps?.isDisabled && {
                onClick: (): void => setFocusedInput(FocusedInput.StartDate),
              })}
              h="100%"
              align="center"
            >
              <Icon name="calendarDate" size={iconSize} />
            </Flex>
          }
          value={getInputValue(startDate, dateFormat, '')}
          onChange={onInputChange}
          inputRef={startDateInputRef}
          onKeyDown={onKeyDown}
          {...(!inputProps?.isDisabled && {
            onFocus: (): void => setFocusedInput(FocusedInput.StartDate),
          })}
          placeholder={format(today, dateFormat).toString()}
          {...(focusedInput === FocusedInput.StartDate && focusedInputStyles)}
          {...hoverInputStyles}
          {...inputProps}
        />
        {isDateRange && (
          <>
            <Text> - </Text>
            <TextInput
              data-testid="date-picker-text-input"
              rightElement={
                <Flex
                  {...(!inputProps?.isDisabled && {
                    onClick: (): void => setFocusedInput(FocusedInput.StartDate),
                  })}
                  h="100%"
                  align="center"
                >
                  <Icon name="calendarDate" size={iconSize} />
                </Flex>
              }
              value={getInputValue(endDate, dateFormat, '')}
              onChange={onInputChange}
              {...(!inputProps?.isDisabled && {
                onFocus: (): void => setFocusedInput(FocusedInput.EndDate),
              })}
              onKeyDown={onKeyDown}
              placeholder={format(today, dateFormat).toString()}
              {...(focusedInput === FocusedInput.EndDate && focusedInputStyles)}
              {...hoverInputStyles}
              {...inputProps}
            />
          </>
        )}
      </HStack>
    </PopoverTrigger>
  );
}
