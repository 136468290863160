import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgInvoice = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 14c0 .14.16 1 2 1 1.38 0 2-.68 2-1 0-.32 0-1-2-1-3.48 0-4-1.88-4-3 0-1.29 1-2.58 3-2.91V6h2v1.12A3 3 0 0 1 15.4 10h-2c-.01-.36-.1-1-1.4-1-1.3 0-2 .63-2 1s0 1 2 1c3.48 0 4 1.88 4 3 0 1.29-1 2.58-3 2.92V18h-2v-1.08c-2.34-.37-3-2-3-2.92h2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2h20v2h-2v17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4H2V2Zm4 2v16h12V4H6Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInvoice);
export default ForwardRef;
