import React, { FunctionComponent, ReactNode } from 'react';
import { MenuProps as CMenuProps, MenuButton as CMenuButton, Portal as CPortal } from '@chakra-ui/react';
import { MenuContainer, MenuContainerProps } from './components/MenuContainer/menuContainer.component';
import { MenuButton, MenuButtonProps } from './components/MenuButton/menuButton.component';
import { MenuList, MenuListProps } from './components/MenuList/menuList.component';
import { MenuProvider } from './context/menu.context';

export interface KMenuProps {
  buttonProps?: MenuButtonProps;
  customTriggerElement?: FunctionComponent;
  listProps?: MenuListProps;
  containerProps?: MenuContainerProps;
  dataTestId?: string;
}

export type MenuProps = KMenuProps & Omit<CMenuProps, 'onClose' | 'children'>;

export const Menu = ({
  buttonProps,
  customTriggerElement,
  listProps,
  containerProps,
  dataTestId = 'menu',
  ...props
}: MenuProps): JSX.Element => {
  const MenuListContainer = ({ children }: { children: ReactNode }): JSX.Element => {
    return listProps?.shouldUsePortal ? <CPortal>{children}</CPortal> : <>{children}</>;
  };

  return (
    <MenuProvider
      menuItems={listProps?.menuItems}
      isMultiSelect={listProps?.isMultiSelect}
      onClose={containerProps?.onClose}
      dataTestId={dataTestId}
    >
      <MenuContainer
        data-testid={`${dataTestId}-containter`}
        isLazy={containerProps?.isLazy || true}
        {...containerProps}
        {...props}
      >
        {customTriggerElement ? (
          // @ts-ignore
          <customTriggerElement as={CMenuButton} />
        ) : (
          <MenuButton data-testid={`${dataTestId}-button`} {...buttonProps} />
        )}
        <MenuListContainer>
          <MenuList
            data-testid={`${dataTestId}-list`}
            {...listProps}
            isMatchWidthSet={props.matchWidth}
            menuItems={listProps?.menuItems}
          />
        </MenuListContainer>
      </MenuContainer>
    </MenuProvider>
  );
};
