import React from 'react';
import { Box, Wrap, WrapItem } from '@chakra-ui/react';
import { Tag, TagProps } from 'components/Tag';
import { Tooltip } from 'components/Tooltip';

export interface ConversationCardTag extends TagProps {
  tooltipContent?: string;
}

export interface ConversationCardTagsProps {
  tags: ConversationCardTag[];
}

export const ConversationCardTags = ({ tags }: ConversationCardTagsProps): JSX.Element => {
  return (
    <Box className="ConversationCard__Tags" mt={3}>
      <Wrap spacing="6px">
        {tags.map((tag) => {
          const { tooltipContent, ...rest } = tag;
          let tagElement = <Tag closeButtonDisplay="hover" {...rest} size="sm" textTransform="capitalize" />;

          if (tag.tooltipContent) {
            tagElement = (
              <Tooltip label={tag.tooltipContent} placement="top">
                <Box>{tagElement}</Box>
              </Tooltip>
            );
          }

          return <WrapItem key={tag.label}>{tagElement}</WrapItem>;
        })}
      </Wrap>
    </Box>
  );
};
