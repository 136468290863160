import { HeadingSize } from 'components/Heading';
import { NotificationCounterSize } from '../../../NotificationCounter/notificationCounter.types';
import { TabSize } from './tab.types';

export const notificationCounterSize: Record<TabSize, NotificationCounterSize> = {
  xs: 'sm',
  sm: 'md',
  md: 'md',
  lg: 'lg',
};

export const tabHeadingSize: Record<TabSize, HeadingSize> = {
  xs: 'xs',
  sm: 'xs',
  md: 'sm',
  lg: 'md',
};
