import React, { ReactNode } from 'react';
import { Alert as CAlert, AlertProps as CAlertProps, Box, VStack } from '@chakra-ui/react';
import { IconProps } from '../Icon/icon.component';
import { TextProps } from '../Text/text.component';
import { HeadingProps } from '../Heading/heading.component';
import { AlertButton, AlertButtonProps } from './components/alertButton.component';
import { AlertCloseButton } from './components/alertCloseButton.component';
import { AlertTitle } from './components/alertTitle.component';
import { AlertIcon } from './components/alertIcon.component';
import { AlertDescription } from './components/alertDescription.component';
import { AlertBody } from './components/alertBody.component';
import { AlertHeader } from './components/alertHeader.component';
import { Status } from 'shared/types/status';

export interface KAlertProps {
  buttonProps?: AlertButtonProps;
  children?: ReactNode;
  description?: string | ReactNode;
  descriptionProps?: TextProps;
  hasShadow?: boolean;
  hideCloseButton?: boolean;
  hideIcon?: boolean;
  iconProps?: IconProps;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  status?: Status;
  title?: string | ReactNode;
  titleProps?: HeadingProps;
  isToast?: boolean;
}

export interface AlertProps extends KAlertProps, Omit<CAlertProps, 'title' | 'status'> {}

export const Alert = ({
  buttonProps,
  children,
  description,
  descriptionProps,
  hasShadow = false,
  hideCloseButton = false,
  hideIcon = false,
  iconProps,
  onClose,
  status = 'success',
  title,
  titleProps,
  isToast = false,
  ...rest
}: AlertProps): JSX.Element => {
  const computedVariant = isToast ? `${status}Toast` : status;
  if (buttonProps && !buttonProps.buttonPosition) {
    buttonProps.buttonPosition = 'topRight';
  }

  const AlertWithTitle = (): JSX.Element => {
    return (
      <>
        <AlertHeader>
          {!hideIcon && <AlertIcon isToast={isToast} status={status} iconProps={iconProps} />}
          <AlertTitle isToast={isToast} title={title} titleProps={titleProps} />
          {buttonProps?.buttonPosition === 'topRight' && (
            <AlertButton {...buttonProps} hasDescription={Boolean(description)} isToast={isToast} status={status} />
          )}
          {!hideCloseButton && <AlertCloseButton isToast={isToast} onClose={onClose} />}
        </AlertHeader>
        <AlertBody hideIcon={hideIcon} hideCloseButton={hideCloseButton}>
          {description && (
            <AlertDescription isToast={isToast} description={description} descriptionProps={descriptionProps} />
          )}
          {children}
          {buttonProps?.buttonPosition === 'bottomLeft' && (
            <AlertButton {...buttonProps} hasDescription={Boolean(description)} isToast={isToast} status={status} />
          )}
        </AlertBody>
      </>
    );
  };

  const AlertWithoutTitle = (): JSX.Element => {
    return (
      <VStack w="100%" align="flex-start" justify="flex-start">
        <AlertHeader>
          {!hideIcon && <AlertIcon isToast={isToast} status={status} iconProps={iconProps} />}
          <Box flexGrow={1}>
            {description && (
              <AlertDescription isToast={isToast} description={description} descriptionProps={descriptionProps} />
            )}
            {children}
          </Box>
          {buttonProps?.buttonPosition === 'topRight' && (
            <AlertButton {...buttonProps} hasDescription={Boolean(description)} isToast={isToast} status={status} />
          )}
          {!hideCloseButton && <AlertCloseButton isToast={isToast} onClose={onClose} />}
        </AlertHeader>
        {buttonProps?.buttonPosition === 'bottomLeft' && (
          <AlertBody hideIcon={hideIcon} hideCloseButton={hideCloseButton}>
            <AlertButton {...buttonProps} hasDescription={Boolean(description)} isToast={isToast} status={status} />
          </AlertBody>
        )}
      </VStack>
    );
  };

  return (
    <CAlert
      flexDirection="column"
      align="flex-start"
      justify="flex-start"
      variant={computedVariant}
      overflow="visible"
      px={3}
      py={2}
      {...(hasShadow && { boxShadow: 'md' })}
      {...rest}
    >
      {title ? <AlertWithTitle /> : <AlertWithoutTitle />}
    </CAlert>
  );
};
