import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgQuestionMark = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 4C9.244 4 7 6.243 7 9h2c0-1.654 1.347-3 3-3 1.655 0 3 1.346 3 3 0 1.069-.454 1.465-1.48 2.255-.383.294-.813.626-1.226 1.038C10.98 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.681-.598 1.03-.867C15.799 12.024 17 11.1 17 9c0-2.757-2.242-5-5-5Zm-1 14h2v2h-2v-2Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgQuestionMark);
export default ForwardRef;
