import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgMessageDetail = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17 7H7v2h10V7ZM14 11H7v2h7v-2Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2h16c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2h-6.723L7 21.767V18H4c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2Zm8.723 14H20V4H4v12h5v2.233L12.723 16Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMessageDetail);
export default ForwardRef;
