import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgCheckmarkBox = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m11.239 13.519-2.226-2.226-1.414 1.414 3.774 3.774 5.702-6.84-1.538-1.282-4.298 5.16Z" fill={color} />
    <path
      d="M19.306 3h-14c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2Zm-14 16V5h14l.002 14H5.306Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCheckmarkBox);
export default ForwardRef;
