import { Grid, PopoverBody } from '@chakra-ui/react';
import { useDatePickerContext } from 'components/DatePicker/context/datepicker.context';
import React from 'react';
import { Month } from './Month/month.component';

export function DatePickerCalendar(): JSX.Element {
  const { isDateRange, activeMonths, onClose } = useDatePickerContext();

  const handleDaySelected = (): void => {
    if (!isDateRange) {
      onClose();
    }
  };

  return (
    <PopoverBody>
      <Grid gap="0 64px">
        {activeMonths.map((month) => (
          <Month key={`${month.year}-${month.month}`} onDaySelected={handleDaySelected} />
        ))}
      </Grid>
    </PopoverBody>
  );
}
