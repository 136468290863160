import React, { useCallback } from 'react';
import { Button } from 'components/Button/button.component';
import { PopoverFooter, HStack } from '@chakra-ui/react';
import { useDatePickerContext } from 'components/DatePicker/context/datepicker.context';
import { DateRange } from 'components/DatePicker/datepicker.component';

export interface DatePickerFooterProps {
  buttonText?: string;
  onSubmit?: ({ startDate, endDate }: DateRange) => void;
  hideResetButton?: boolean;
}

export function DatePickerFooter({
  buttonText = 'Apply',
  onSubmit,
  hideResetButton,
}: DatePickerFooterProps): JSX.Element {
  const { startDate, endDate, onClose, clearDates } = useDatePickerContext();

  const handleSubmit = useCallback((): void => {
    onClose();

    if (onSubmit) {
      onSubmit({ startDate, endDate });
    }
  }, [onClose, onSubmit, startDate, endDate]);

  return (
    <PopoverFooter>
      <HStack w="100%" justify={hideResetButton ? 'end' : 'space-between'} align="center">
        {!hideResetButton && (
          <Button size="sm" variant="ghostNeutral" onClick={clearDates}>
            Clear Dates
          </Button>
        )}
        <Button size="sm" onClick={handleSubmit}>
          {buttonText}
        </Button>
      </HStack>
    </PopoverFooter>
  );
}
