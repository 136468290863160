import React, { ReactNode } from 'react';
import { DarkMode, Box } from '@chakra-ui/react';

export const withDarkMode = (children: ReactNode): JSX.Element => {
  return (
    <DarkMode>
      <Box data-theme="dark" width="inherit">
        {children}
      </Box>
    </DarkMode>
  );
};
