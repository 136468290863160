import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { SwatchSize, SwatchSizeMap } from './swatch.types';

export interface BaseSwatchProps {
  size?: SwatchSize;
}

export type SwatchProps = BaseSwatchProps & BoxProps;

export const Swatch = ({ size = 'md', borderColor, ...rest }: SwatchProps): JSX.Element => {
  const _swatchColor = rest.bgColor || rest.backgroundColor || 'transparent';
  const d = SwatchSizeMap[size] + 'px';

  return (
    <Box
      display="inline-block"
      borderRadius="base"
      border="1px"
      bg={_swatchColor}
      borderColor={borderColor || _swatchColor}
      w={d}
      h={d}
      {...rest}
    />
  );
};
