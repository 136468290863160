import React from 'react';
import { ModalFooter as CModalFooter, ModalFooterProps as CModalFooterProps } from '@chakra-ui/react';

export interface ModalFooterProps extends CModalFooterProps {}

export const ModalFooter = ({ children, ...rest }: ModalFooterProps): JSX.Element => {
  return (
    <CModalFooter pb={6} className="Modal__Footer" {...rest}>
      {children}
    </CModalFooter>
  );
};
