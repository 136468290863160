import React from 'react';
import { ModalBody as CModalBody, ModalBodyProps as CModalBodyProps } from '@chakra-ui/react';

export interface ModalBodyProps extends CModalBodyProps {}

export const ModalBody = ({ children, ...rest }: ModalBodyProps): JSX.Element => {
  return (
    <CModalBody className="Modal__Body" py={1} {...rest}>
      {children}
    </CModalBody>
  );
};
