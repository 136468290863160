import React, { useCallback } from 'react';
import { Flex } from '@chakra-ui/react';

export interface IRatingDotProps {
  number: number;
  isInteractive?: boolean;
  isDisabled?: boolean;
  onClick?: (value: number) => void;
  selected: boolean;
}

export const RatingDot = ({ number, isInteractive, isDisabled, onClick, selected }: IRatingDotProps): JSX.Element => {
  const handleClick = useCallback(
    (e): void => {
      e.preventDefault();
      if (onClick) onClick(number);
    },
    [number, onClick],
  );

  return (
    <>
      {isInteractive ? (
        <Flex
          as="button"
          type="button"
          bgColor={selected ? 'background.primary' : 'transparent'}
          h="60px"
          w="60px"
          borderRadius="30px"
          border="2px"
          borderColor="background.primary"
          color={selected ? 'text.onContrast' : 'text.default'}
          mr={1}
          mb={1}
          justifyContent="center"
          alignItems="center"
          fontSize="lg"
          cursor="not-allowed"
          {...(!isDisabled && {
            onClick: handleClick,
            cursor: 'pointer',
            _hover: {
              bg: selected ? 'background.primary' : 'background.primaryAlpha.hover',
            },
          })}
        >
          {number}
        </Flex>
      ) : (
        <Flex
          as="div"
          bgColor="background.primary"
          h="40px"
          w="40px"
          borderRadius="20px"
          border="2px"
          borderColor="background.primary"
          color="text.onContrast"
          mr={1}
          mb={1}
          justifyContent="center"
          alignItems="center"
          fontSize="lg"
          cursor="default"
        >
          {number}
        </Flex>
      )}
    </>
  );
};
