import * as React from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  color?: string;
  height?: string;
  width?: string;
  title?: string;
  titleId?: string;
}
const SvgClose = (
  { color = 'currentColor', height = '24px', width = '24px', title, titleId }: SVGRProps,
  ref: Ref<SVGSVGElement>,
): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    ref={ref}
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m18.239 4.344-6.243 6.242-6.242-6.242L4.34 5.758 10.582 12 4.34 18.242l1.414 1.414 6.242-6.242 6.243 6.242 1.414-1.414L13.411 12l6.242-6.242-1.414-1.414Z"
      fill={color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgClose);
export default ForwardRef;
